import '../App.css';
import { makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import Layout from '../layout';
import TimePicker from '../components/timepicker';
import Datepicker from '../components/datepicker';
import loaderImage from '../assets/city.png';
import { useDispatch, useSelector } from 'react-redux';
import { clearsuccess, getDeliveryInfo } from '../store/actions/appactions';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import Button from './controls/Button';
import moment from 'moment';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  icons: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '90%',
    height: 80
  },
  rounddiv: {
    height: '15px',
    width: '15px',
    borderRadius: '7.5px',
    backgroundColor: 'orange'
  },
  heading: {
    textAlign: 'center',
    color: '#909090',
    fontSize: 14
  },
  headingsix: {
    fontSize: 18,
    fontWeight: '500'
  },
  spantext: {
    fontSize: 20,
    fontWeight: 400,
    color: '#000',
    textAlign: 'center'
  },
  spantextten: {
    fontSize: 16,
    fontWeight: 400,
    color: '#000',
    margin: 15,
  },
  spantexttwo: {
    fontSize: 20,
    marginTop: 20,
    marginBottom: 20,
    fontWeight: '400',
    color: '#000',
    textAlign: 'center'
  },
  headingone: {
    fontSize: 50,
    fontWeight: 500,
    marginTop: 60
  },
  headingoneone: {
    fontSize: 40,
    fontWeight: 500,
    marginTop: 20
  },
  icontext: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 12,
    fontWeight: '800'
  },
  iconsocial: {
    fontSize: 12,
    marginRight: 5
  },
  spantextfour: {
    marginTop: 20,
    fontWeight: '400',
    color: theme.palette.primary.light,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  spantextfive: {
    fontSize: 50,
    fontWeight: '800',
    marginRight: 10
  },
  spantextsix: {
    fontWeight: '500',
    fontSize: 16,
  },
  spantextseven: {
    fontWeight: '800',
    marginBottom: 20,
    fontSize: 20,
    color: theme.palette.primary.light,
  },
  imagediv: {
    width: '100%',
    height: 225,
    position: 'relative',
    bottom: 0,
    left: 0,
    right: 0,
  },

  imagedivtwo: {
    width: '100%',
    [theme.breakpoints.up("md")]: {
      height: 150,
      bottom: 0,
    },
    [theme.breakpoints.down("md")]: {
      height: 136,
      bottom: -8,
    },
    [theme.breakpoints.down("sm")]: {
      height: 136,
      bottom: -8,
    },
    [theme.breakpoints.down("xs")]: {
      height: 136,
      bottom: -3,
    },
    position: 'relative',
    left: 0,
    right: 0,
  },


  inputdivs: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },

  inputdivstwo: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },

  inputdivsthree: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },

  pageImage: {
    display: 'block',
    width: '100%',
    height: '100%',
    objectFit: 'cover',

  },
  buttoncontainer: {
    width: '25%',
    margin: 30
  },
  roundbtn: {
    borderRadius: 30

  }
}));

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}


function Thankyouscreentwo() {
  const navstate = useLocation();
  let history = useHistory();

  const classes = useStyles();
  const { token } = useParams();
  const app = useSelector(state => state.app)
  const data = useSelector((state) => state.app?.userinfo == null ? null : state.app?.userinfo);
  const delivery = data?.steps?.find(elemento => elemento.stepType === 1);
  const dispatch = useDispatch();
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  const dateFrom = new Date(delivery?.startEtaWindow);
  const dateFromString = moment(dateFrom).format("DD/MM");
  const timeFromString = dateFrom.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  const dateTo = new Date(delivery?.endEtaWindow);
  //const dateToString = dateTo.toLocaleDateString();
  const timeToString = dateTo.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });


  const dateFromRequested = new Date(delivery?.requested);
  const dateFromRequestedString = dateFromRequested.toLocaleDateString();
  const timeFromRequestedString = dateFromRequested.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  const dateToRequested = new Date(delivery?.requestedEnd);
  //const dateToString = dateTo.toLocaleDateString();
  const timeToRequestedString = dateToRequested.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });





  // const month = dateFrom.getMonth(); /*month in numeric value*/
  // const hourFrom = dateFrom.getHour();
  // const hourTo = dateTo.getHour();
  // const minuteFrom = dateFrom.getMinutes();
  // const minuteTo = dateTo.getMinutes();

  useEffect(() => {
    dispatch(getDeliveryInfo(token))
    dispatch(clearsuccess());
  }, [dispatch])

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  return (
    <Layout>
      <div className={classes.root}>
        <h1 className={windowDimensions.height <= 568 ? classes.headingoneone : classes.headingone}>GRAZIE!</h1>
        <span className={classes.spantext}>Il suo ordine è stato confermato.</span>
        <span className={classes.spantexttwo}>Lo consegneremo il:</span>

        <div className={windowDimensions.height <= 667 ? classes.inputdivstwo : windowDimensions.height <= 736 ? classes.inputdivsthree : classes.inputdivs}>
          <div style={{ marginBottom: - 20, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {navstate.state.changetype === "locationOnly" && <Datepicker calender color='black'
              words={<span><b>{dateFromString}</b>&nbsp;&nbsp;&nbsp;ALLE &nbsp;&nbsp;
                <b>{timeFromString}  - {timeToString}</b> </span>} />}
            {navstate.state.changetype === "alsoTime" && <Datepicker calender color='black'
              words={<span><b>{dateFromRequestedString}</b>&nbsp;&nbsp;&nbsp;ALLE &nbsp;&nbsp;
                <b>{timeFromRequestedString}  - {timeToRequestedString}</b> </span>} />}

          </div>
          <div className={classes.buttoncontainer}>
            <Button size="medium" variant="contained" text="TORNA ALLA PAGINA PRINCIPALE" className={classes.roundbtn} onClick={()=>{
              history.push("/confirm/"+token)
            }}/>
          </div>


        </div>
        <div className={classes.imagedivtwo}>
          <img src={loaderImage} className={classes.pageImage} alt="city" />
        </div>
        {/* <span className={classes.spantextfour}><span className={classes.spantextfive}>22</span><span className={classes.spantextsix}>Maggio<br/>2021</span></span>
       <span className={classes.spantextseven}>10:00 - 13:00</span> */}
      </div>

    </Layout>


  )
}

export default Thankyouscreentwo;
