import * as types from '../types';

const initialState = {
  loading: false,
  error: null,
  userinfo: null,
}

export const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CLEARSUCCESS:
      return {
        ...state,
        modifysuccess: null,
        feedback: null,
        successconfirm: null
      }
    case types.CLEARERROR:
      return {
        ...state,
        loading: false,
        error: null
      }
    case types.LOAD_REVIEW:      
      return {
        ...state,
        hasfeedback: action.payload,
        loading: false,
        error: null
      }
    case types.SUCCESS_REVIEW:
      return {
        ...state,
        feedback: action.payload,
        loading: false,
        error: null
      }
    case types.SUCCESS_MODIFY:
      return {
        ...state,
        modify: action.payload,
        loading: false,
        error: null,
        modifysuccess: true
      }
    case types.SUCCESS_USERINFO:
      return{
        ...state,
        userinfo: action.payload.data,
        token: action.payload.token,
        sessionName: action.payload.sessionName,
        type: action.payload.type,
        error: null,
        loading: false
      }
      case types.SUCCESS_CONFIRM:
        return{
          ...state,
          successconfirm: action.payload,
          modify: action.payload,
          error: null,
          confirmloading: false,
          loading: false
        }
    case types.LOADING:
      return {
        ...state,
        loading: true,
        error: null
      }
    case types.CONFIRMLOADING:
      return {
        ...state,
        confirmloading: true,
        error: null
        }
    case types.ERROR:
      return {
        ...state,
        confirmloading: false,
        loading: false,
        error: action.payload,
      }
    case types.SETTIME:
      return {
        ...state,
        time: action.payload,
      }
    case types.DRIVER_COORDINATE:
      return {
        ...state,
        driverPosition: action.payload,
      }
    default:
      return state
  }
}



