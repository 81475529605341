import ChangeDate from '../../components/ChangeDeliveryDateScreen';
import Confirm from '../../components/ConfirmScreen'
import Detail from '../../components/DetailScreen';
import Tracking from '../../components/mapscreen';
import Success from '../../components/SuccessDeliveryScreen';
import FeedBack from '../../components/FeedbackScreen';
import Reschedule from '../../components/RescheduleScreen';
import Thankyouscreen from '../../components/Thankyouscreentwo';
import Completed from '../../components/CompleteScreen';
import NotFound from '../../components/NotFoundScreen';

export const routes = [
  {
    key: 'confirm',
    path: '/confirm/:token',
    component: Confirm,
  },
  {
    key: 'edit',
    path: '/change-date/:token',
    component: ChangeDate,
  },
  {
    key: 'address',
    path: '/address/:token',
    component: Detail,
  },
  {
    key: 'successdelivery',
    path: '/success/:token',
    component: Success,
  },
  {
    key: 'feedback',
    path: '/feedback/:token',
    component: FeedBack,
  },
  {
    key: 'reschedule',
    path: '/reschedule/:token',
    component: Reschedule,
  },
  {
    key: 'second',
    path: '/thankyouscreen/:token',
    component: Thankyouscreen,
  },{
    key: 'completed',
    path: '/completed/:token',
    component: Completed,
  },
  {
    key: 'tracking',
    path: '/tracking/:token',
    component: Tracking,
  },
  {
    key: 'errorpage',
    path: '/err',
    component: NotFound,
  },
  {
    key: 'notfound',
    path: '*',
    component: NotFound,
  }
]
