import "../App.css";
import { makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import TimePicker from "./timepicker";
import Datepicker from "./datepicker";
import loaderImage from "../assets/wora_logo.png";
import messageImage from "../icons/white.svg";
import signimage from "../assets/sign.png";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { Link } from "react-router-dom";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import { getDeliveryInfo } from "../store/actions/appactions";
import { WoraCorbosServiceDeliveriesDeliveryDto, WoraCorbosServiceDeliveryStepsStepType } from "../proxy/Api";
import { useHistory, useParams } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "100vh",
    backgroundColor: "#fff",
    overflow: "hidden",
  },
  icons: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-evenly",
    width: "100%",
    backgroundColor: "#930483",
    textDecoration: "none",
    zIndex: 99,
  },
  iconss: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  rounddiv: {
    height: "15px",
    width: "15px",
    borderRadius: "7.5px",
    backgroundColor: "orange",
  },
  heading: {
    textAlign: "center",
    color: "#393939",
    fontSize: 22,
    fontWeight: 400,
    marginBottom: 27,
  },
  headingheading: {
    textAlign: "center",
    color: "#393939",
    fontSize: 22,
    fontWeight: 400,
    marginBottom: 17,
  },
  headingtwo: {
    fontWeight: 700,
    fontSize: 24,
    maxWidth: "80%",
    textAlign: "center",
    color: "#393939",
    marginTop: "-10px",
  },
  buttoncontainer: {
    width: "90%",
  },
  headingsix: {
    fontSize: 18,
    fontWeight: 500,
    lineHeight: 0,
    color: "#393939",
    textAlign: "center",
  },
  spantext: {
    fontSize: 18,
    fontWeight: 400,
    maxWidth: "80%",
    textAlign: "center",
    color: "#393939",
    marginTop: "-10px",
  },
  spantexttwo: {
    fontSize: 18,
    fontWeight: 400,
    color: "#393939",
    maxWidth: "90%",
    textAlign: "center",
    marginTop: "0px",
    marginBottom: 30,
  },
  spantexttwotwo: {
    fontSize: 18,
    fontWeight: 400,
    color: "#393939",
    // maxWidth: '90%',
    textAlign: "center",
    marginBottom: 0,
  },
  headingone: {
    fontSize: 60,
  },
  icontext: {
    display: "flex",
    alignItems: "center",
    fontSize: 18,
    fontWeight: 500,
    color: "#fff",
  },
  iconsocial: {
    width: 30,
    color: "#fff",
    marginTop: 10,
  },
  typocontainer: {
    width: "90%",
  },
  pageImage: {
    display: "inline-block",
    paddingTop: theme.spacing(5),
    color: "#fff",
    maxWidth: "10em",
    marginBottom: 44,
  },
  pageImagethree: {
    display: "inline-block",
    paddingTop: theme.spacing(5),
    color: "#fff",
    maxWidth: "10em",
    marginBottom: 24,
  },
  pageImagetwo: {
    width: 250,
    paddingTop: 5,
    paddingBottom: 5,
  },
  headingalt: {
    fontWeight: 700,
    fontSize: 24,
    maxWidth: "80%",
    textAlign: "center",
    color: "#393939",
    paddingTop: 0,
    marginBottom: 27,
  },
  headingalttwo: {
    fontWeight: 700,
    fontSize: 24,
    maxWidth: "80%",
    textAlign: "center",
    color: "#393939",
    marginTop: 0,
    marginBottom: 20,
  },
  roottwo: {
    width: "100%",
    /*     [theme.breakpoints.up("md")]: {
          display: "none",
        }, */
  },
}));

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

function SuccessDeliveryScreen() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(0);
  const { token } = useParams<{ [key: string]: string }>();
  const app = useSelector((state: any) => state.app);
  const data = useSelector((state: any) => state.app?.userinfo == null ? null : state.app?.userinfo as WoraCorbosServiceDeliveriesDeliveryDto);

  let history = useHistory();



  //1 means delivery, 0 means pickup
  const delivery = data?.steps?.find(elemento => elemento.stepType === WoraCorbosServiceDeliveryStepsStepType.Value1);


  const isAboutToArrive = delivery?.status == 4;
  const notCompleted = delivery?.status != 5;

  useEffect(() => {
    //if the driver is driving to deliver this delivery, then redirect to tracking
    if (isAboutToArrive == true)
      history.push("/tracking/" + token);
    if (notCompleted == true)
      history.push('/confirm/' + token);
  }, [isAboutToArrive,notCompleted])

  let LocalDate = moment().format("l");
  let LocalSeparator =
    LocalDate.indexOf("/") > -1 ? "-" : LocalDate.indexOf("-") > -1 ? "." : ".";
  const date = app?.userinfo?.realTo
    ? moment(app?.userinfo?.realTo).format(
      "DD[" + LocalSeparator + "]MM[" + LocalSeparator + "]YYYY"
    )
    : "";
  const time = moment(app.userinfo?.realTo).format("HH:mm");
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  /** Load  data */
  useEffect(() => {
    if (token !== undefined) {
      dispatch(getDeliveryInfo(token));
    }
  }, [token, dispatch]);
  return (
    <>
      <div className={classes.root}>
        <img
          src={loaderImage}
          className={
            windowDimensions.height <= 568
              ? classes.pageImagethree
              : classes.pageImage
          }
          alt="Loading"
        />
        <div className={classes.iconss}>
          <div
            className={
              windowDimensions.height <= 667
                ? classes.headingheading
                : classes.heading
            }>
            {
              delivery?.deliveredToType == 0 && <span> Il suo ordine {app?.userinfo?.businessPartnerDescription} è stato consegnato </span>
            }
            {
              delivery?.deliveredToType == 1 && <span> Il suo ordine {app?.userinfo?.businessPartnerDescription} è stato consegnato in portineria a {delivery?.deliveredToName}</span>
            }
            {
              delivery?.deliveredToType == 2 && <span> Il suo ordine {app?.userinfo?.businessPartnerDescription} è stato consegnato a {delivery?.deliveredToName}</span>
            }

            {app.userinfo && app.userinfo.consegnato &&
              <span
                className={
                  windowDimensions.height <= 568
                    ? classes.spantexttwotwo
                    : classes.spantexttwo
                }
              >
                {app.userinfo.consegnato === 'Custode' &&
                  'Il suo ordine è stato ritirato dal custode'
                }
                {app.userinfo.consegnato === 'Altro' &&
                  `Il suo ordine è stato ritirato da ${app.userinfo.altro}`
                }
              </span>
            }

          </div>
        </div>
        {/* <img src={loaderImagetwo} className={classes.pageImagetwo} alt="Loading image"/> */}
        {/* <h2
          className={
            windowDimensions.height <= 667
              ? classes.headingalttwo
              : classes.headingalt
          }
        >
          {app?.userinfo?.deliveryContact || "-"}
        </h2> */}
        {/* <Datepicker/> */}
        <div
          style={{
            width: "90%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Datepicker color="#A6218E" words={date} calender={true} />
          <TimePicker color="purple" words={time} onClick={undefined} />
        </div>

        {app.userinfo && app.userinfo.signature &&
          <div
            style={{
              width: "70%",
              backgroundColor: "#fff",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >

            <span
              style={{
                fontSize: 14,
                color: "#969696",
                fontWeight: 400,
                marginBottom: 30,
                marginTop: 0,
              }}
            >
              Firma
            </span>

            <img
              src={
                app?.userinfo?.signature?.fullpath
                  ? app?.userinfo?.signature?.fullpath
                  : signimage
              }
              alt="Signature"
              style={{ objectFit: "contain", width: 100, height: 50 }}
            />
          </div>
        }
      </div>
      <div
        style={{
          position: "fixed",
          bottom: 0,
          zIndex: "99999999",
          width: "100%",
          left: 0,
        }}
      >
        <BottomNavigation
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          showLabels
          className={classes.roottwo}
        >
          <Link to={`/feedback/${token}`} className={classes.icons}>
            <img
              src={messageImage}
              className={classes.iconsocial}
              alt="Loading"
            />
            <span className={classes.icontext}>VALUTA IL SERVIZIO WORA</span>
          </Link>
        </BottomNavigation>
      </div>
    </>
  );
}

export default SuccessDeliveryScreen;
