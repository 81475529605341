import { makeStyles, Button, CircularProgress} from '@material-ui/core';
import React  from 'react'

const useStyles = makeStyles((theme) => ({
   button: {
    backgroundColor: theme.palette.primary.light,
       color: '#fff',
       borderRadius: 50,
       height: 50,
       fontWeight: '800',
       marginTop: 10,
       "&:hover": {
        backgroundColor: theme.palette.primary.light,
      }
   },
    outlined: {
        backgroundColor: '#fff',
        "&:hover": {
          backgroundColor: '#fff',
        },
       color: theme.palette.primary.light,
       borderRadius: 50,
       height: 50,
       fontWeight: '800',
       marginTop: 10,
       borderColor: theme.palette.primary.light,
    }
  }));

function Roundbutton({text, variant, link, loading, onClick, disabled}) {
  const classes = useStyles();

  return (
    <Button disabled={disabled} onClick={onClick} variant={variant} fullWidth className={variant === 'contained' ? classes.button : classes.outlined}>{loading ? <CircularProgress style={{color: '#fff'}}/> : text}</Button>      
  )
}

export default Roundbutton;
