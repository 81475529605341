import React, {ChangeEventHandler, useRef} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import editicon from '../icons/editdata.svg';
 
const TextInput = (label : string | undefined, id : string | undefined, handleChange : ChangeEventHandler<HTMLInputElement>, value?: any | undefined, invalid?:boolean|undefined) => {
  const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        flexDirection: 'row',
        backgroundColor: '#eeeeee',
        paddingRight: 10,
        borderRadius: 50,
        marginTop: 10
      },
      textField: {
        width: '100%',
        position: 'relative',
        height: 10,
        border: '0px',
        padding: 20,
        backgroundColor: '#eeeeee',
        borderRadius: 50,
        color: '#000'
      },
      button: {
          backgroundColor: theme.palette.primary.light,
          width: 50,
          padding: 0,
          height: 50,
          border: 0,
          borderTopRightRadius: 10,
          borderBottomRightRadius: 10
      },
      arrow: {
          color: '#fff'
      },
      roots: {
          width: '80%'
      },
      label: {
          color: 'grey',
          fontWeight: 400,
          fontSize: 14,
          marginLeft: 10,
      },
      iconsocial: {
        width: 15
      },
      '&.MuiFormControl-root': {
        backgroundColor: '#eeeeee',
      }
}));

  const classes = useStyles();
  const textInput = useRef<any>(null);
  return (
    // <TextField className={classes.roots} label={label} onChange={onChange} fullWidth variant="outlined"></TextField>
    <div className={classes.roots}>
    <label htmlFor={id} className={classes.label}>{label}</label>
    <div className={classes.container} style={{border:invalid?2:0, borderColor:invalid?'red':'', borderStyle:invalid?'solid':''}}>
      <input
      id={id}
        placeholder={label || 'insert data'}
        type="text"
        className={classes.textField}
        value={value}
        onChange={handleChange}
        ref={textInput}
      />
       <img onClick={() => textInput.current.focus()} src={editicon} className={classes.iconsocial} alt="Loading"/>
    </div>
    </div>
  );
}


export default TextInput;