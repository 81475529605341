import { useEffect, useState, useMemo } from 'react';
import '../App.css';
import { makeStyles } from '@material-ui/core';
import { getCoordinates } from '../store/actions/appactions';
import { useSelector, useDispatch } from 'react-redux';
import Footer from './footer';
import { useHistory, useParams } from 'react-router-dom';
import { getDeliveryInfo } from '../store/actions/appactions';
import '../../node_modules/leaflet/dist/leaflet.css';
import { MapContainer, TileLayer, Marker } from 'react-leaflet';
// import { useMap } from 'react-leaflet/hooks'
import icon from "../../node_modules/leaflet/dist/images/marker-icon.png";
import L from "leaflet";
import iconShadow from "../../node_modules/leaflet/dist/images/marker-shadow.png";
import pickupIconPng from "../assets/delivery_truck.png";
import deliveryIconPng from "../assets/house-solid.svg";
import clockIconSvg from "../assets/clock-solid.svg";
import moment, { Moment } from 'moment';


let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
});

L.Marker.prototype.options.icon = DefaultIcon;

let deliveryIcon = L.icon({
    iconUrl: deliveryIconPng,
    // shadowUrl: deliveryIconPng,
    iconSize: [30, 30],
    iconAnchor: [15, 15],
});

let fattorinoIcon = L.icon({
    iconUrl: pickupIconPng,
    // shadowUrl: pickupIconPng,
    iconAnchor: [15, 15],
    iconSize: [55, 55],
});

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: '#fff',
        height: '100vh'
    },
    iconstwo: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        width: '100%',
        position: 'relative',
        backgroundColor: '#eeeeee',
        flex: 7,
    },
    iconss: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '90%',
        flex: 1,
    },
    rounddiv: {
        height: '15px',
        width: '15px',
        borderRadius: '7.5px',
        backgroundColor: 'orange'
    },
    heading: {
        textAlign: 'center',
        color: '#909090',
        fontSize: 15,
        maxWidth: 280,
        fontWeight: 500
    },
    headingtwo: {
        fontWeight: 700,
        fontSize: 24,
        maxWidth: '80%',
        textAlign: 'center',
        color: '#393939',
        marginTop: '-10px'
    },
    buttoncontainer: {
        width: '90%'
    },
    headingsix: {
        fontSize: 18,
        fontWeight: 500,
        lineHeight: 0,
        color: '#393939',
        textAlign: 'center'
    },
    spantext: {
        fontSize: 18,
        fontWeight: '400',
        maxWidth: '80%',
        textAlign: 'center',
        color: '#393939',
        marginTop: '-10px'
    },
    spantexttwo: {
        fontSize: 14,
        fontWeight: '400',
        color: '#252525',
        textAlign: 'center'
    },
    spantextthree: {
        fontSize: 14,
        fontWeight: '800',
        color: '#252525',
        textAlign: 'center'
    },
    //    headingone: {
    //        marginBottom: '-20px'
    //    },
    icontext: {
        display: 'flex',
        alignItems: 'center',
        fontSize: 12,
        fontWeight: '800',
        textDecoration: 'none',
        color: 'black'
    },
    icontexttwo: {
        display: 'flex',
        alignItems: 'center',
        fontSize: 9,
        fontWeight: '800',
        textDecoration: 'none',
        color: 'black',
    },
    iconsocial: {
        width: 15,
        marginRight: 5
    },
    iconsocialtwo: {
        width: 30,
        color: '#222222'
    },
    typocontainer: {
        width: '90%'
    },
    words: {
        flex: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'center'
    },
    details: {
        backgroundColor: '#fff',
        flex: 1,
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        borderBottom: "1px solid black",
        paddingBottom: 5,
        paddingTop: 5
    },
    imagecircle: {
        width: 50,
        height: 50,
        borderRadius: 25,
        border: '1px solid black',
        backgroundColor: '#eeeeee',
        overflow: 'hidden'
    },
    detailswords: {
        textAlign: 'center',
        fontWeight: 500,
        fontSize: 12,
        textTransform: 'uppercase',
    },
    detailswordstwo: {
        textAlign: 'center',
        fontWeight: 500,
        fontSize: 10,
        textTransform: 'uppercase',
    },
    pageImage: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        display: 'block',
        objectFit: 'cover',
        top: 0,
        left: 0,
        zIndex: 1
    },
    overlay: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        zIndex: 9,
        backgroundColor: 'rgba(0,0,0,0.3)',
    },
    leafletContainer: {
        width: '100%',
        height: '100vh',
    },
    warningtxt: {
        color: 'red'
    }
}));

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

function MapScreen() {
    const classes = useStyles();
    const app = useSelector(state => state.app)
    const data = useSelector((state) => state.app?.userinfo == null ? null : state.app?.userinfo);

    const dispatch = useDispatch();
    const { token } = useParams();
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    const [currentDate, setCurrentDate] = useState(new Date());


    let history = useHistory();


    //custoemr
    const deliveryContactLatitude = app?.userinfo?.deliveryContactLatitude || 45.4644794;
    const deliveryContactLongitude = app?.userinfo?.deliveryContactLongitude || 9.1873669;
    const position = useMemo(() => [deliveryContactLatitude, deliveryContactLongitude], [deliveryContactLatitude, deliveryContactLongitude]);

    // Driver
    const [driverPosition, setDriverPosition] = useState([app?.userinfo?.deliveryDriverLat || null, app?.userinfo?.deliveryDriverLng || null]);

    const dateTo = new Date(app?.userinfo?.taskEtaTo);
    const shouldShowEta = true//dateTo > new Date()
    const etaData = dateTo.toLocaleDateString();
    const etaTime = dateTo.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

    // Delivery
    const [delivery, setDelivery] = useState(data?.steps?.find(elemento => elemento.stepType === 1) || null);
    const isNotDeliveryingYet = delivery?.status == 3;
    const needToShowDriverOnMap = delivery?.status == 4;
    const isCompletedSuccessfully = delivery?.status == 5;
    useEffect(() => {
        //console.log(isCompletedSuccessfully, " status -> ", delivery.status)

        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    useEffect(() => {
        if (isCompletedSuccessfully) {
            history.push({
                pathname: '/success/' + token,
            });
        }
        //it's useless to see this page if the driver isn't currently shipping this delivery
        if (isNotDeliveryingYet) {
            history.push({
                pathname: '/confirm/' + token,
            });
        }
    }, [isCompletedSuccessfully, isNotDeliveryingYet])
    useEffect(() => {
        if (token !== undefined) {
            dispatch(getDeliveryInfo(token));
            dispatch(getCoordinates(token))
        }
        // Set refresh timer
        setInterval(() => {
            dispatch(getCoordinates(token))
        }, 15000);
    }, [token, dispatch]);

    useEffect(() => {
        setDriverPosition([app?.userinfo?.deliveryDriverLat || null, app?.userinfo?.deliveryDriverLng || null])
    }, [app.userinfo]);

    useEffect(() => {
        if (app.driverPosition) {
            // let itemData = app.driverPosition.data;
            setDriverPosition([app.driverPosition.data.latitude, app.driverPosition.data.longitude]);
            setCurrentDate(new Date());
        }
    }, [app.driverPosition, dispatch])

    useEffect(() => {
        // let itemData = app.driverPosition.data;
        // driverPosition[0] = app.driverPosition.latitude;
        // driverPosition[1] = app.driverPosition.longitude;
        if (app?.userinfo) {
            let deliveryData = app?.userinfo.steps?.find(elemento => elemento.stepType === 1) || null;
            setDelivery(deliveryData)
        }

    }, [app.userinfo, app.driverPosition, dispatch])


    useEffect(() => {
        window.scroll({
            top: document.body.offsetHeight,
            left: 0,
            behavior: 'smooth',
        });
    }, [])

    const newLocal = <>

        <MapContainer center={position} zoom={12}
            style={{ height: '100%', width: '90%', paddingTop: '5rem' }}>
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker position={position} icon={deliveryIcon}></Marker>
            ({(needToShowDriverOnMap && driverPosition[0]) && <Marker position={driverPosition} icon={fattorinoIcon}></Marker>})
        </MapContainer>
    </>
    function formatPhoneNumber(number) {
        const nmb = number.split("")
        console.log(nmb)
        nmb?.splice(3, 0, " ")
        console.log(nmb)
        nmb?.splice(7, 0, " ")
        nmb?.splice(12, 0, " ")

        return nmb.join("")

    }
    return (

        <div className={classes.root}>
            {/* <div>Customer {position[0]} - {position[1]}</div>
            <div>Driver {driverPosition[0]} - {driverPosition[1]}</div> */}
            <br />
            {position != null && position[0] != null ? newLocal : null}

            <div className={classes.details} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', lineHeight: '2' }}>

                    <span className={windowDimensions.height <= 667 ? classes.detailswordstwo : classes.detailswords}>
                        {delivery?.driver?.split(" ")?.[0] || '-'} è in arrivo con il suo ordine <b>{app?.userinfo?.businessPartnerDescription} {app?.userinfo?.externalOrderCode}</b>
                        <br /></span>
                    {delivery?.driverPhone && <a href={"tel:" + delivery?.driverPhone}>{"Contatta il driver al numero: " + formatPhoneNumber(delivery?.driverPhone)}</a>}
                    {shouldShowEta && <span style={{ textTransform: 'none', textAlign: 'left' }}>
                        <img src={clockIconSvg} alt='clock' style={{ width: 11, marginRight: 8 }} />
                        <span>La consegna è prevista alle ore </span>
                        {moment(app.driverPosition?.data?.estimatedEta).format("HH:mm")}

                        <br />
                    </span>}
                </div>
                <div style={{ width: '50px' }}></div>
            </div>
            <div className={classes.words} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', lineHeight: '2' }}>
                <span className={classes.spantextthree}>{app?.userinfo?.deliveryContact || '-'}</span>
                {(app?.userinfo?.deliveryInterPhone == false) && <span className={classes.warningtxt}><b>Citofono mancante</b></span>}

                <span className={classes.spantexttwo}>
                    {app?.userinfo?.to || '-'}
                    <br />Citofono: {app?.userinfo?.deliveryInterPhone || '-'}<br />Note: {app?.userinfo?.deliveryNote || '-'}
                </span>
                <br />
            </div>
            <div className={classes.iconss}>
                <Footer />
            </div>
        </div>


    )
}

export default MapScreen;
