import React, { Fragment } from "react";
import DateFnsUtils from '@date-io/date-fns';
import Time from '@material-ui/icons/AccessTime'
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
  } from '@material-ui/pickers';

function BasicTimePicker({value, onClick, onBlur, onChange, label, error, helperText}) {
  return (
    <Fragment>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardTimePicker
                      autoOk
                    //   className={classes.dates}
                    fullWidth
                      variant="inline"
                      inputVariant="outlined"
                      margin="normal"
                      ampm={false}
                      id={label}
                      label={label}
                      onFocus={onClick}
                      onBlur={onBlur}
                      helperText={helperText}
                      value={value}
                      error={error}
                      onChange={onChange}
                      keyboardIcon={<Time onClick={onClick}/>}
                      KeyboardButtonProps={{
                        disabled: true,
                        'aria-label': 'change time'
                      }}
                      InputProps={{ readOnly: true }}
                    />
        </MuiPickersUtilsProvider>
      
    </Fragment>
  );
}

export default BasicTimePicker;