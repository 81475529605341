import '../App.css';
import { makeStyles } from '@material-ui/core';
import React from 'react'
import loaderImage from '../assets/wora-white-logo.png';
import backImage from '../assets/back.jpg';

const useStyles = makeStyles((theme) => ({
   root: {
       display: 'flex',
       flexDirection: 'column',
       flex: 1,
       width: '100%',
       minHeight: '100vh',
       overflowY: 'auto',
       overflowX: 'hidden',
       backgroundImage: `url(${backImage})`,
       backgroundSize: '100%',
       backgroundPosition: 'center',
    //    backgroundBlendMode: 'lighten'
   },
   pageImage: {
    display: 'inline-block',
    padding: theme.spacing(1),
    color: '#fff',
    maxWidth: '7em',
},
   heading: {
       flex: 1,
       display: 'flex',
       flexDirection: 'column',
       alignItems: 'center',
       justifyContent: 'flex-end'
   },
   body: {
       flex: 5,
       backgroundColor: '#fff',
       borderTopRightRadius: 30,
       borderTopLeftRadius: 30,
       boxShadow: '0px 0px 10px -4px rgba(0,0,0,0.75)',
   },
   logo: {
       height: 50,
       width: '90%',
       position: 'relative',
       backgroundColor: theme.palette.primary.light,
       borderTopRightRadius: 40,
       borderTopLeftRadius: 40,
       alignItems: 'center',
       justifyContent: 'center',
       display: 'flex',
       flexDirection: 'row'
       
   },
   arrow: {
       height: 50,
       display: 'flex',
       flexDirection: 'column',
       alignItems: 'center',
       justifyContent: 'center',
       flex: 1
   },
   icon: {
       color: '#fff',
       fontSize: 20,

   },
   icontext: {
    color: '#fff',
    fontSize: 12,
    lineHeight: 0,
    marginTop: 10
}
  }));

function Layout(props) {
  const classes = useStyles();
  return (
   <div className={classes.root}>
       <div className={classes.heading}>
           <div className={classes.logo}>
           <img src={loaderImage} className={classes.pageImage} alt="Loading"/>
           </div>
       </div>
       <div className={classes.body}>
           {props.children}
       </div>
   </div>
   
  )
}

export default Layout;
