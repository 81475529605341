import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Calender from '../icons/clock.svg';
import Calenderpurple from '../icons/clockpurple.svg';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    width: '90%',
    marginBottom: 10,
    backgroundColor: '#eeeeee',
    height: 50,
    borderRadius: 25,
    border: '0px',
  },
  containertwo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    width: '90%',
    marginBottom: 20,
    backgroundColor: '#eeeeee',
    height: 45,
    borderRadius: 25,
    border: '0px',
  },
  textField: {
    width: '100%',
    position: 'relative',
    
   
    backgroundColor: '#eeeeee',
    padding: 10
  },
  word: {
    color: '#000 !important',
    fontSize: 16,
    marginLeft: 5
  }
}));

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

export default function TimePickers({color, words, onClick}) {
  const classes = useStyles();
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());


  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
  
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return (
    <div className={windowDimensions.height <= 568 ? classes.containertwo : classes.container} onClick={onClick}>
     {color === 'purple' ? <img src={Calenderpurple} style={{width: 20}} alt='clock'/> : <img src={Calender} style={{width: 20}} alt='clock'/>}<span className={classes.word}>{words ? words : '16:30-17:30'}</span>
    </div>
  );
}
