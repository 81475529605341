import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import check from '../icons/check.svg';

interface IAlertDialogProps
{
  open: boolean,
  content: string,
  handleClose: ()=>void
}
export default function genericModal({open, content, handleClose}:IAlertDialogProps) {

  return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{""}</DialogTitle>
        <DialogContent style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
          <img src={check} alt='checked' style={{width: 60, marginBottom: 10}}/>
          <DialogContentText id="alert--description" style={{marginBottom: 30, textAlign: 'center'}}>
             <span style={{color: '#222222', fontSize: 22}}>Grazie,</span>&nbsp;&nbsp;<span style={{fontWeight: '800', fontSize: 22, color: '#222222'}}>{content}</span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        </DialogActions>
      </Dialog>
  );
}