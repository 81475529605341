import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Select, {components} from 'react-select';
import Arrow from '@material-ui/icons/KeyboardArrowDown';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        width: '100%',
        backgroundColor: '#eeeeee',
        borderRadius: 50,
        marginTop: 10
      },
      textField: {
        width: '100%',
        position: 'relative',
        height: 45,
        border: '0px',
        // paddingLeft: 20,
        // paddingRight: 20,
        backgroundColor: '#eeeeee',
        borderRadius: 50,
        color: '#8D8D8D'
      },
      button: {
          backgroundColor: theme.palette.primary.light,
          width: 50,
          padding: 0,
          height: 50,
          border: 0,
          borderTopRightRadius: 10,
          borderBottomRightRadius: 10
      },
      arrow: {
          color: '#fff'
      },
      root: {
          width: '80%'
      },
      label: {
        color: 'grey',
        fontWeight: '400',
        fontSize: 14,
        marginLeft: 10
    }
}));

const customStyles = {
  container: (styles) => ({
    ...styles,
     width: '100%',
     height: 45
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#000'
  }),
  control: (styles) => ({
    ...styles,
    backgroundColor: '#eeeeee',
    height: 45,
    borderRadius: 50,
    paddingLeft: 10,
    // display: 'flex',
    // flexDirection: 'row',
    border: 0
 }),
  // option: (provided, state) => ({
  //   ...provided,
  //   borderBottom: '1px dotted pink',
  //   color: state.isSelected ? 'red' : 'blue',
  //   padding: 20,
  // }),
  // singleValue: (provided, state) => {
  //   const opacity = state.isDisabled ? 0.5 : 1;
  //   const transition = 'opacity 300ms';

  //   return { ...provided, opacity, transition };
  // }
}

export default function DatePickers({label, id, value, onChange, onClick, options}) {
  const classes = useStyles();
  
  const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        <Arrow  style={{color: "grey" , marginRight: 5}}/>
      </components.DropdownIndicator>
    );
  };
  
  return (
    <div className={classes.root}>
    <label htmlFor={id} className={classes.label}>{label}</label>
    <div className={classes.container}>
      <Select
      id={id}
        label={label}
        type="text"
        value={value}
        styles={customStyles}
        onChange={onChange}
        options={options}
        components={{
          IndicatorSeparator: () => null,
          DropdownIndicator
        }}
      />
    </div>
    </div>
  );
}