import '../App.css';
import { makeStyles, Button, CircularProgress } from '@material-ui/core';
import React, { useState, useEffect } from 'react'
import Textarea from './textareatwo';
import loaderImage from '../assets/wora_logo.png';
import Rating from 'react-rating';
import envelopimage from '../icons/send.svg';
import Group from '../icons/purplestar.svg';
import Grouptwo from '../icons/star.svg';
import { useDispatch, useSelector } from 'react-redux';
import { clearsuccess, sendFeedback } from '../store/actions/appactions';
import Successmodal from './successmodal';
import { useParams } from 'react-router-dom';
import Footer from './footer';
import { hasFeedback } from '../store/actions/appactions';
import { getDeliveryInfo } from '../store/actions/appactions';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: '100vh',
        backgroundColor: '#fff'
    },
    icons: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '90%',
        height: 100
    },
    iconstwo: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '90%',
        height: 100,
        marginTop: 20
    },
    iconss: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
    },
    rounddiv: {
        height: '15px',
        width: '15px',
        borderRadius: '7.5px',
        backgroundColor: 'orange'
    },
    heading: {
        textAlign: 'center',
        color: '#393939',
        fontSize: 22,
        fontWeight: 400
    },
    headingheading: {
        textAlign: 'center',
        color: '#393939',
        fontSize: 20,
        fontWeight: 400,
        marginTop: 20
    },
    headingheadingtwo: {
        textAlign: 'center',
        color: '#393939',
        fontSize: 18,
        fontWeight: 400,
        marginTop: 20
    },
    headingtwo: {
        fontWeight: 400,
        fontSize: 18,
        maxWidth: '70%',
        textAlign: 'center',
        color: '#393939',
    },
    headingtwotwo: {
        fontWeight: 400,
        fontSize: 16,
        maxWidth: '80%',
        textAlign: 'center',
        color: '#393939',
    },
    buttoncontainer: {
        width: '90%'
    },
    headingsix: {
        fontSize: 18,
        fontWeight: 500,
        lineHeight: 0,
        color: '#393939',
        textAlign: 'center'
    },
    spantext: {
        fontSize: 18,
        fontWeight: '400',
        maxWidth: '80%',
        textAlign: 'center',
        color: '#393939',
        marginTop: '-10px'
    },
    spantexttwo: {
        fontSize: 18,
        marginTop: 20,
        fontWeight: '400',
        color: '#393939',
        maxWidth: '80%',
        textAlign: 'center'
    },
    headingone: {
        fontSize: 20,
        fontWeight: '400'
    },
    icontext: {
        display: 'flex',
        alignItems: 'center',
        fontSize: 12,
        fontWeight: '500',
        color: 'black',
        textDecoration: 'none'
    },
    icontexttwo: {
        display: 'flex',
        alignItems: 'center',
        fontSize: 9,
        fontWeight: '800',
        textDecoration: 'none',
        color: 'black',
    },
    iconsocial: {
        color: '#930483',
        width: 15,
        marginRight: 5,

    },
    typocontainer: {
        width: '90%'
    },
    pageImage: {
        display: 'inline-block',
        paddingTop: theme.spacing(5),
        color: '#fff',
        maxWidth: '10em',
    },
    button: {
        width: 220,
        height: 40,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 15,
        border: '1px solid #930483',
        textTransform: 'none',
        color: '#930483',
        fontSize: 18,
        fontWeight: '500'
    },
    buttontwo: {
        width: 220,
        height: 40,
        marginTop: 30,
        marginBottom: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 15,
        border: '1px solid #930483',
        textTransform: 'none',
        color: '#930483',
        fontSize: 18,
        fontWeight: '500'
    },
    star: {
        paddingTop: 30,
        paddingBottom: 30
    }
}));


function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

function FeedbackScreen() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { token } = useParams();
    const app = useSelector(state => state.app)
    const hasFeedbackSend = (app) ? app.hasfeedback : false;
    const [note, setNote] = useState('');
    const [review, setReview] = useState(0);
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    const onSubmit = (e) => {
        e.preventDefault();
        dispatch(sendFeedback(review, note, token, app.sessionName));
    }

    const handleClose = () => {
        dispatch(hasFeedback(token));
    }

    /** Load  data */
    useEffect(() => {
        if (token !== undefined) {
            dispatch(clearsuccess())
            dispatch(hasFeedback(token));
        }
    }, [token, dispatch]);

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (token !== undefined) {
            dispatch(getDeliveryInfo(token));
        }
    }, [token, dispatch]);
    const newLocal = <>
        <Successmodal content={app?.customer_name_delivery || null} open={app.feedback} handleClose={handleClose} />
        <div className={classes.iconss}>
            <div className={windowDimensions.height <= 568 ? classes.headingheadingtwo : windowDimensions.height <= 667 ? classes.headingheading : classes.heading}>
                Come valuta il servizio di WORA?
            </div>
        </div>
        <div style={{display:'flex',flexDirection:'column',justifyContent:'center'}}>
            <Rating className={windowDimensions.height <= 667 ? classes.star : ''} initialRating={review} emptySymbol={<img src={Group} style={{ marginLeft: 5, marginRight: 5, width: 26 }} alt="star empty" />} onChange={(value) => setReview(value)} fullSymbol={<img src={Grouptwo} style={{ marginLeft: 5, marginRight: 5, width: 26 }} alt="star filled" />} />
            <span style={{alignSelf:'center'}}><b>{review + "/5"}</b></span>
        </div>

        <h1 className={classes.headingone}>Grazie per la sua valutazione!</h1>
        <h1 className={windowDimensions.height <= 568 ? classes.headingtwotwo : classes.headingtwo}>Può inserire una recensione compilando il seguente campo.</h1>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <Textarea value={note} onChange={(e) => setNote(e.target.value)} />
        </div>
        <Button onClick={(e) => onSubmit(e)} variant="outlined" className={windowDimensions.height <= 763 ? classes.buttontwo : classes.button}>{app.loading ? <CircularProgress size={20} /> : 'Invia'}  <img src={envelopimage} style={{ marginLeft: 10, width: 20 }} alt="envelope" /></Button>
    </>;

    const newFeedback =
        <>
            <div className={classes.iconss}>
                <div className={windowDimensions.height <= 568 ? classes.headingheadingtwo : windowDimensions.height <= 667 ? classes.headingheading : classes.heading}>
                    Feedback già inviato
                </div>
            </div>
        </>
    return (
        <>
            <div className={classes.root}>
                <img src={loaderImage} className={classes.pageImage} alt="heading" />
                {hasFeedbackSend ? newFeedback : newLocal}
                <div className={windowDimensions.height <= 568 ? classes.iconstwo : classes.icons}>
                    <Footer />
                </div>
            </div>
        </>
    )
}

export default FeedbackScreen;
