import '../App.css';
import { makeStyles } from '@material-ui/core';
import React, {useState, useEffect} from 'react'
import loaderImage from '../assets/wora_logo.png';
import Button from './controls/RoundButton';
import linkPhoto from '../icons/edit.svg';
import { useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import Footer from './footer';

const useStyles = makeStyles((theme) => ({
   root: {
       display: 'flex',
       flexDirection: 'column',
       alignItems: 'center',
       justifyContent: 'space-around',
       height: '100vh',
       backgroundColor: '#fff'
   },
   pageImage: {
    display: 'inline-block',
    paddingTop: theme.spacing(5),
    color: '#fff',
    maxWidth: '10em',
},
   icons: {
       display: 'flex',
       flexDirection: 'row',
       alignItems: 'flex-start',
       justifyContent: 'space-between',
       width: '90%',
       height: 80,
       marginTop: 20
   },
   rounddiv: {
       height: '15px',
       width: '15px',
       borderRadius: '7.5px',
       backgroundColor: 'orange'
   },
   heading: {
       textAlign: 'center',
       color: '#909090',
       fontSize: 15,
       maxWidth: 280,
       fontWeight: 500
   },
   headingtwo: {
    fontWeight: 700,
    fontSize: 24,
    maxWidth: '80%',
    textAlign: 'center',
    color: '#393939',
    marginTop: '0px'
   },
   headingtwotwo: {
    fontWeight: 700,
    fontSize: 24,
    maxWidth: '80%',
    textAlign: 'center',
    color: '#393939',
    marginTop: '30px'
   },
   buttoncontainer: {
       width: '90%',
       minHeight: 150,
       marginBottom: -20
   },
   buttoncontainertwo: {
    width: '90%',
    minHeight: 150,
    marginBottom: 0
},
buttoncontainerthree: {
    width: '90%',
    minHeight: 150,
    marginTop: 30
},
   headingsix: {
       fontSize: 18,
       fontWeight: 400,
       color: '#393939',
       textAlign: 'center',
       margin: 0
   },
   headingsixsix: {
    fontSize: 18,
    fontWeight: 400,
    color: '#393939',
    textAlign: 'center',
    paddingBottom: 0,
    margin: 40
},
headingsixsixtwo: {
    fontSize: 18,
    fontWeight: 400,
    color: '#393939',
    textAlign: 'center',
    paddingBottom: 0,
    margin: 0
},
   spantext: {
       fontSize: 18,
       fontWeight: '400',
       maxWidth: '80%',
       textAlign: 'center',
       color: '#393939',
       marginTop: '0px'
   },
   spantexttwo: {
    fontSize: 16,
    marginTop: '-50px',
    fontWeight: '400',
    color: '#7D7D7D',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
},
spantexttwotwo: {
    fontSize: 16,
    marginTop: '-50px',
    marginBottom: 20,
    fontWeight: '400',
    color: '#7D7D7D',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
},
spantextfour: {
    fontSize: 14,
    marginTop: '-50px',
    marginBottom: 20,
    fontWeight: '400',
    color: '#7D7D7D',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
},
headingalt: {
    fontWeight: 700,
    fontSize: 24,
    maxWidth: '80%',
    textAlign: 'center',
    color: '#393939',
    paddingTop: 20,
    margin: 0
   },
   headingalttwo: {
    fontWeight: 700,
    fontSize: 24,
    maxWidth: '80%',
    textAlign: 'center',
    color: '#393939',
    marginTop: 50,
    margin: 0
   },
   headingalttwotwo: {
    fontWeight: 700,
    fontSize: 24,
    maxWidth: '80%',
    textAlign: 'center',
    color: '#393939',
    marginTop: 20,
    marginBottom: 20,
    margin: 0
   },
//    headingone: {
//        marginBottom: '-20px'
//    },
   icontext: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 12,
    fontWeight: '800',
    textDecoration: 'none',
    color: 'black'
   },
   icontexttwo: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 9,
    fontWeight: '800',
    textDecoration: 'none',
    color: 'black',
   },
   iconsocial: {
       width: 15,
       marginRight: 5
   },
   typocontainer: {
       width: '90%'
   }
  }));

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }


function RescheduleScreen() {
  const classes = useStyles();
  const app = useSelector(state => state.app)
  let history = useHistory();
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
  
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const onsubmit = () => {
    history.push("/confirm");
  }

  const onsend = () => {
    history.push("/details");
  }

  return (
        <div className={classes.root}>
       <img src={loaderImage} className={classes.pageImage} alt="location img"/>
       {/* <h2 className={windowDimensions.height <= 667 ? classes.headingtwotwo : classes.headingtwo}>Gentile Sig.ra {app?.userinfo?.customer_name_delivery || '-' },</h2> */}
       <span className={classes.spantext}>C’e stato un problema con il suo ordine </span>
       {/* <img src={locationImage} alt="Loading image"/> */}
       <h2 className={windowDimensions.height <= 568 ? classes.headingalttwotwo : windowDimensions.height <= 667 ? classes.headingalttwo : classes.headingalt}>{app?.userinfo?.customer_name_pickup || '-' }</h2>
      <h6 className={ windowDimensions.height <= 568 ? classes.headingsixsixtwo : windowDimensions.height <= 667 ? classes.headingsixsix : classes.headingsix}>Clicchi qui per riprogrammare un<br/> appuntamento di consegna</h6>
       <div className={windowDimensions.height <= 568 ? classes.buttoncontainerthree : windowDimensions.height <= 667 ? classes.buttoncontainertwo : classes.buttoncontainer}>
       <Button text="riprogrammare consegna" onClick={() => onsubmit()} variant="contained" link='/thankyouscreen'/>
       </div>
       <span className={windowDimensions.height <= 667 ? classes.spantextfour : windowDimensions.height <= 667 ? classes.spantexttwotwo : classes.spantexttwo} onClick={() => onsend()}><img src={linkPhoto} className={classes.iconsocial} alt="Linkimgtwo"/>MODIFICA INFORMAZIONI DI CONSEGNA</span>
       <div className={classes.icons}>
       <Footer/>
       </div>
   </div>  
   
  )
}

export default RescheduleScreen;
