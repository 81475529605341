export const SUCCESS_USERINFO = 'SUCCESS_USERINFO';
export const LOADING = 'LOADING';
export const ERROR = 'ERROR';
export const SUCCESS_MODIFY = 'SUCCESS_MODIFY';
export const SUCCESS_REVIEW = 'SUCCESS_REVIEW';
export const CLEARERROR = 'CLEARERROR';
export const CLEARSUCCESS = 'CLEARSUCCESS';
export const CONFIRMLOADING = 'CONFIRMLOADING';
export const SUCCESS_CONFIRM = 'SUCCESS_CONFIRM';
export const SETTIME = 'SETTIME';
export const DRIVER_COORDINATE = 'DRIVER_COORDINATE';
export const LOAD_REVIEW = 'LOAD_REVIEW';

