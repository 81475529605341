import { useEffect, useState } from 'react';
import '../App.css';
import { makeStyles } from '@material-ui/core';
import React from 'react'
import globeImage from '../icons/globe.svg';
import instagramImage from '../icons/instagram.svg';
import Phone from '../assets/wb.png'

function Footer() {
    const useStyles = makeStyles(themes => ({
        root: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between',
            backgroundColor: '#fff',
            height: '100vh'
        },
        icontexttwo: {
            display: 'flex',
            alignItems: 'center',
            fontSize: 9,
            // fontWeight: 'normal',
            textDecoration: 'none',
            color: 'black',
        },
        icontext: {
            display: 'flex',
            alignItems: 'center',
            fontSize: 12,
            // fontWeight: 'normal',
            textDecoration: 'none',
            color: 'black'
        },
        iconss: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '90%',
            flex: 1,
        },
        iconsocial: {
            width: 15,
            marginRight: 5
        },
        iconsocialtwo: {
            width: 30,
            color: '#222222'
        },
        // rounddiv: {
        //     height: '15px',
        //     width: '15px',
        //     borderRadius: '7.5px',
        //     backgroundColor: 'orange'
        // },
        // heading: {
        //     textAlign: 'center',
        //     color: '#909090',
        //     fontSize: 15,
        //     maxWidth: 280,
        //     fontWeight: 500
        // },
        // headingtwo: {
        //     fontWeight: 700,
        //     fontSize: 24,
        //     maxWidth: '80%',
        //     textAlign: 'center',
        //     color: '#393939',
        //     marginTop: '-10px'
        // },
        // buttoncontainer: {
        //     width: '90%'
        // },
        // headingsix: {
        //     fontSize: 18,
        //     fontWeight: 'normal',
        //     lineHeight: 0,
        //     color: '#393939',
        //     textAlign: 'center'
        // },
        // spantext: {
        //     fontSize: 18,
        //     // fontWeight: '400',
        //     maxWidth: '80%',
        //     textAlign: 'center',
        //     color: '#393939',
        //     marginTop: '-10px'
        // },
        // spantexttwo: {
        //     fontSize: 14,
        //     // fontWeight: '400',
        //     color: '#252525',
        //     textAlign: 'center'
        // },
        // spantextthree: {
        //     fontSize: 14,
        //     fontWeight: 'normal',
        //     color: '#252525',
        //     textAlign: 'center'
        // },
        // // //    headingone: {
        // // //        marginBottom: '-20px'
        // // //    },
        
        // typocontainer: {
        //     width: '90%'
        // },
        // words: {
        //     flex: 2,
        //     display: 'flex',
        //     flexDirection: 'column',
        //     alignItems: 'center',
        //     width: '100%',
        //     justifyContent: 'center'
        // },
        // details: {
        //     backgroundColor: '#fff',
        //     flex: 1,
        //     width: '100%',
        //     display: 'flex',
        //     flexDirection: 'row',
        //     alignItems: 'center',
        //     justifyContent: 'space-evenly',
        //     borderBottom: "1px solid black",
        //     paddingBottom: 5,
        //     paddingTop: 5
        // },
        // imagecircle: {
        //     width: 50,
        //     height: 50,
        //     borderRadius: 25,
        //     border: '1px solid black',
        //     backgroundColor: '#eeeeee',
        //     overflow: 'hidden'
        // },
        // detailswords: {
        //     textAlign: 'center',
        //     fontWeight: 'normal',
        //     fontSize: 12,
        //     textTransform: 'uppercase',
        // },
        // detailswordstwo: {
        //     textAlign: 'center',
        //     fontWeight: 'normal',
        //     fontSize: 10,
        //     textTransform: 'uppercase',
        // },
        // pageImage: {
        //     position: 'absolute',
        //     width: '100%',
        //     height: '100%',
        //     display: 'block',
        //     objectFit: 'cover',
        //     top: 0,
        //     left: 0,
        //     zIndex: 1
        // },
        // overlay: {
        //     position: 'absolute',
        //     width: '100%',
        //     height: '100%',
        //     top: 0,
        //     left: 0,
        //     zIndex: 9,
        //     backgroundColor: 'rgba(0,0,0,0.3)',
        // }
    }));

    const classes = useStyles();
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    function getWindowDimensions() {
        const { innerWidth: width, innerHeight: height } = window;
        return {
            width,
            height
        };
    }

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);


    return (
        <>
                    <a href='https://www.woradelivery.com/' className={windowDimensions.height <= 568 ? classes.icontexttwo : classes.icontext}><span className={windowDimensions.height <= 568 ? classes.icontexttwo : classes.icontext}><img src={globeImage} className={classes.iconsocial} alt="socialimg" /> <b>SITO WEB</b></span></a>

            <a href='https://api.whatsapp.com/send?phone=390230568029' className={windowDimensions.height <= 568 ? classes.icontexttwo : classes.icontext}><span className={windowDimensions.height <= 568 ? classes.icontexttwo : classes.icontext}><img src={Phone} className={classes.iconsocial} alt="teleimage" /><b>CUSTOMER SERVICE</b></span></a>
            <a href='https://www.instagram.com/woradelivery/
' className={windowDimensions.height <= 568 ? classes.icontexttwo : classes.icontext}><span className={windowDimensions.height <= 568 ? classes.icontexttwo : classes.icontext}><img src={instagramImage} className={classes.iconsocial} alt="instaimg" /> <b>INSTAGRAM</b></span></a>
        </>
    )

}

export default Footer;
