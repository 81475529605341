/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface DmApplicationComboxesComboboxItemDto {
  value?: string | null;
  displayText?: string | null;
  isSelected?: boolean;
}

export interface DmApplicationComboxesComboboxItemIntDto {
  /** @format int32 */
  value?: number | null;
  description?: string | null;
  disabled?: boolean;
}

export interface DmApplicationComboxesComboboxItemLongDto {
  /** @format int64 */
  value?: number | null;
  description?: string | null;
  disabled?: boolean;
}

export interface DmCrmBusinessPartnerTenantLinksBusinessPartnerTenantLinkCreateDto {
  extraField?: DmDynamicDataEntitiesExtraFieldJsonObjectDto;
  /** @format int64 */
  businessPartnerId?: number;
  /** @format uuid */
  userSpaceId?: string;
}

export interface DmCrmBusinessPartnerTenantLinksBusinessPartnerTenantLinkDto {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  creationTime?: string;
  /** @format uuid */
  creatorId?: string | null;
  /** @format date-time */
  lastModificationTime?: string | null;
  /** @format uuid */
  lastModifierId?: string | null;
  /** @format int64 */
  businessPartnerId?: number;
  /** @format uuid */
  userSpaceId?: string;
  concurrencyStamp?: string | null;
  applicationName?: string | null;
}

export interface DmCrmBusinessPartnerTenantLinksBusinessPartnerTenantLinkUpdateDto {
  extraField?: DmDynamicDataEntitiesExtraFieldJsonObjectDto;
  /** @format int64 */
  businessPartnerId?: number;
  /** @format uuid */
  userSpaceId?: string;
  concurrencyStamp?: string | null;
}

export interface DmCrmBusinessPartnerTenantLinksGetBusinessPartnerTenantLinksInput {
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  maxResultCount?: number;
  /**
   * @format int32
   * @min 0
   * @max 2147483647
   */
  skipCount?: number;
  sorting?: string | null;
  filters?: DmFilterApplicationContractsFiltersTelerikFilterDto;
  maps?: DmFilterApplicationContractsFiltersFilterMapDto[] | null;
  filterText?: string | null;
  /** @format int64 */
  businessPartnerIdMin?: number | null;
  /** @format int64 */
  businessPartnerIdMax?: number | null;
  /** @format uuid */
  userSpaceId?: string | null;
}

export interface DmCrmBusinessPartnerUserLinksBusinessPartnerUserLinkCreateDto {
  /** @format int64 */
  businessPartnerId?: number;
  /** @format uuid */
  userId?: string;
}

export interface DmCrmBusinessPartnerUserLinksBusinessPartnerUserLinkDto {
  /** @format int64 */
  id?: number;
  /** @format date-time */
  creationTime?: string;
  /** @format uuid */
  creatorId?: string | null;
  /** @format date-time */
  lastModificationTime?: string | null;
  /** @format uuid */
  lastModifierId?: string | null;
  isDeleted?: boolean;
  /** @format uuid */
  deleterId?: string | null;
  /** @format date-time */
  deletionTime?: string | null;
  /** @format int64 */
  businessPartnerId?: number;
  /** @format uuid */
  userId?: string;
  concurrencyStamp?: string | null;
}

export interface DmCrmBusinessPartnerUserLinksBusinessPartnerUserLinkUpdateDto {
  /** @format int64 */
  businessPartnerId?: number;
  /** @format uuid */
  userId?: string;
  concurrencyStamp?: string | null;
}

export interface DmCrmBusinessPartnerUserLinksGetBusinessPartnerUserLinksInput {
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  maxResultCount?: number;
  /**
   * @format int32
   * @min 0
   * @max 2147483647
   */
  skipCount?: number;
  sorting?: string | null;
  filterText?: string | null;
  /** @format int64 */
  businessPartnerIdMin?: number | null;
  /** @format int64 */
  businessPartnerIdMax?: number | null;
  /** @format int64 */
  businessPartnerId?: number;
  /** @format uuid */
  userId?: string | null;
  userIds?: string[] | null;
}

export interface DmCrmBusinessPartnersBusinessPartnerCreateDto {
  extraField?: DmDynamicDataEntitiesExtraFieldJsonObjectDto;
  /** @format int32 */
  accountId?: number | null;
  /**
   * @minLength 0
   * @maxLength 4
   */
  acronym?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  address?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  addressNumber?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  bankName?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  city?: string | null;
  commercialConsent: boolean;
  /** @format date-time */
  commercialConsentConfirmDate?: string | null;
  /** @format int32 */
  defaultQuantityType: number;
  /**
   * @minLength 0
   * @maxLength 255
   */
  description: string;
  /**
   * @minLength 0
   * @maxLength 512
   */
  emailOrDomainForCustomer?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  fiscalCode?: string | null;
  /**
   * @minLength 0
   * @maxLength 34
   */
  iban?: string | null;
  /** @format double */
  maxBillable?: number | null;
  /** @format double */
  minBillable?: number | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  name?: string | null;
  /** @format int64 */
  nationId: number;
  /**
   * @minLength 0
   * @maxLength 255
   */
  note?: string | null;
  /** @format int32 */
  paymentConditionId?: number | null;
  /**
   * @minLength 0
   * @maxLength 256
   */
  pecEmail?: string | null;
  /** @format int64 */
  priceListId?: number | null;
  privacy: boolean;
  /** @format date-time */
  privacyConfirmDate?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  province?: string | null;
  /**
   * @minLength 0
   * @maxLength 7
   */
  receiverCode?: string | null;
  /**
   * @minLength 0
   * @maxLength 1
   */
  role: string;
  /**
   * @minLength 0
   * @maxLength 255
   */
  sapCode?: string | null;
  /** @format int32 */
  slaId?: number | null;
  /** @format int32 */
  status: number;
  /** @format int32 */
  subjectType: number;
  /** @format int32 */
  supplierTenantId?: number | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  surname?: string | null;
  thirdPartyCommercialConsent: boolean;
  /** @format date-time */
  thirdPartyCommercialConsentConfirmDate?: string | null;
  /**
   * @minLength 0
   * @maxLength 28
   */
  vatID?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  zipCode?: string | null;
  vatFree?: boolean;
  /** @format double */
  latitude?: number | null;
  /** @format double */
  longitude?: number | null;
}

export interface DmCrmBusinessPartnersBusinessPartnerDto {
  /** @format int64 */
  id?: number;
  /** @format date-time */
  creationTime?: string;
  /** @format uuid */
  creatorId?: string | null;
  /** @format date-time */
  lastModificationTime?: string | null;
  /** @format uuid */
  lastModifierId?: string | null;
  isDeleted?: boolean;
  /** @format uuid */
  deleterId?: string | null;
  /** @format date-time */
  deletionTime?: string | null;
  /** @format int32 */
  accountId?: number | null;
  acronym?: string | null;
  address?: string | null;
  addressNumber?: string | null;
  bankName?: string | null;
  city?: string | null;
  commercialConsent?: boolean;
  /** @format date-time */
  commercialConsentConfirmDate?: string | null;
  /** @format int32 */
  defaultQuantityType?: number;
  description?: string | null;
  emailOrDomainForCustomer?: string | null;
  fiscalCode?: string | null;
  iban?: string | null;
  /** @format double */
  maxBillable?: number | null;
  /** @format double */
  minBillable?: number | null;
  name?: string | null;
  /** @format int64 */
  nationId?: number;
  note?: string | null;
  /** @format int32 */
  paymentConditionId?: number | null;
  pecEmail?: string | null;
  /** @format int64 */
  priceListId?: number | null;
  privacy?: boolean;
  /** @format date-time */
  privacyConfirmDate?: string | null;
  province?: string | null;
  receiverCode?: string | null;
  role?: string | null;
  sapCode?: string | null;
  /** @format int32 */
  slaId?: number | null;
  /** @format int32 */
  status?: number;
  /** @format int32 */
  subjectType?: number;
  /** @format int32 */
  supplierTenantId?: number | null;
  surname?: string | null;
  thirdPartyCommercialConsent?: boolean;
  /** @format date-time */
  thirdPartyCommercialConsentConfirmDate?: string | null;
  vatID?: string | null;
  zipCode?: string | null;
  nationName?: string | null;
  vatFree?: boolean;
  /** @format double */
  latitude?: number | null;
  /** @format double */
  longitude?: number | null;
  applicationName?: string | null;
}

export interface DmCrmBusinessPartnersBusinessPartnerUpdateDto {
  extraField?: DmDynamicDataEntitiesExtraFieldJsonObjectDto;
  /** @format int32 */
  accountId?: number | null;
  /**
   * @minLength 0
   * @maxLength 4
   */
  acronym?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  address?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  addressNumber?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  bankName?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  city?: string | null;
  commercialConsent: boolean;
  /** @format date-time */
  commercialConsentConfirmDate?: string | null;
  /** @format int32 */
  defaultQuantityType: number;
  /**
   * @minLength 0
   * @maxLength 255
   */
  description: string;
  /**
   * @minLength 0
   * @maxLength 512
   */
  emailOrDomainForCustomer?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  fiscalCode?: string | null;
  /**
   * @minLength 0
   * @maxLength 34
   */
  iban?: string | null;
  /** @format double */
  maxBillable?: number | null;
  /** @format double */
  minBillable?: number | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  name?: string | null;
  /** @format int64 */
  nationId: number;
  /**
   * @minLength 0
   * @maxLength 255
   */
  note?: string | null;
  /** @format int32 */
  paymentConditionId?: number | null;
  /**
   * @minLength 0
   * @maxLength 256
   */
  pecEmail?: string | null;
  /** @format int64 */
  priceListId?: number | null;
  privacy: boolean;
  /** @format date-time */
  privacyConfirmDate?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  province?: string | null;
  /**
   * @minLength 0
   * @maxLength 7
   */
  receiverCode?: string | null;
  /**
   * @minLength 0
   * @maxLength 1
   */
  role: string;
  /**
   * @minLength 0
   * @maxLength 255
   */
  sapCode?: string | null;
  /** @format int32 */
  slaId?: number | null;
  /** @format int32 */
  status: number;
  /** @format int32 */
  subjectType: number;
  /** @format int32 */
  supplierTenantId?: number | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  surname?: string | null;
  thirdPartyCommercialConsent: boolean;
  /** @format date-time */
  thirdPartyCommercialConsentConfirmDate?: string | null;
  /**
   * @minLength 0
   * @maxLength 28
   */
  vatID?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  zipCode?: string | null;
  vatFree?: boolean;
  /** @format double */
  latitude?: number | null;
  /** @format double */
  longitude?: number | null;
}

export interface DmCrmBusinessPartnersGetBusinessPartnersInput {
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  maxResultCount?: number;
  /**
   * @format int32
   * @min 0
   * @max 2147483647
   */
  skipCount?: number;
  sorting?: string | null;
  filters?: DmFilterApplicationContractsFiltersTelerikFilterDto;
  maps?: DmFilterApplicationContractsFiltersFilterMapDto[] | null;
  filterText?: string | null;
  /** @format int32 */
  accountIdMin?: number | null;
  /** @format int32 */
  accountIdMax?: number | null;
  acronym?: string | null;
  address?: string | null;
  addressNumber?: string | null;
  bankName?: string | null;
  city?: string | null;
  commercialConsent?: boolean | null;
  /** @format date-time */
  commercialConsentConfirmDateMin?: string | null;
  /** @format date-time */
  commercialConsentConfirmDateMax?: string | null;
  /** @format int32 */
  defaultQuantityTypeMin?: number | null;
  /** @format int32 */
  defaultQuantityTypeMax?: number | null;
  description?: string | null;
  emailOrDomainForCustomer?: string | null;
  fiscalCode?: string | null;
  iban?: string | null;
  /** @format double */
  maxBillableMin?: number | null;
  /** @format double */
  maxBillableMax?: number | null;
  /** @format double */
  minBillableMin?: number | null;
  /** @format double */
  minBillableMax?: number | null;
  name?: string | null;
  /** @format int64 */
  nationIdMin?: number | null;
  /** @format int64 */
  nationIdMax?: number | null;
  note?: string | null;
  /** @format int32 */
  paymentConditionIdMin?: number | null;
  /** @format int32 */
  paymentConditionIdMax?: number | null;
  pecEmail?: string | null;
  /** @format int64 */
  priceListIdMin?: number | null;
  /** @format int64 */
  priceListIdMax?: number | null;
  privacy?: boolean | null;
  /** @format date-time */
  privacyConfirmDateMin?: string | null;
  /** @format date-time */
  privacyConfirmDateMax?: string | null;
  province?: string | null;
  receiverCode?: string | null;
  role?: string | null;
  sapCode?: string | null;
  /** @format int32 */
  slaIdMin?: number | null;
  /** @format int32 */
  slaIdMax?: number | null;
  /** @format int32 */
  statusMin?: number | null;
  /** @format int32 */
  statusMax?: number | null;
  /** @format int32 */
  subjectTypeMin?: number | null;
  /** @format int32 */
  subjectTypeMax?: number | null;
  /** @format int32 */
  supplierTenantIdMin?: number | null;
  /** @format int32 */
  supplierTenantIdMax?: number | null;
  surname?: string | null;
  thirdPartyCommercialConsent?: boolean | null;
  /** @format date-time */
  thirdPartyCommercialConsentConfirmDateMin?: string | null;
  /** @format date-time */
  thirdPartyCommercialConsentConfirmDateMax?: string | null;
  vatID?: string | null;
  zipCode?: string | null;
  /** @format double */
  latitude?: number | null;
  /** @format double */
  longitude?: number | null;
}

export interface DmCrmContactsContactCreateDto {
  extraField?: DmDynamicDataEntitiesExtraFieldJsonObjectDto;
  address?: string | null;
  addressNumber?: string | null;
  city?: string | null;
  /** @format int64 */
  nationId?: number | null;
  province?: string | null;
  zipCode?: string | null;
  contactType: DmCrmContactsContactType;
  description?: string | null;
  email?: string | null;
  fax?: string | null;
  firstName?: string | null;
  isDefault: boolean;
  isValidMobile: boolean;
  lastName?: string | null;
  mobile?: string | null;
  /** @format int64 */
  parentId?: number | null;
  parentType: DmCrmContactsParentType;
  phone?: string | null;
  /** @format double */
  latitude?: number | null;
  /** @format double */
  longitude?: number | null;
  customString1?: string | null;
  customString2?: string | null;
  customString3?: string | null;
  customBool1?: boolean;
  customBool2?: boolean;
  customBool3?: boolean;
  /** @format double */
  customDecimal1?: number | null;
  /** @format double */
  customDecimal2?: number | null;
  /** @format double */
  customDecimal3?: number | null;
  /** @format int32 */
  customInt1?: number | null;
  /** @format int32 */
  customInt2?: number | null;
  /** @format int32 */
  customInt3?: number | null;
}

export interface DmCrmContactsContactDto {
  /** @format int64 */
  id?: number;
  /** @format date-time */
  creationTime?: string;
  /** @format uuid */
  creatorId?: string | null;
  /** @format date-time */
  lastModificationTime?: string | null;
  /** @format uuid */
  lastModifierId?: string | null;
  isDeleted?: boolean;
  /** @format uuid */
  deleterId?: string | null;
  /** @format date-time */
  deletionTime?: string | null;
  address?: string | null;
  addressNumber?: string | null;
  city?: string | null;
  /** @format int64 */
  nationId?: number | null;
  province?: string | null;
  zipCode?: string | null;
  contactType?: DmCrmContactsContactType;
  description?: string | null;
  email?: string | null;
  fax?: string | null;
  firstName?: string | null;
  isDefault?: boolean;
  isValidMobile?: boolean;
  lastName?: string | null;
  mobile?: string | null;
  /** @format int64 */
  parentId?: number | null;
  parentType?: DmCrmContactsParentType;
  phone?: string | null;
  /** @format double */
  latitude?: number | null;
  /** @format double */
  longitude?: number | null;
  customString1?: string | null;
  customString2?: string | null;
  customString3?: string | null;
  customBool1?: boolean;
  customBool2?: boolean;
  customBool3?: boolean;
  /** @format double */
  customDecimal1?: number | null;
  /** @format double */
  customDecimal2?: number | null;
  /** @format double */
  customDecimal3?: number | null;
  /** @format int32 */
  customInt1?: number | null;
  /** @format int32 */
  customInt2?: number | null;
  /** @format int32 */
  customInt3?: number | null;
  applicationName?: string | null;
}

/** @format int32 */
export enum DmCrmContactsContactType {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
  Value5 = 5,
  Value6 = 6,
  Value7 = 7,
}

export interface DmCrmContactsContactUpdateDto {
  extraField?: DmDynamicDataEntitiesExtraFieldJsonObjectDto;
  address?: string | null;
  addressNumber?: string | null;
  city?: string | null;
  /** @format int64 */
  nationId?: number | null;
  province?: string | null;
  zipCode?: string | null;
  contactType: DmCrmContactsContactType;
  description?: string | null;
  email?: string | null;
  fax?: string | null;
  firstName?: string | null;
  isDefault: boolean;
  isValidMobile: boolean;
  lastName?: string | null;
  mobile?: string | null;
  /** @format int64 */
  parentId?: number | null;
  parentType: DmCrmContactsParentType;
  phone?: string | null;
  /** @format double */
  latitude?: number | null;
  /** @format double */
  longitude?: number | null;
  customString1?: string | null;
  customString2?: string | null;
  customString3?: string | null;
  customBool1?: boolean;
  customBool2?: boolean;
  customBool3?: boolean;
  /** @format double */
  customDecimal1?: number | null;
  /** @format double */
  customDecimal2?: number | null;
  /** @format double */
  customDecimal3?: number | null;
  /** @format int32 */
  customInt1?: number | null;
  /** @format int32 */
  customInt2?: number | null;
  /** @format int32 */
  customInt3?: number | null;
}

export interface DmCrmContactsGetContactsInput {
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  maxResultCount?: number;
  /**
   * @format int32
   * @min 0
   * @max 2147483647
   */
  skipCount?: number;
  sorting?: string | null;
  filters?: DmFilterApplicationContractsFiltersTelerikFilterDto;
  maps?: DmFilterApplicationContractsFiltersFilterMapDto[] | null;
  filterText?: string | null;
  address?: string | null;
  addressNumber?: string | null;
  city?: string | null;
  /** @format int64 */
  nationId?: number | null;
  province?: string | null;
  zipCode?: string | null;
  contactType?: DmCrmContactsContactType;
  description?: string | null;
  email?: string | null;
  fax?: string | null;
  firstName?: string | null;
  isDefault?: boolean | null;
  isValidMobile?: boolean | null;
  lastName?: string | null;
  mobile?: string | null;
  /** @format int64 */
  parentId?: number | null;
  parentType?: DmCrmContactsParentType;
  phone?: string | null;
  /** @format double */
  latitude?: number | null;
  /** @format double */
  longitude?: number | null;
  customString1?: string | null;
  customString2?: string | null;
  customString3?: string | null;
  customBool1?: boolean;
  customBool2?: boolean;
  customBool3?: boolean;
  /** @format double */
  customDecimal1?: number | null;
  /** @format double */
  customDecimal2?: number | null;
  /** @format double */
  customDecimal3?: number | null;
  /** @format int32 */
  customInt1?: number | null;
  /** @format int32 */
  customInt2?: number | null;
  /** @format int32 */
  customInt3?: number | null;
}

/** @format int32 */
export enum DmCrmContactsParentType {
  Value0 = 0,
  Value1 = 1,
}

export interface DmCrmNationsGetNationsInput {
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  maxResultCount?: number;
  /**
   * @format int32
   * @min 0
   * @max 2147483647
   */
  skipCount?: number;
  sorting?: string | null;
  filters?: DmFilterApplicationContractsFiltersTelerikFilterDto;
  maps?: DmFilterApplicationContractsFiltersFilterMapDto[] | null;
  filterText?: string | null;
  description?: string | null;
  enable?: boolean | null;
  isDefault?: boolean | null;
  isoCode?: string | null;
}

export interface DmCrmNationsNationCreateDto {
  extraField?: DmDynamicDataEntitiesExtraFieldJsonObjectDto;
  description?: string | null;
  enable: boolean;
  isDefault: boolean;
  isoCode?: string | null;
}

export interface DmCrmNationsNationDto {
  /** @format int64 */
  id?: number;
  /** @format date-time */
  creationTime?: string;
  /** @format uuid */
  creatorId?: string | null;
  /** @format date-time */
  lastModificationTime?: string | null;
  /** @format uuid */
  lastModifierId?: string | null;
  isDeleted?: boolean;
  /** @format uuid */
  deleterId?: string | null;
  /** @format date-time */
  deletionTime?: string | null;
  description?: string | null;
  enable?: boolean;
  isDefault?: boolean;
  isoCode?: string | null;
  applicationName?: string | null;
}

export interface DmCrmNationsNationUpdateDto {
  extraField?: DmDynamicDataEntitiesExtraFieldJsonObjectDto;
  description?: string | null;
  enable: boolean;
  isDefault: boolean;
  isoCode?: string | null;
}

export interface DmDynamicDataEntitiesExtraFieldJsonDefinitionDto {
  /** @format int64 */
  id?: number | null;
  code?: string | null;
}

export interface DmDynamicDataEntitiesExtraFieldJsonObjectDto {
  definition?: DmDynamicDataEntitiesExtraFieldJsonDefinitionDto[] | null;
  values?: Record<string, any>;
}

export interface DmFilterApplicationContractsFiltersFilterMapDto {
  technicalName?: string | null;
  baseObject?: string | null;
  propertyName?: string | null;
}

export interface DmFilterApplicationContractsFiltersTelerikFilterDto {
  baseObject?: string | null;
  propertyName?: string | null;
  field?: string | null;
  ignoreCase?: boolean;
  operator?: string | null;
  value?: any;
  logic?: string | null;
  filters?: DmFilterApplicationContractsFiltersTelerikFilterDto[] | null;
}

/** @format int32 */
export enum SystemNetHttpStatusCode {
  Value100 = 100,
  Value101 = 101,
  Value102 = 102,
  Value103 = 103,
  Value200 = 200,
  Value201 = 201,
  Value202 = 202,
  Value203 = 203,
  Value204 = 204,
  Value205 = 205,
  Value206 = 206,
  Value207 = 207,
  Value208 = 208,
  Value226 = 226,
  Value300 = 300,
  Value301 = 301,
  Value302 = 302,
  Value303 = 303,
  Value304 = 304,
  Value305 = 305,
  Value306 = 306,
  Value307 = 307,
  Value308 = 308,
  Value400 = 400,
  Value401 = 401,
  Value402 = 402,
  Value403 = 403,
  Value404 = 404,
  Value405 = 405,
  Value406 = 406,
  Value407 = 407,
  Value408 = 408,
  Value409 = 409,
  Value410 = 410,
  Value411 = 411,
  Value412 = 412,
  Value413 = 413,
  Value414 = 414,
  Value415 = 415,
  Value416 = 416,
  Value417 = 417,
  Value421 = 421,
  Value422 = 422,
  Value423 = 423,
  Value424 = 424,
  Value426 = 426,
  Value428 = 428,
  Value429 = 429,
  Value431 = 431,
  Value451 = 451,
  Value500 = 500,
  Value501 = 501,
  Value502 = 502,
  Value503 = 503,
  Value504 = 504,
  Value505 = 505,
  Value506 = 506,
  Value507 = 507,
  Value508 = 508,
  Value510 = 510,
  Value511 = 511,
}

export interface VoloAbpApplicationDtosListResultDto1DmApplicationComboxesComboboxItemDtoDmApplicationContractsVersion1160CultureNeutralPublicKeyTokenNull {
  items?: DmApplicationComboxesComboboxItemDto[] | null;
}

export interface VoloAbpApplicationDtosListResultDto1DmApplicationComboxesComboboxItemIntDtoDmApplicationContractsVersion1160CultureNeutralPublicKeyTokenNull {
  items?: DmApplicationComboxesComboboxItemIntDto[] | null;
}

export interface VoloAbpApplicationDtosListResultDto1DmApplicationComboxesComboboxItemLongDtoDmApplicationContractsVersion1160CultureNeutralPublicKeyTokenNull {
  items?: DmApplicationComboxesComboboxItemLongDto[] | null;
}

export interface VoloAbpApplicationDtosListResultDto1WebHookManagementWebhookSubscriptionsGetAllAvailableWebhooksOutputWebHookManagementApplicationContractsVersion1010CultureNeutralPublicKeyTokenNull {
  items?: WebHookManagementWebhookSubscriptionsGetAllAvailableWebhooksOutput[] | null;
}

export interface VoloAbpApplicationDtosListResultDto1WoraCorbosServiceShiftsShiftFullDtoWoraCorbosServiceApplicationContractsVersion1000CultureNeutralPublicKeyTokenNull {
  items?: WoraCorbosServiceShiftsShiftFullDto[] | null;
}

export interface VoloAbpApplicationDtosPagedResultDto1DmApplicationComboxesComboboxItemDtoDmApplicationContractsVersion1160CultureNeutralPublicKeyTokenNull {
  items?: DmApplicationComboxesComboboxItemDto[] | null;
  /** @format int64 */
  totalCount?: number;
}

export interface VoloAbpApplicationDtosPagedResultDto1DmApplicationComboxesComboboxItemLongDtoDmApplicationContractsVersion1160CultureNeutralPublicKeyTokenNull {
  items?: DmApplicationComboxesComboboxItemLongDto[] | null;
  /** @format int64 */
  totalCount?: number;
}

export interface VoloAbpApplicationDtosPagedResultDto1DmCrmBusinessPartnerTenantLinksBusinessPartnerTenantLinkDtoDmCrmApplicationContractsVersion1030CultureNeutralPublicKeyTokenNull {
  items?: DmCrmBusinessPartnerTenantLinksBusinessPartnerTenantLinkDto[] | null;
  /** @format int64 */
  totalCount?: number;
}

export interface VoloAbpApplicationDtosPagedResultDto1DmCrmBusinessPartnerUserLinksBusinessPartnerUserLinkDtoDmCrmApplicationContractsVersion1030CultureNeutralPublicKeyTokenNull {
  items?: DmCrmBusinessPartnerUserLinksBusinessPartnerUserLinkDto[] | null;
  /** @format int64 */
  totalCount?: number;
}

export interface VoloAbpApplicationDtosPagedResultDto1DmCrmBusinessPartnersBusinessPartnerDtoDmCrmApplicationContractsVersion1030CultureNeutralPublicKeyTokenNull {
  items?: DmCrmBusinessPartnersBusinessPartnerDto[] | null;
  /** @format int64 */
  totalCount?: number;
}

export interface VoloAbpApplicationDtosPagedResultDto1DmCrmContactsContactDtoDmCrmApplicationContractsVersion1030CultureNeutralPublicKeyTokenNull {
  items?: DmCrmContactsContactDto[] | null;
  /** @format int64 */
  totalCount?: number;
}

export interface VoloAbpApplicationDtosPagedResultDto1DmCrmNationsNationDtoDmCrmApplicationContractsVersion1030CultureNeutralPublicKeyTokenNull {
  items?: DmCrmNationsNationDto[] | null;
  /** @format int64 */
  totalCount?: number;
}

export interface VoloAbpApplicationDtosPagedResultDto1WebHookManagementWebhookEventsWebhookEventDtoWebHookManagementApplicationContractsVersion1010CultureNeutralPublicKeyTokenNull {
  items?: WebHookManagementWebhookEventsWebhookEventDto[] | null;
  /** @format int64 */
  totalCount?: number;
}

export interface VoloAbpApplicationDtosPagedResultDto1WebHookManagementWebhookSendAttemptsWebhookSendAttemptWithNavigationPropertiesDtoWebHookManagementApplicationContractsVersion1010CultureNeutralPublicKeyTokenNull {
  items?: WebHookManagementWebhookSendAttemptsWebhookSendAttemptWithNavigationPropertiesDto[] | null;
  /** @format int64 */
  totalCount?: number;
}

export interface VoloAbpApplicationDtosPagedResultDto1WebHookManagementWebhookSubscriptionsWebhookSubscriptionDtoWebHookManagementApplicationContractsVersion1010CultureNeutralPublicKeyTokenNull {
  items?: WebHookManagementWebhookSubscriptionsWebhookSubscriptionDto[] | null;
  /** @format int64 */
  totalCount?: number;
}

export interface VoloAbpApplicationDtosPagedResultDto1DmExcelImportManagementDataFilesDataFileDtoDmExcelImportManagementApplicationContractsVersion00440CultureNeutralPublicKeyTokenNull {
  items?: DmExcelImportManagementDataFilesDataFileDto[] | null;
  /** @format int64 */
  totalCount?: number;
}

export interface VoloAbpApplicationDtosPagedResultDto1DmExcelImportManagementImportInstancesImportInstanceDtoDmExcelImportManagementApplicationContractsVersion00440CultureNeutralPublicKeyTokenNull {
  items?: DmExcelImportManagementImportInstancesImportInstanceDto[] | null;
  /** @format int64 */
  totalCount?: number;
}

export interface VoloAbpApplicationDtosPagedResultDto1DmExcelImportManagementImportTemplatesImportTemplateDtoDmExcelImportManagementApplicationContractsVersion00440CultureNeutralPublicKeyTokenNull {
  items?: DmExcelImportManagementImportTemplatesImportTemplateDto[] | null;
  /** @format int64 */
  totalCount?: number;
}

export interface VoloAbpApplicationDtosPagedResultDto1WoraCorbosServiceCancellationReasonsCancellationReasonDtoWoraCorbosServiceApplicationContractsVersion1000CultureNeutralPublicKeyTokenNull {
  items?: WoraCorbosServiceCancellationReasonsCancellationReasonDto[] | null;
  /** @format int64 */
  totalCount?: number;
}

export interface VoloAbpApplicationDtosPagedResultDto1WoraCorbosServiceDeliveriesDeliveryDtoWoraCorbosServiceApplicationContractsVersion1000CultureNeutralPublicKeyTokenNull {
  items?: WoraCorbosServiceDeliveriesDeliveryDto[] | null;
  /** @format int64 */
  totalCount?: number;
}

export interface VoloAbpApplicationDtosPagedResultDto1WoraCorbosServiceDeliveryHistoriesDeliveryHistoryDtoWoraCorbosServiceApplicationContractsVersion1000CultureNeutralPublicKeyTokenNull {
  items?: WoraCorbosServiceDeliveryHistoriesDeliveryHistoryDto[] | null;
  /** @format int64 */
  totalCount?: number;
}

export interface VoloAbpApplicationDtosPagedResultDto1WoraCorbosServiceDeliveryStepHistoriesDeliveryStepHistoryDtoWoraCorbosServiceApplicationContractsVersion1000CultureNeutralPublicKeyTokenNull {
  items?: WoraCorbosServiceDeliveryStepHistoriesDeliveryStepHistoryDto[] | null;
  /** @format int64 */
  totalCount?: number;
}

export interface VoloAbpApplicationDtosPagedResultDto1WoraCorbosServiceDeliveryStepsDeliveryStepDtoWoraCorbosServiceApplicationContractsVersion1000CultureNeutralPublicKeyTokenNull {
  items?: WoraCorbosServiceDeliveryStepsDeliveryStepDto[] | null;
  /** @format int64 */
  totalCount?: number;
}

export interface VoloAbpApplicationDtosPagedResultDto1WoraCorbosServiceDriverPositionsDriverPositionDtoWoraCorbosServiceApplicationContractsVersion1000CultureNeutralPublicKeyTokenNull {
  items?: WoraCorbosServiceDriverPositionsDriverPositionDto[] | null;
  /** @format int64 */
  totalCount?: number;
}

export interface VoloAbpApplicationDtosPagedResultDto1WoraCorbosServiceDriversDriverDtoWoraCorbosServiceApplicationContractsVersion1000CultureNeutralPublicKeyTokenNull {
  items?: WoraCorbosServiceDriversDriverDto[] | null;
  /** @format int64 */
  totalCount?: number;
}

export interface VoloAbpApplicationDtosPagedResultDto1WoraCorbosServiceEventMessageTemplatesEventMessageTemplateDtoWoraCorbosServiceApplicationContractsVersion1000CultureNeutralPublicKeyTokenNull {
  items?: WoraCorbosServiceEventMessageTemplatesEventMessageTemplateDto[] | null;
  /** @format int64 */
  totalCount?: number;
}

export interface VoloAbpApplicationDtosPagedResultDto1WoraCorbosServiceFileStoragesFileStorageDtoWoraCorbosServiceApplicationContractsVersion1000CultureNeutralPublicKeyTokenNull {
  items?: WoraCorbosServiceFileStoragesFileStorageDto[] | null;
  /** @format int64 */
  totalCount?: number;
}

export interface VoloAbpApplicationDtosPagedResultDto1WoraCorbosServiceHistoryEventsHistoryEventDtoWoraCorbosServiceApplicationContractsVersion1000CultureNeutralPublicKeyTokenNull {
  items?: WoraCorbosServiceHistoryEventsHistoryEventDto[] | null;
  /** @format int64 */
  totalCount?: number;
}

export interface VoloAbpApplicationDtosPagedResultDto1WoraCorbosServiceNotificationsNotificationDtoWoraCorbosServiceApplicationContractsVersion1000CultureNeutralPublicKeyTokenNull {
  items?: WoraCorbosServiceNotificationsNotificationDto[] | null;
  /** @format int64 */
  totalCount?: number;
}

export interface VoloAbpApplicationDtosPagedResultDto1WoraCorbosServiceReviewsReviewDtoWoraCorbosServiceApplicationContractsVersion1000CultureNeutralPublicKeyTokenNull {
  items?: WoraCorbosServiceReviewsReviewDto[] | null;
  /** @format int64 */
  totalCount?: number;
}

export interface VoloAbpApplicationDtosPagedResultDto1WoraCorbosServiceShiftsShiftDtoWoraCorbosServiceApplicationContractsVersion1000CultureNeutralPublicKeyTokenNull {
  items?: WoraCorbosServiceShiftsShiftDto[] | null;
  /** @format int64 */
  totalCount?: number;
}

export interface VoloAbpApplicationDtosPagedResultDto1WoraCorbosServiceStoresStoreDtoWoraCorbosServiceApplicationContractsVersion1000CultureNeutralPublicKeyTokenNull {
  items?: WoraCorbosServiceStoresStoreDto[] | null;
  /** @format int64 */
  totalCount?: number;
}

export interface VoloAbpApplicationDtosPagedResultDto1WoraCorbosServiceUserStoresUserStoreDtoWoraCorbosServiceApplicationContractsVersion1000CultureNeutralPublicKeyTokenNull {
  items?: WoraCorbosServiceUserStoresUserStoreDto[] | null;
  /** @format int64 */
  totalCount?: number;
}

export interface VoloAbpAspNetCoreMvcApplicationConfigurationsApplicationAuthConfigurationDto {
  grantedPolicies?: Record<string, boolean | null>;
}

export interface VoloAbpAspNetCoreMvcApplicationConfigurationsApplicationConfigurationDto {
  localization?: VoloAbpAspNetCoreMvcApplicationConfigurationsApplicationLocalizationConfigurationDto;
  auth?: VoloAbpAspNetCoreMvcApplicationConfigurationsApplicationAuthConfigurationDto;
  setting?: VoloAbpAspNetCoreMvcApplicationConfigurationsApplicationSettingConfigurationDto;
  currentUser?: VoloAbpAspNetCoreMvcApplicationConfigurationsCurrentUserDto;
  features?: VoloAbpAspNetCoreMvcApplicationConfigurationsApplicationFeatureConfigurationDto;
  globalFeatures?: VoloAbpAspNetCoreMvcApplicationConfigurationsApplicationGlobalFeatureConfigurationDto;
  multiTenancy?: VoloAbpAspNetCoreMvcMultiTenancyMultiTenancyInfoDto;
  currentTenant?: VoloAbpAspNetCoreMvcMultiTenancyCurrentTenantDto;
  timing?: VoloAbpAspNetCoreMvcApplicationConfigurationsTimingDto;
  clock?: VoloAbpAspNetCoreMvcApplicationConfigurationsClockDto;
  objectExtensions?: VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingObjectExtensionsDto;
  extraProperties?: Record<string, any>;
}

export interface VoloAbpAspNetCoreMvcApplicationConfigurationsApplicationFeatureConfigurationDto {
  values?: Record<string, string | null>;
}

export interface VoloAbpAspNetCoreMvcApplicationConfigurationsApplicationGlobalFeatureConfigurationDto {
  /** @uniqueItems true */
  enabledFeatures?: string[] | null;
}

export interface VoloAbpAspNetCoreMvcApplicationConfigurationsApplicationLocalizationConfigurationDto {
  values?: Record<string, Record<string, string>>;
  resources?: Record<string, VoloAbpAspNetCoreMvcApplicationConfigurationsApplicationLocalizationResourceDto>;
  languages?: VoloAbpLocalizationLanguageInfo[] | null;
  currentCulture?: VoloAbpAspNetCoreMvcApplicationConfigurationsCurrentCultureDto;
  defaultResourceName?: string | null;
  languagesMap?: Record<string, VoloAbpNameValue[] | null>;
  languageFilesMap?: Record<string, VoloAbpNameValue[] | null>;
}

export interface VoloAbpAspNetCoreMvcApplicationConfigurationsApplicationLocalizationDto {
  resources?: Record<string, VoloAbpAspNetCoreMvcApplicationConfigurationsApplicationLocalizationResourceDto>;
}

export interface VoloAbpAspNetCoreMvcApplicationConfigurationsApplicationLocalizationResourceDto {
  texts?: Record<string, string | null>;
  baseResources?: string[] | null;
}

export interface VoloAbpAspNetCoreMvcApplicationConfigurationsApplicationSettingConfigurationDto {
  values?: Record<string, string | null>;
}

export interface VoloAbpAspNetCoreMvcApplicationConfigurationsClockDto {
  kind?: string | null;
}

export interface VoloAbpAspNetCoreMvcApplicationConfigurationsCurrentCultureDto {
  displayName?: string | null;
  englishName?: string | null;
  threeLetterIsoLanguageName?: string | null;
  twoLetterIsoLanguageName?: string | null;
  isRightToLeft?: boolean;
  cultureName?: string | null;
  name?: string | null;
  nativeName?: string | null;
  dateTimeFormat?: VoloAbpAspNetCoreMvcApplicationConfigurationsDateTimeFormatDto;
}

export interface VoloAbpAspNetCoreMvcApplicationConfigurationsCurrentUserDto {
  isAuthenticated?: boolean;
  /** @format uuid */
  id?: string | null;
  /** @format uuid */
  tenantId?: string | null;
  /** @format uuid */
  impersonatorUserId?: string | null;
  /** @format uuid */
  impersonatorTenantId?: string | null;
  impersonatorUserName?: string | null;
  impersonatorTenantName?: string | null;
  userName?: string | null;
  name?: string | null;
  surName?: string | null;
  email?: string | null;
  emailVerified?: boolean;
  phoneNumber?: string | null;
  phoneNumberVerified?: boolean;
  roles?: string[] | null;
}

export interface VoloAbpAspNetCoreMvcApplicationConfigurationsDateTimeFormatDto {
  calendarAlgorithmType?: string | null;
  dateTimeFormatLong?: string | null;
  shortDatePattern?: string | null;
  fullDateTimePattern?: string | null;
  dateSeparator?: string | null;
  shortTimePattern?: string | null;
  longTimePattern?: string | null;
}

export interface VoloAbpAspNetCoreMvcApplicationConfigurationsIanaTimeZone {
  timeZoneName?: string | null;
}

export interface VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingEntityExtensionDto {
  properties?: Record<string, VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingExtensionPropertyDto>;
  configuration?: Record<string, any>;
}

export interface VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingExtensionEnumDto {
  fields?: VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingExtensionEnumFieldDto[] | null;
  localizationResource?: string | null;
}

export interface VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingExtensionEnumFieldDto {
  name?: string | null;
  value?: any;
}

export interface VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingExtensionPropertyApiCreateDto {
  isAvailable?: boolean;
}

export interface VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingExtensionPropertyApiDto {
  onGet?: VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingExtensionPropertyApiGetDto;
  onCreate?: VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingExtensionPropertyApiCreateDto;
  onUpdate?: VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingExtensionPropertyApiUpdateDto;
}

export interface VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingExtensionPropertyApiGetDto {
  isAvailable?: boolean;
}

export interface VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingExtensionPropertyApiUpdateDto {
  isAvailable?: boolean;
}

export interface VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingExtensionPropertyAttributeDto {
  typeSimple?: string | null;
  config?: Record<string, any>;
}

export interface VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingExtensionPropertyDto {
  type?: string | null;
  typeSimple?: string | null;
  displayName?: VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingLocalizableStringDto;
  api?: VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingExtensionPropertyApiDto;
  ui?: VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingExtensionPropertyUiDto;
  attributes?: VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingExtensionPropertyAttributeDto[] | null;
  configuration?: Record<string, any>;
  defaultValue?: any;
}

export interface VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingExtensionPropertyUiDto {
  onTable?: VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingExtensionPropertyUiTableDto;
  onCreateForm?: VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingExtensionPropertyUiFormDto;
  onEditForm?: VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingExtensionPropertyUiFormDto;
  lookup?: VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingExtensionPropertyUiLookupDto;
}

export interface VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingExtensionPropertyUiFormDto {
  isVisible?: boolean;
}

export interface VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingExtensionPropertyUiLookupDto {
  url?: string | null;
  resultListPropertyName?: string | null;
  displayPropertyName?: string | null;
  valuePropertyName?: string | null;
  filterParamName?: string | null;
}

export interface VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingExtensionPropertyUiTableDto {
  isVisible?: boolean;
}

export interface VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingLocalizableStringDto {
  name?: string | null;
  resource?: string | null;
}

export interface VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingModuleExtensionDto {
  entities?: Record<string, VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingEntityExtensionDto>;
  configuration?: Record<string, any>;
}

export interface VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingObjectExtensionsDto {
  modules?: Record<string, VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingModuleExtensionDto>;
  enums?: Record<string, VoloAbpAspNetCoreMvcApplicationConfigurationsObjectExtendingExtensionEnumDto>;
}

export interface VoloAbpAspNetCoreMvcApplicationConfigurationsTimeZone {
  iana?: VoloAbpAspNetCoreMvcApplicationConfigurationsIanaTimeZone;
  windows?: VoloAbpAspNetCoreMvcApplicationConfigurationsWindowsTimeZone;
}

export interface VoloAbpAspNetCoreMvcApplicationConfigurationsTimingDto {
  timeZone?: VoloAbpAspNetCoreMvcApplicationConfigurationsTimeZone;
}

export interface VoloAbpAspNetCoreMvcApplicationConfigurationsWindowsTimeZone {
  timeZoneId?: string | null;
}

export interface VoloAbpAspNetCoreMvcMultiTenancyCurrentTenantDto {
  /** @format uuid */
  id?: string | null;
  name?: string | null;
  isAvailable?: boolean;
}

export interface VoloAbpAspNetCoreMvcMultiTenancyMultiTenancyInfoDto {
  isEnabled?: boolean;
}

export interface VoloAbpHttpModelingActionApiDescriptionModel {
  uniqueName?: string | null;
  name?: string | null;
  httpMethod?: string | null;
  url?: string | null;
  supportedVersions?: string[] | null;
  parametersOnMethod?: VoloAbpHttpModelingMethodParameterApiDescriptionModel[] | null;
  parameters?: VoloAbpHttpModelingParameterApiDescriptionModel[] | null;
  returnValue?: VoloAbpHttpModelingReturnValueApiDescriptionModel;
  allowAnonymous?: boolean | null;
  implementFrom?: string | null;
}

export interface VoloAbpHttpModelingApplicationApiDescriptionModel {
  modules?: Record<string, VoloAbpHttpModelingModuleApiDescriptionModel>;
  types?: Record<string, VoloAbpHttpModelingTypeApiDescriptionModel>;
}

export interface VoloAbpHttpModelingControllerApiDescriptionModel {
  controllerName?: string | null;
  controllerGroupName?: string | null;
  isRemoteService?: boolean;
  isIntegrationService?: boolean;
  apiVersion?: string | null;
  type?: string | null;
  interfaces?: VoloAbpHttpModelingControllerInterfaceApiDescriptionModel[] | null;
  actions?: Record<string, VoloAbpHttpModelingActionApiDescriptionModel>;
}

export interface VoloAbpHttpModelingControllerInterfaceApiDescriptionModel {
  type?: string | null;
  name?: string | null;
  methods?: VoloAbpHttpModelingInterfaceMethodApiDescriptionModel[] | null;
}

export interface VoloAbpHttpModelingInterfaceMethodApiDescriptionModel {
  name?: string | null;
  parametersOnMethod?: VoloAbpHttpModelingMethodParameterApiDescriptionModel[] | null;
  returnValue?: VoloAbpHttpModelingReturnValueApiDescriptionModel;
}

export interface VoloAbpHttpModelingMethodParameterApiDescriptionModel {
  name?: string | null;
  typeAsString?: string | null;
  type?: string | null;
  typeSimple?: string | null;
  isOptional?: boolean;
  defaultValue?: any;
}

export interface VoloAbpHttpModelingModuleApiDescriptionModel {
  rootPath?: string | null;
  remoteServiceName?: string | null;
  controllers?: Record<string, VoloAbpHttpModelingControllerApiDescriptionModel>;
}

export interface VoloAbpHttpModelingParameterApiDescriptionModel {
  nameOnMethod?: string | null;
  name?: string | null;
  jsonName?: string | null;
  type?: string | null;
  typeSimple?: string | null;
  isOptional?: boolean;
  defaultValue?: any;
  constraintTypes?: string[] | null;
  bindingSourceId?: string | null;
  descriptorName?: string | null;
}

export interface VoloAbpHttpModelingPropertyApiDescriptionModel {
  name?: string | null;
  jsonName?: string | null;
  type?: string | null;
  typeSimple?: string | null;
  isRequired?: boolean;
  /** @format int32 */
  minLength?: number | null;
  /** @format int32 */
  maxLength?: number | null;
  minimum?: string | null;
  maximum?: string | null;
  regex?: string | null;
}

export interface VoloAbpHttpModelingReturnValueApiDescriptionModel {
  type?: string | null;
  typeSimple?: string | null;
}

export interface VoloAbpHttpModelingTypeApiDescriptionModel {
  baseType?: string | null;
  isEnum?: boolean;
  enumNames?: string[] | null;
  enumValues?: any[] | null;
  genericArguments?: string[] | null;
  properties?: VoloAbpHttpModelingPropertyApiDescriptionModel[] | null;
}

export interface VoloAbpHttpRemoteServiceErrorInfo {
  code?: string | null;
  message?: string | null;
  details?: string | null;
  data?: Record<string, any>;
  validationErrors?: VoloAbpHttpRemoteServiceValidationErrorInfo[] | null;
}

export interface VoloAbpHttpRemoteServiceErrorResponse {
  error?: VoloAbpHttpRemoteServiceErrorInfo;
}

export interface VoloAbpHttpRemoteServiceValidationErrorInfo {
  message?: string | null;
  members?: string[] | null;
}

export interface VoloAbpLocalizationLanguageInfo {
  cultureName?: string | null;
  uiCultureName?: string | null;
  displayName?: string | null;
  twoLetterISOLanguageName?: string | null;
  flagIcon?: string | null;
}

export interface VoloAbpNameValue {
  name?: string | null;
  value?: string | null;
}

export interface VoloAbpSettingsSettingValue {
  name?: string | null;
  value?: string | null;
}

export interface WebHookManagementWebhookEventsWebhookEventDto {
  /** @format uuid */
  id?: string;
  webhookName?: string | null;
  data?: string | null;
  concurrencyStamp?: string | null;
}

export interface WebHookManagementWebhookSendAttemptsGetWebhookSendAttemptsInput {
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  maxResultCount?: number;
  /**
   * @format int32
   * @min 0
   * @max 2147483647
   */
  skipCount?: number;
  sorting?: string | null;
  filterText?: string | null;
  response?: string | null;
  responseStatusCodeMin?: SystemNetHttpStatusCode;
  /** @format uuid */
  webhookEventId?: string | null;
  /** @format uuid */
  webhookSubscriptionId?: string | null;
}

export interface WebHookManagementWebhookSendAttemptsWebhookSendAttemptDto {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  creationTime?: string;
  /** @format uuid */
  creatorId?: string | null;
  /** @format date-time */
  lastModificationTime?: string | null;
  /** @format uuid */
  lastModifierId?: string | null;
  response?: string | null;
  responseStatusCode?: SystemNetHttpStatusCode;
  /** @format uuid */
  webhookEventId?: string | null;
  /** @format uuid */
  webhookSubscriptionId?: string | null;
  concurrencyStamp?: string | null;
}

export interface WebHookManagementWebhookSendAttemptsWebhookSendAttemptWithNavigationPropertiesDto {
  webhookSendAttempt?: WebHookManagementWebhookSendAttemptsWebhookSendAttemptDto;
  webhookEvent?: WebHookManagementWebhookEventsWebhookEventDto;
  webhookSubscription?: WebHookManagementWebhookSubscriptionsWebhookSubscriptionDto;
}

export interface WebHookManagementWebhookSubscriptionsActiveSubscriptionDto {
  /** @format uuid */
  subscriptionId?: string;
  status?: boolean;
}

export interface WebHookManagementWebhookSubscriptionsGetAllAvailableWebhooksOutput {
  name?: string | null;
  displayName?: string | null;
  description?: string | null;
}

export interface WebHookManagementWebhookSubscriptionsWebhookSubscriptionCreateDto {
  /**
   * @minLength 0
   * @maxLength 1024
   */
  webhookUri?: string | null;
  /**
   * @minLength 0
   * @maxLength 512
   */
  secret?: string | null;
  isActive?: boolean;
  webhooks?: string[] | null;
  headers?: Record<string, string>;
}

export interface WebHookManagementWebhookSubscriptionsWebhookSubscriptionDto {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  creationTime?: string;
  /** @format uuid */
  creatorId?: string | null;
  /** @format date-time */
  lastModificationTime?: string | null;
  /** @format uuid */
  lastModifierId?: string | null;
  webhookUri?: string | null;
  secret?: string | null;
  isActive?: boolean;
  webhooks?: string[] | null;
  headers?: Record<string, string>;
  concurrencyStamp?: string | null;
}

export interface WebHookManagementWebhookSubscriptionsWebhookSubscriptionUpdateDto {
  /**
   * @minLength 0
   * @maxLength 1024
   */
  webhookUri?: string | null;
  /**
   * @minLength 0
   * @maxLength 512
   */
  secret?: string | null;
  isActive?: boolean;
  webhooks?: string[] | null;
  headers?: Record<string, string>;
  concurrencyStamp?: string | null;
}

export interface DmExcelImportManagementDataFilesDataFileCreateDto {
  dataFileType: string;
  /**
   * @minLength 0
   * @maxLength 256
   */
  fileName?: string | null;
  /**
   * @minLength 0
   * @maxLength 128
   */
  fileType?: string | null;
  /** @format int32 */
  dimensionInByte?: number;
}

export interface DmExcelImportManagementDataFilesDataFileDto {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  creationTime?: string;
  /** @format uuid */
  creatorId?: string | null;
  /** @format date-time */
  lastModificationTime?: string | null;
  /** @format uuid */
  lastModifierId?: string | null;
  isDeleted?: boolean;
  /** @format uuid */
  deleterId?: string | null;
  /** @format date-time */
  deletionTime?: string | null;
  dataFileType?: string;
  fileName?: string | null;
  fileType?: string | null;
  /** @format int32 */
  dimensionInByte?: number;
  concurrencyStamp?: string | null;
}

export interface DmExcelImportManagementDataFilesDataFileUpdateDto {
  dataFileType: string;
  /**
   * @minLength 0
   * @maxLength 256
   */
  fileName?: string | null;
  /**
   * @minLength 0
   * @maxLength 128
   */
  fileType?: string | null;
  /** @format int32 */
  dimensionInByte?: number;
  concurrencyStamp?: string | null;
}

export interface DmExcelImportManagementImportFilesFileInfoDto {
  /** @format uuid */
  fileId?: string | null;
  name?: string | null;
  fileType?: string | null;
}

export interface DmExcelImportManagementImportFilesOutputFileDto {
  fileName?: string | null;
  /** @format uuid */
  id?: string;
}

export interface DmExcelImportManagementImportInstancesImportInstanceCreateDto {
  /**
   * @minLength 0
   * @maxLength 256
   */
  templateKey: string;
  /**
   * @minLength 0
   * @maxLength 256
   */
  importProvider?: string | null;
  /** @format uuid */
  fileId?: string;
  importStatus?: DmExcelImportManagementImportStatusEnum;
  /** @format date-time */
  startTime?: string | null;
  /** @format date-time */
  endTime?: string | null;
  /** @format int32 */
  duration?: number;
  /**
   * @minLength 0
   * @maxLength 512
   */
  message?: string | null;
  /**
   * @minLength 0
   * @maxLength 1024
   */
  exceptionMessage?: string | null;
}

export interface DmExcelImportManagementImportInstancesImportInstanceDto {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  creationTime?: string;
  /** @format uuid */
  creatorId?: string | null;
  /** @format date-time */
  lastModificationTime?: string | null;
  /** @format uuid */
  lastModifierId?: string | null;
  isDeleted?: boolean;
  /** @format uuid */
  deleterId?: string | null;
  /** @format date-time */
  deletionTime?: string | null;
  templateKey?: string | null;
  importProvider?: string | null;
  /** @format uuid */
  fileId?: string;
  importStatus?: DmExcelImportManagementImportStatusEnum;
  /** @format date-time */
  startTime?: string | null;
  /** @format date-time */
  endTime?: string | null;
  /** @format int32 */
  duration?: number;
  message?: string | null;
  exceptionMessage?: string | null;
  concurrencyStamp?: string | null;
}

export interface DmExcelImportManagementImportInstancesImportInstanceUpdateDto {
  /**
   * @minLength 0
   * @maxLength 256
   */
  templateKey: string;
  /**
   * @minLength 0
   * @maxLength 256
   */
  importProvider?: string | null;
  /** @format uuid */
  fileId?: string;
  importStatus?: DmExcelImportManagementImportStatusEnum;
  /** @format date-time */
  startTime?: string | null;
  /** @format date-time */
  endTime?: string | null;
  /** @format int32 */
  duration?: number;
  /**
   * @minLength 0
   * @maxLength 512
   */
  message?: string | null;
  /**
   * @minLength 0
   * @maxLength 1024
   */
  exceptionMessage?: string | null;
  concurrencyStamp?: string | null;
}

/** @format int32 */
export enum DmExcelImportManagementImportStatusEnum {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
}

export interface DmExcelImportManagementImportTemplatesImportTemplateCreateDto {
  /**
   * @minLength 0
   * @maxLength 256
   */
  templateKey?: string | null;
  /** @format uuid */
  fileId?: string;
}

export interface DmExcelImportManagementImportTemplatesImportTemplateDto {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  creationTime?: string;
  /** @format uuid */
  creatorId?: string | null;
  /** @format date-time */
  lastModificationTime?: string | null;
  /** @format uuid */
  lastModifierId?: string | null;
  isDeleted?: boolean;
  /** @format uuid */
  deleterId?: string | null;
  /** @format date-time */
  deletionTime?: string | null;
  templateKey?: string | null;
  /** @format uuid */
  fileId?: string | null;
  concurrencyStamp?: string | null;
  name?: string | null;
  description?: string | null;
  displayName?: string | null;
  hasTemplate?: boolean;
}

export interface DmExcelImportManagementImportTemplatesImportTemplateUpdateDto {
  /**
   * @minLength 0
   * @maxLength 256
   */
  templateKey?: string | null;
  /** @format uuid */
  fileId?: string;
  concurrencyStamp?: string | null;
}

export interface DmExcelImportManagementSharedDownloadTokenResultDto {
  token?: string | null;
}

export interface WoraCorbosServiceCancellationReasonsCancellationReasonCreateDto {
  isPickUp?: boolean;
  isBeforStart?: string | null;
  /**
   * @minLength 0
   * @maxLength 256
   */
  message: string;
}

export interface WoraCorbosServiceCancellationReasonsCancellationReasonDto {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  creationTime?: string;
  /** @format uuid */
  creatorId?: string | null;
  /** @format date-time */
  lastModificationTime?: string | null;
  /** @format uuid */
  lastModifierId?: string | null;
  isDeleted?: boolean;
  /** @format uuid */
  deleterId?: string | null;
  /** @format date-time */
  deletionTime?: string | null;
  isPickUp?: boolean;
  isBeforStart?: string | null;
  message?: string | null;
  concurrencyStamp?: string | null;
}

export interface WoraCorbosServiceCancellationReasonsCancellationReasonUpdateDto {
  isPickUp?: boolean;
  isBeforStart?: string | null;
  /**
   * @minLength 0
   * @maxLength 256
   */
  message: string;
  concurrencyStamp?: string | null;
}

export interface WoraCorbosServiceDeliveriesDeliveryCreateDto {
  extraField?: DmDynamicDataEntitiesExtraFieldJsonObjectDto;
  /** @format int64 */
  businessPartnerId?: number;
  /** @format uuid */
  storeId?: string;
  /** @format int32 */
  totSmallPackages?: number;
  /** @format int32 */
  totMediumPackages?: number;
  /** @format int32 */
  totLargePackages?: number;
  externalOrderCode?: string | null;
  description?: string | null;
  note?: string | null;
  tokanId?: string | null;
  teamId?: string | null;
  status?: WoraCorbosServiceDeliveriesDeliveryStatus;
  deliveryType?: WoraCorbosServiceDeliveriesDeliveryType;
  orderType?: WoraCorbosServiceDeliveriesOrderType;
  /** @format date-time */
  orderDate?: string | null;
  externalDeliveryId?: string | null;
  deliveryStep?: WoraCorbosServiceDeliveryStepsDeliveryStepCreateDto;
  pickupStep?: WoraCorbosServiceDeliveryStepsDeliveryStepCreateDto;
  steps?: WoraCorbosServiceDeliveryStepsDeliveryStepCreateDto[] | null;
}

export interface WoraCorbosServiceDeliveriesDeliveryCreateShopPortal {
  /** @format uuid */
  id?: string | null;
  /** @format uuid */
  storeId?: string;
  pickupRepresentative?: string | null;
  pickupRepresentativePhone?: string | null;
  pickupRepresentativeEmail?: string | null;
  /** @format date-time */
  pickupStartDate?: string | null;
  pickupNote?: string | null;
  deliveryType?: WoraCorbosServiceDeliveriesDeliveryType;
  internalPhone?: string | null;
  reception?: boolean;
  /** @format int32 */
  totSmallPackages?: number;
  /** @format int32 */
  totMediumPackages?: number;
  /** @format int32 */
  totLargePackages?: number;
  deliveryNote?: string | null;
  deliveryExternalCode?: string | null;
  contact?: string | null;
  contactPhone?: string | null;
  contactEmail?: string | null;
  deliveryAddress?: string | null;
  deliveryAddressNumber?: string | null;
  deliveryCity?: string | null;
  deliveryZipCode?: string | null;
  deliveryProvince?: string | null;
  deliveryNation?: string | null;
  /** @format date-time */
  deliveryStartDate?: string | null;
  /** @format date-time */
  deliveryEndDate?: string | null;
  /** @format double */
  latitude?: number;
  /** @format double */
  longitude?: number;
  orderType?: WoraCorbosServiceDeliveriesOrderType;
}

export interface WoraCorbosServiceDeliveriesDeliveryDto {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  creationTime?: string;
  /** @format uuid */
  creatorId?: string | null;
  /** @format date-time */
  lastModificationTime?: string | null;
  /** @format uuid */
  lastModifierId?: string | null;
  isDeleted?: boolean;
  /** @format uuid */
  deleterId?: string | null;
  /** @format date-time */
  deletionTime?: string | null;
  applicationName?: string | null;
  /** @format int64 */
  contactId?: number;
  /** @format date-time */
  startTime?: string | null;
  /** @format date-time */
  endTime?: string | null;
  /** @format int32 */
  totSmallPackages?: number;
  /** @format int32 */
  totMediumPackages?: number;
  /** @format int32 */
  totLargePackages?: number;
  /** @deprecated */
  description?: string | null;
  externalOrderCode?: string | null;
  note?: string | null;
  /** @format uuid */
  storeId?: string | null;
  teamId?: string | null;
  tokanId?: string | null;
  deliveryType?: WoraCorbosServiceDeliveriesDeliveryType;
  status?: WoraCorbosServiceDeliveriesDeliveryStatus;
  /**
   * @deprecated
   * @format int64
   */
  contactOutId?: number;
  /**
   * @deprecated
   * @format uuid
   */
  storeOutId?: string | null;
  /** @format date-time */
  expireDate?: string | null;
  randomCode?: string | null;
  orderType?: WoraCorbosServiceDeliveriesOrderType;
  concurrencyStamp?: string | null;
  /** @format date-time */
  orderDate?: string | null;
  orderCode?: string | null;
  /** @format int64 */
  businessPartnerId?: number | null;
  businessPartnerDescription?: string | null;
  statusName?: string | null;
  deliveryTypeName?: string | null;
  orderTypeName?: string | null;
  city?: string | null;
  feedbackSent?: boolean;
  /** @format int32 */
  totalPackages?: number;
  /** @format uuid */
  pickupId?: string | null;
  externalPickUpId?: string | null;
  /** @format double */
  pickupLat?: number | null;
  /** @format double */
  pickupLng?: number | null;
  from?: string | null;
  /** @format date-time */
  requestedFrom?: string | null;
  /** @format date-time */
  requestedEndFrom?: string | null;
  /** @format date-time */
  realFrom?: string | null;
  /** @format date-time */
  taskEtaFrom?: string | null;
  storeName?: string | null;
  pickupContactName?: string | null;
  pickupContactSurname?: string | null;
  pickupContactPhone?: string | null;
  pickupContactMobile?: string | null;
  pickupContactEmail?: string | null;
  storeZipCode?: string | null;
  storeCity?: string | null;
  storeAddress?: string | null;
  storeAddressNumber?: string | null;
  storeProvince?: string | null;
  pickupContactProvince?: string | null;
  pickupContactZipCode?: string | null;
  pickupContactAddress?: string | null;
  pickupContactCity?: string | null;
  pickupContactAddressNumber?: string | null;
  /** @format date-time */
  acceptedDateFrom?: string | null;
  /** @format date-time */
  assignedDateFrom?: string | null;
  /** @format date-time */
  startDateFrom?: string | null;
  pickupContact?: string | null;
  pickupDriver?: string | null;
  pickupDriverEmail?: string | null;
  pickupDriverPhone?: string | null;
  /** @format double */
  pickupDriverLat?: number | null;
  /** @format double */
  pickupDriverLng?: number | null;
  pickupInterPhone?: string | null;
  pickupReception?: boolean;
  pickupStatus?: WoraCorbosServiceDeliveryStepsStepStatus;
  pickupStatusName?: string | null;
  pickupCode?: string | null;
  pickupNote?: string | null;
  pickupRepresentative?: string | null;
  pickupRepresentativeEmail?: string | null;
  pickupRepresentativePhone?: string | null;
  /** @format uuid */
  deliveryId?: string | null;
  externalDeliveryId?: string | null;
  to?: string | null;
  /** @format date-time */
  requestedTo?: string | null;
  /** @format date-time */
  requestedEndTo?: string | null;
  /** @format date-time */
  realTo?: string | null;
  /** @format date-time */
  taskEtaTo?: string | null;
  deliveryContactName?: string | null;
  deliveryContactSurname?: string | null;
  deliveryContactMobile?: string | null;
  /** @format double */
  deliveryContactLatitude?: number | null;
  /** @format double */
  deliveryContactLongitude?: number | null;
  deliveryContactCity?: string | null;
  deliveryContactAddress?: string | null;
  deliveryContactAddressNumber?: string | null;
  deliveryContactZipCode?: string | null;
  deliveryContactProvince?: string | null;
  deliveryContactPhone?: string | null;
  deliveryContactEmail?: string | null;
  deliveryInfo?: string | null;
  /** @format date-time */
  acceptedDateTo?: string | null;
  /** @format date-time */
  assignedDateTo?: string | null;
  /** @format date-time */
  startDateTo?: string | null;
  deliveryContact?: string | null;
  deliveryInterPhone?: string | null;
  deliveryReception?: boolean;
  deliveryDriver?: string | null;
  deliveryDriverEmail?: string | null;
  deliveryDriverPhone?: string | null;
  /** @format double */
  deliveryDriverLat?: number | null;
  /** @format double */
  deliveryDriverLng?: number | null;
  deliveryStatus?: WoraCorbosServiceDeliveryStepsStepStatus;
  deliveryStatusName?: string | null;
  deliveryCode?: string | null;
  deliveryNote?: string | null;
  deliveryExternalCode?: string | null;
  steps?: WoraCorbosServiceDeliveryStepsDeliveryStepDto[] | null;
  /** @format int32 */
  stepCount?: number;
}

/** @format int32 */
export enum WoraCorbosServiceDeliveriesDeliveryStatus {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
  Value5 = 5,
  Value6 = 6,
  Value7 = 7,
  Value8 = 8,
}

/** @format int32 */
export enum WoraCorbosServiceDeliveriesDeliveryType {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
}

export interface WoraCorbosServiceDeliveriesDeliveryUpdateDto {
  extraField?: DmDynamicDataEntitiesExtraFieldJsonObjectDto;
  /** @format uuid */
  storeId?: string;
  /** @format int64 */
  contactId?: number | null;
  /** @format date-time */
  startTime?: string | null;
  /** @format date-time */
  endTime?: string | null;
  /** @format int32 */
  totSmallPackages?: number;
  /** @format int32 */
  totMediumPackages?: number;
  /** @format int32 */
  totLargePackages?: number;
  externalOrderCode?: string | null;
  description?: string | null;
  note?: string | null;
  tokanId?: string | null;
  teamId?: string | null;
  status?: WoraCorbosServiceDeliveriesDeliveryStatus;
  deliveryType?: WoraCorbosServiceDeliveriesDeliveryType;
  orderType?: WoraCorbosServiceDeliveriesOrderType;
  /** @format date-time */
  pickUpFrom?: string | null;
  /** @format date-time */
  deliveryTo?: string | null;
  /** @format uuid */
  pickupId?: string | null;
  /** @format uuid */
  deliveryId?: string | null;
  /** @format date-time */
  orderDate?: string | null;
  orderCode?: string | null;
  contact?: DmCrmContactsContactDto;
  externalPickUpId?: string | null;
  externalDeliveryId?: string | null;
  randomCode?: string | null;
  /** @format date-time */
  expireDate?: string | null;
  /** @format date-time */
  requestedFrom?: string | null;
  /** @format date-time */
  requestedEndFrom?: string | null;
  /** @format date-time */
  requestedTo?: string | null;
  /** @format date-time */
  requestedEndTo?: string | null;
  pickupNote?: string | null;
  deliveryNote?: string | null;
  concurrencyStamp?: string | null;
  steps?: WoraCorbosServiceDeliveryStepsDeliveryStepDto[] | null;
}

export interface WoraCorbosServiceDeliveriesGetDeliveriesInput {
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  maxResultCount?: number;
  /**
   * @format int32
   * @min 0
   * @max 2147483647
   */
  skipCount?: number;
  sorting?: string | null;
  filters?: DmFilterApplicationContractsFiltersTelerikFilterDto;
  maps?: DmFilterApplicationContractsFiltersFilterMapDto[] | null;
  filterText?: string | null;
  /** @format int64 */
  contactIdMin?: number | null;
  /** @format int64 */
  contactIdMax?: number | null;
  /** @format date-time */
  startTimeMin?: string | null;
  /** @format date-time */
  startTimeMax?: string | null;
  /** @format date-time */
  endTimeMin?: string | null;
  /** @format date-time */
  endTimeMax?: string | null;
  /** @format int32 */
  totSmallPackagesMin?: number | null;
  /** @format int32 */
  totSmallPackagesMax?: number | null;
  /** @format int32 */
  totMediumPackagesMin?: number | null;
  /** @format int32 */
  totMediumPackagesMax?: number | null;
  /** @format int32 */
  totLargePackagesMin?: number | null;
  /** @format int32 */
  totLargePackagesMax?: number | null;
  externalOrderCode?: string | null;
  description?: string | null;
  note?: string | null;
  /** @format uuid */
  storeId?: string;
  tokanId?: string | null;
  teamId?: string | null;
  status?: WoraCorbosServiceDeliveriesDeliveryStatus;
  deliveryType?: WoraCorbosServiceDeliveriesDeliveryType;
  orderType?: WoraCorbosServiceDeliveriesOrderType;
  /** @format uuid */
  pickupId?: string | null;
  /** @format uuid */
  deliveryId?: string | null;
  randomCode?: string | null;
  /** @format date-time */
  expireDate?: string | null;
  /** @format date-time */
  orderDate?: string | null;
  orderCode?: string | null;
  deliveryExternalCode?: string | null;
  customer?: string | null;
  deliveryTypeName?: string | null;
}

export interface WoraCorbosServiceDeliveriesGetDeliveryStepsByDeliveryInput {
  deliveryIds?: string[] | null;
}

/** @format int32 */
export enum WoraCorbosServiceDeliveriesOrderType {
  Value0 = 0,
  Value1 = 1,
}

export interface WoraCorbosServiceDeliveriesRegeolocalizeInput {
  orderIds?: string[] | null;
}

export interface WoraCorbosServiceDeliveriesSendDeliveryInput {
  ids?: string[] | null;
  /** @format int32 */
  maxRunningTime?: number | null;
  /** @format date-time */
  routingDate?: string | null;
  /** @format int32 */
  maxDriverNumber?: number | null;
  /** @format uuid */
  executionId?: string | null;
  status?: WoraCorbosServiceDeliveriesDeliveryStatus;
  deliveryType?: WoraCorbosServiceDeliveriesDeliveryType;
  cleanAndUnassign?: boolean;
  ignoreDriverPosition?: boolean;
  /** @format int32 */
  globalSpanCostCoefficient?: number | null;
  /** @format int32 */
  earlyTaskPenalty?: number | null;
  /** @format int32 */
  lateTaskPenalty?: number | null;
  /** @format int32 */
  lateDriverTurnPenalty?: number | null;
  /** @format int32 */
  dropPenalty?: number | null;
  /** @format int32 */
  dropPenaltyPickupDeliveryPair?: number | null;
}

export interface WoraCorbosServiceDeliveryHistoriesDeliveryHistoryCreateDto {
  extraField?: DmDynamicDataEntitiesExtraFieldJsonObjectDto;
  /** @format uuid */
  deliveryId?: string;
  /** @format uuid */
  stepId?: string | null;
  /**
   * @minLength 0
   * @maxLength 128
   */
  deliveryCode?: string | null;
  /**
   * @minLength 0
   * @maxLength 256
   */
  stepCode?: string | null;
  stepStatus?: WoraCorbosServiceDeliveryStepsStepStatus;
  /**
   * @minLength 0
   * @maxLength 16
   */
  stepType?: string | null;
  /**
   * @minLength 0
   * @maxLength 64
   */
  eventType?: string | null;
  /**
   * @minLength 0
   * @maxLength 256
   */
  note: string;
  /** @format double */
  lat?: number | null;
  /** @format double */
  lng?: number | null;
  userName?: string | null;
  /** @format uuid */
  historyEventId?: string;
  orderStatus?: WoraCorbosServiceDeliveriesDeliveryStatus;
}

export interface WoraCorbosServiceDeliveryHistoriesDeliveryHistoryDto {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  creationTime?: string;
  /** @format uuid */
  creatorId?: string | null;
  /** @format date-time */
  lastModificationTime?: string | null;
  /** @format uuid */
  lastModifierId?: string | null;
  /** @format uuid */
  deliveryId?: string;
  /** @format uuid */
  stepId?: string | null;
  deliveryCode?: string | null;
  stepCode?: string | null;
  stepStatus?: WoraCorbosServiceDeliveryStepsStepStatus;
  stepType?: string | null;
  eventType?: string | null;
  note?: string | null;
  /** @format double */
  lat?: number | null;
  /** @format double */
  lng?: number | null;
  type?: WoraCorbosServiceDeliveryHistoriesHistoryType;
  userName?: string | null;
  orderStatus?: WoraCorbosServiceDeliveriesDeliveryStatus;
  concurrencyStamp?: string | null;
  applicationName?: string | null;
}

export interface WoraCorbosServiceDeliveryHistoriesDeliveryHistoryUpdateDto {
  extraField?: DmDynamicDataEntitiesExtraFieldJsonObjectDto;
  /** @format uuid */
  deliveryId?: string;
  /** @format uuid */
  stepId?: string | null;
  /**
   * @minLength 0
   * @maxLength 128
   */
  deliveryCode?: string | null;
  /**
   * @minLength 0
   * @maxLength 256
   */
  stepCode?: string | null;
  stepStatus?: WoraCorbosServiceDeliveryStepsStepStatus;
  /**
   * @minLength 0
   * @maxLength 16
   */
  stepType?: string | null;
  /**
   * @minLength 0
   * @maxLength 64
   */
  eventType?: string | null;
  /**
   * @minLength 0
   * @maxLength 256
   */
  note: string;
  /** @format double */
  lat?: number | null;
  /** @format double */
  lng?: number | null;
  userName?: string | null;
  /** @format uuid */
  historyEventId?: string;
  orderStatus?: WoraCorbosServiceDeliveriesDeliveryStatus;
  concurrencyStamp?: string | null;
}

export interface WoraCorbosServiceDeliveryHistoriesGetDeliveryHistoriesInput {
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  maxResultCount?: number;
  /**
   * @format int32
   * @min 0
   * @max 2147483647
   */
  skipCount?: number;
  sorting?: string | null;
  filters?: DmFilterApplicationContractsFiltersTelerikFilterDto;
  maps?: DmFilterApplicationContractsFiltersFilterMapDto[] | null;
  filterText?: string | null;
  /** @format uuid */
  deliveryId?: string | null;
  /** @format uuid */
  stepId?: string | null;
  deliveryCode?: string | null;
  stepCode?: string | null;
  stepStatus?: WoraCorbosServiceDeliveryStepsStepStatus;
  stepType?: string | null;
  eventType?: string | null;
  note?: string | null;
  /** @format double */
  lat?: number | null;
  /** @format double */
  lng?: number | null;
  userName?: string | null;
  /** @format uuid */
  historyEventId?: string;
  orderStatus?: WoraCorbosServiceDeliveriesDeliveryStatus;
}

/** @format int32 */
export enum WoraCorbosServiceDeliveryHistoriesHistoryType {
  Value0 = 0,
  Value1 = 1,
}

export interface WoraCorbosServiceDeliveryStepHistoriesDeliveryStepHistoryCreateDto {
  /** @format uuid */
  executionId?: string;
  /** @format uuid */
  scenarioId?: string | null;
  /**
   * @minLength 0
   * @maxLength 64
   */
  clusterKey?: string | null;
  /** @format uuid */
  driverId?: string | null;
  /** @format uuid */
  stepId?: string;
  /** @format date-time */
  eta?: string | null;
  /** @format date-time */
  startEtaWindow?: string | null;
  /** @format date-time */
  endEtaWindow?: string | null;
  stepUsageStatus?: WoraCorbosServiceDeliveryStepHistoriesStepUsageStatus;
  notes?: string | null;
}

export interface WoraCorbosServiceDeliveryStepHistoriesDeliveryStepHistoryDto {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  creationTime?: string;
  /** @format uuid */
  creatorId?: string | null;
  /** @format date-time */
  lastModificationTime?: string | null;
  /** @format uuid */
  lastModifierId?: string | null;
  /** @format uuid */
  executionId?: string;
  /** @format uuid */
  scenarioId?: string;
  clusterKey?: string | null;
  /** @format uuid */
  driverId?: string;
  /** @format uuid */
  stepId?: string;
  /** @format date-time */
  eta?: string;
  /** @format date-time */
  startEtaWindow?: string;
  /** @format date-time */
  endEtaWindow?: string;
}

export interface WoraCorbosServiceDeliveryStepHistoriesDeliveryStepHistoryUpdateDto {
  /** @format uuid */
  executionId?: string;
  /** @format uuid */
  scenarioId?: string;
  /**
   * @minLength 0
   * @maxLength 64
   */
  clusterKey?: string | null;
  /** @format uuid */
  driverId?: string;
  /** @format uuid */
  stepId?: string;
  /** @format date-time */
  eta?: string;
  /** @format date-time */
  startEtaWindow?: string;
  /** @format date-time */
  endEtaWindow?: string;
}

/** @format int32 */
export enum WoraCorbosServiceDeliveryStepHistoriesStepUsageStatus {
  Value0 = 0,
  Value10 = 10,
  Value20 = 20,
  Value30 = 30,
  Value40 = 40,
}

export interface WoraCorbosServiceDeliveryStepsAddressesDto {
  addressOriginal?: string | null;
  /** @format double */
  latOriginal?: number;
  /** @format double */
  lngOriginal?: number;
  error?: string | null;
  city?: string | null;
  houseNumber?: string | null;
  road?: string | null;
  suburb?: string | null;
  county?: string | null;
  countyCode?: string | null;
  state?: string | null;
  postcode?: string | null;
  country?: string | null;
  countryCode?: string | null;
  accurancy?: string | null;
  /** @format float */
  latitude?: number;
  /** @format float */
  longitude?: number;
  applicationName?: string | null;
}

export interface WoraCorbosServiceDeliveryStepsAssignDeliveryDto {
  /** @format uuid */
  driverId?: string;
  stepIds?: string[] | null;
  forcedDriver?: boolean;
}

export interface WoraCorbosServiceDeliveryStepsCompleteStepDto {
  /** @format uuid */
  id?: string;
  status?: WoraCorbosServiceDeliveryStepsStepStatus;
  delivery?: boolean;
  note?: string | null;
  reason?: string | null;
  signatureImageStream?: string | null;
  imageStream?: string[] | null;
  /** @format double */
  lat?: number | null;
  /** @format double */
  lng?: number | null;
  deliveredTo?: string | null;
  deliveredToType?: WoraCorbosServiceDeliveryStepsDeliveredToType;
}

export interface WoraCorbosServiceDeliveryStepsDeliverTaskAppDto {
  /** @format uuid */
  id?: string;
  taskNo?: string | null;
  taskType?: string | null;
  referente?: string | null;
  stato?: string | null;
  status?: WoraCorbosServiceDeliveryStepsStepStatus;
  instructions?: string | null;
  /** @format int32 */
  totalPackages?: number;
  details?: string | null;
  pickupDeliveryRelationship?: string | null;
  /** @format date-time */
  jobPickupDatetime?: string | null;
  jobPickupName?: string | null;
  jobPickupPhone?: string | null;
  /** @format double */
  jobPickupLatitude?: number;
  /** @format double */
  jobPickupLongitude?: number;
  jobPickupAddress?: string | null;
  jobPickupEmail?: string | null;
  /** @format date-time */
  timeFrom?: string | null;
  /** @format date-time */
  timeUntil?: string | null;
  citofono?: string | null;
  customerName?: string | null;
  servizioDiPortineria?: boolean;
  orarioDiConsegnaFlessibile?: string | null;
  /** @format int32 */
  pacchiPiccoliMax100Cm?: number;
  /** @format int32 */
  pacchiMediMax120Cm?: number;
  /** @format int32 */
  pacchiGrandiMax150Cm?: number;
  note?: string | null;
  /** @format int64 */
  contactId?: number;
}

/** @format int32 */
export enum WoraCorbosServiceDeliveryStepsDeliveredToType {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value99 = 99,
}

export interface WoraCorbosServiceDeliveryStepsDeliveryPlanDto {
  /** @format int32 */
  count?: number;
  /** @format uuid */
  driverId?: string;
  step?: WoraCorbosServiceDeliveryStepsDeliverTaskAppDto;
  next?: WoraCorbosServiceDeliveryStepsDeliverTaskAppDto;
  pickUps?: WoraCorbosServiceDeliveryStepsDeliverTaskAppDto[] | null;
  deliveries?: WoraCorbosServiceDeliveryStepsDeliverTaskAppDto[] | null;
  sameStep?: WoraCorbosServiceDeliveryStepsDeliverTaskAppDto[] | null;
  relatedStep?: WoraCorbosServiceDeliveryStepsDeliverTaskAppDto[] | null;
}

export interface WoraCorbosServiceDeliveryStepsDeliveryStepCreateDto {
  extraField?: DmDynamicDataEntitiesExtraFieldJsonObjectDto;
  /** @format uuid */
  storeId?: string | null;
  storeName?: string | null;
  stepType?: WoraCorbosServiceDeliveryStepsStepType;
  /**
   * @minLength 0
   * @maxLength 256
   */
  fullAddress?: string | null;
  houseNumber?: string | null;
  /** @format double */
  lat?: number | null;
  /** @format double */
  lng?: number | null;
  /** @format date-time */
  timeFrom?: string | null;
  /** @format date-time */
  timeTo?: string | null;
  /** @format date-time */
  stepDate?: string | null;
  /**
   * @minLength 0
   * @maxLength 1024
   */
  note?: string | null;
  /** @format int64 */
  contactId?: number | null;
  externalId?: string | null;
  contactName?: string | null;
  contactPhone?: string | null;
  contactEmail?: string | null;
  representative?: string | null;
  nationIsoCode?: string | null;
  interPhone?: string | null;
  reception?: boolean;
}

export interface WoraCorbosServiceDeliveryStepsDeliveryStepDataDto {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  creationTime?: string;
  /** @format uuid */
  creatorId?: string | null;
  /** @format date-time */
  lastModificationTime?: string | null;
  /** @format uuid */
  lastModifierId?: string | null;
  /** @format uuid */
  deliveryId?: string;
  stepType?: WoraCorbosServiceDeliveryStepsStepType;
  /** @format uuid */
  driverId?: string | null;
  status?: WoraCorbosServiceDeliveryStepsStepStatus;
  address?: string | null;
  /** @format double */
  lat?: number | null;
  /** @format double */
  lng?: number | null;
  /** @format date-time */
  requested?: string | null;
  /** @format date-time */
  requestedEnd?: string | null;
  /** @format date-time */
  stepDate?: string | null;
  /** @format date-time */
  real?: string | null;
  /** @format date-time */
  taskEta?: string | null;
  /** @format date-time */
  startEtaWindow?: string | null;
  /** @format date-time */
  endEtaWindow?: string | null;
  note?: string | null;
  /** @format int64 */
  contactId?: number;
  /** @format uuid */
  executionId?: string | null;
  /** @format uuid */
  scenarioId?: string | null;
  clusterKey?: string | null;
  forcedDriver?: boolean;
  stepCode?: string | null;
  contactName?: string | null;
  contactPhone?: string | null;
  contactAddress?: string | null;
  contactEmail?: string | null;
  concurrencyStamp?: string | null;
  applicationName?: string | null;
  city?: string | null;
  province?: string | null;
  zipCode?: string | null;
  addressNumber?: string | null;
  /** @format uuid */
  relatedId?: string | null;
  /** @format int64 */
  travelTime?: number;
  externalId?: string | null;
  hasImages?: boolean;
  statusName?: string | null;
  /** @format date-time */
  startDate?: string | null;
  /** @format date-time */
  assignedDate?: string | null;
  /** @format date-time */
  acceptedDate?: string | null;
  driver?: string | null;
  /** @format double */
  driverLat?: number | null;
  /** @format double */
  driverLng?: number | null;
  errors?: string | null;
  driverName?: string | null;
  historyNote?: string | null;
  historyUsage?: WoraCorbosServiceDeliveryStepHistoriesStepUsageStatus;
}

export interface WoraCorbosServiceDeliveryStepsDeliveryStepDto {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  creationTime?: string;
  /** @format uuid */
  creatorId?: string | null;
  /** @format date-time */
  lastModificationTime?: string | null;
  /** @format uuid */
  lastModifierId?: string | null;
  /** @format uuid */
  deliveryId?: string;
  stepType?: WoraCorbosServiceDeliveryStepsStepType;
  /** @format uuid */
  driverId?: string | null;
  status?: WoraCorbosServiceDeliveryStepsStepStatus;
  address?: string | null;
  /** @format int64 */
  nationId?: number | null;
  /** @format double */
  lat?: number | null;
  /** @format double */
  lng?: number | null;
  /** @format date-time */
  requested?: string | null;
  /** @format date-time */
  requestedEnd?: string | null;
  /** @format date-time */
  stepDate?: string | null;
  /** @format date-time */
  real?: string | null;
  /** @format date-time */
  taskEta?: string | null;
  /** @format date-time */
  startEtaWindow?: string | null;
  /** @format date-time */
  endEtaWindow?: string | null;
  note?: string | null;
  /** @format int64 */
  contactId?: number;
  /** @format uuid */
  executionId?: string | null;
  /** @format uuid */
  scenarioId?: string | null;
  clusterKey?: string | null;
  forcedDriver?: boolean;
  stepCode?: string | null;
  contactName?: string | null;
  contactPhone?: string | null;
  contactAddress?: string | null;
  contactEmail?: string | null;
  representative?: string | null;
  retailerId?: string | null;
  representativeEmail?: string | null;
  representativePhone?: string | null;
  concurrencyStamp?: string | null;
  applicationName?: string | null;
  deliveredToName?: string | null;
  deliveredToType?: WoraCorbosServiceDeliveryStepsDeliveredToType;
  city?: string | null;
  province?: string | null;
  zipCode?: string | null;
  addressNumber?: string | null;
  /** @format uuid */
  relatedId?: string | null;
  /** @format int64 */
  travelTime?: number;
  externalId?: string | null;
  hasImages?: boolean;
  statusName?: string | null;
  /** @format date-time */
  startDate?: string | null;
  /** @format date-time */
  assignedDate?: string | null;
  /** @format date-time */
  acceptedDate?: string | null;
  driver?: string | null;
  /** @format double */
  driverLat?: number | null;
  /** @format double */
  driverLng?: number | null;
  driverEmail?: string | null;
  driverPhone?: string | null;
  interPhone?: string | null;
  reception?: boolean;
  multiple?: WoraCorbosServiceDeliveryStepsAddressesDto[] | null;
}

export interface WoraCorbosServiceDeliveryStepsDeliveryStepUpdateDto {
  extraField?: DmDynamicDataEntitiesExtraFieldJsonObjectDto;
  /** @format uuid */
  deliveryId?: string;
  stepType?: WoraCorbosServiceDeliveryStepsStepType;
  /** @format uuid */
  driverId?: string | null;
  status?: WoraCorbosServiceDeliveryStepsStepStatus;
  /**
   * @minLength 0
   * @maxLength 256
   */
  address?: string | null;
  addressNumber?: string | null;
  zipCode?: string | null;
  city?: string | null;
  province?: string | null;
  /** @format double */
  lat?: number | null;
  /** @format double */
  lng?: number | null;
  /** @format date-time */
  requested?: string | null;
  /** @format date-time */
  requestedEnd?: string | null;
  /** @format date-time */
  stepDate?: string | null;
  /** @format date-time */
  real?: string | null;
  /** @format date-time */
  taskEta?: string | null;
  /** @format date-time */
  startEtaWindow?: string | null;
  /** @format date-time */
  endEtaWindow?: string | null;
  /**
   * @minLength 0
   * @maxLength 1024
   */
  note?: string | null;
  /** @format uuid */
  relatedId?: string | null;
  /** @format int64 */
  contactId?: number;
  concurrencyStamp?: string | null;
  externalId?: string | null;
  /** @format uuid */
  executionId?: string | null;
  /** @format uuid */
  scenarioId?: string | null;
  clusterKey?: string | null;
  forcedDriver?: boolean;
  stepCode?: string | null;
  contactName?: string | null;
  contactPhone?: string | null;
  contactAddress?: string | null;
  contactEmail?: string | null;
  reception?: boolean;
  interPhone?: string | null;
}

export interface WoraCorbosServiceDeliveryStepsDeliveryStepUpdateStatusDto {
  id?: string[] | null;
  status?: WoraCorbosServiceDeliveryStepsStepStatus;
  /** @format double */
  lat?: number;
  /** @format double */
  lng?: number;
  source?: string | null;
  note?: string | null;
  /** @format uuid */
  reasonId?: string | null;
}

export interface WoraCorbosServiceDeliveryStepsGetDeliverDriver {
  /** @format uuid */
  driverId?: string;
  /** @format date-time */
  scenarioDate?: string | null;
}

export interface WoraCorbosServiceDeliveryStepsGetDeliveryDataInput {
  /** @format uuid */
  executionId?: string;
  /** @format uuid */
  scenarioId?: string | null;
}

export interface WoraCorbosServiceDeliveryStepsGetDeliveryStepsInput {
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  maxResultCount?: number;
  /**
   * @format int32
   * @min 0
   * @max 2147483647
   */
  skipCount?: number;
  sorting?: string | null;
  filters?: DmFilterApplicationContractsFiltersTelerikFilterDto;
  maps?: DmFilterApplicationContractsFiltersFilterMapDto[] | null;
  /** @format uuid */
  deliveryId?: string | null;
  filterText?: string | null;
  stepType?: WoraCorbosServiceDeliveryStepsStepType;
  /** @format uuid */
  driverId?: string | null;
  status?: WoraCorbosServiceDeliveryStepsStepStatus;
  address?: string | null;
  /** @format double */
  latMin?: number | null;
  /** @format double */
  latMax?: number | null;
  /** @format double */
  lngMin?: number | null;
  /** @format double */
  lngMax?: number | null;
  /** @format date-time */
  requestedMin?: string | null;
  /** @format date-time */
  requestedMax?: string | null;
  /** @format date-time */
  realMin?: string | null;
  /** @format date-time */
  realMax?: string | null;
  /** @format date-time */
  taskEtaMin?: string | null;
  /** @format date-time */
  taskEtaMax?: string | null;
  /** @format date-time */
  startEtaWindow?: string | null;
  /** @format date-time */
  endEtaWindow?: string | null;
  /** @format date-time */
  stepDate?: string | null;
  note?: string | null;
  /** @format uuid */
  relatedId?: string | null;
  isRoute?: boolean;
  /** @format uuid */
  executionDetailId?: string | null;
  /** @format int64 */
  contactId?: number;
  /** @format uuid */
  executionId?: string | null;
  /** @format uuid */
  scenarioId?: string | null;
  clusterKey?: string | null;
  forcedDriver?: boolean;
  stepCode?: string | null;
  contactName?: string | null;
  contactPhone?: string | null;
  contactAddress?: string | null;
  contactEmail?: string | null;
  ids?: string[] | null;
}

/** @format int32 */
export enum WoraCorbosServiceDeliveryStepsStepStatus {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
  Value5 = 5,
  Value6 = 6,
  Value7 = 7,
  Value8 = 8,
  Value9 = 9,
  Value10 = 10,
  Value11 = 11,
  Value12 = 12,
}

/** @format int32 */
export enum WoraCorbosServiceDeliveryStepsStepType {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
  Value5 = 5,
}

export interface WoraCorbosServiceDeliveryStepsUpdateAddressInput {
  step?: WoraCorbosServiceDeliveryStepsDeliveryStepDto;
  fromCoordinate?: boolean;
  systemType?: WoraRoutingServiceGeoLocationsGeolocationSystemType;
  overrideContactAddress?: boolean;
}

export interface WoraCorbosServiceDeliveryStepsUpdateDeliveryAddressInput {
  token?: string | null;
  /** @format int64 */
  contactId?: number;
  address?: string | null;
  addressNumber?: string | null;
  zipCode?: string | null;
  city?: string | null;
  province?: string | null;
  note?: string | null;
  /** @format double */
  latitude?: number | null;
  /** @format double */
  longitude?: number | null;
  interPhone?: string | null;
  reception?: boolean;
}

export interface WoraCorbosServiceDeliveryStepsUpdateDeliveryTimeInput {
  token?: string | null;
  /** @format date-time */
  requestTimeStart?: string;
  /** @format date-time */
  requestTimeEnd?: string;
  stepType?: string | null;
}

export interface WoraCorbosServiceDeliveryStepsUploadImageInput {
  fileName?: string | null;
  base64?: string | null;
  /** @format uuid */
  deliveryStepId?: string;
}

export interface WoraCorbosServiceDriverPositionsDriverPositionCreateDto {
  /** @format uuid */
  driverId?: string;
  /** @format double */
  latitude?: number;
  /** @format double */
  longitude?: number;
}

export interface WoraCorbosServiceDriverPositionsDriverPositionDto {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  creationTime?: string;
  /** @format uuid */
  creatorId?: string | null;
  /** @format date-time */
  lastModificationTime?: string | null;
  /** @format uuid */
  lastModifierId?: string | null;
  /** @format uuid */
  driverId?: string;
  /** @format double */
  latitude?: number;
  /** @format double */
  longitude?: number;
}

export interface WoraCorbosServiceDriverPositionsDriverPositionUpdateDto {
  /** @format uuid */
  driverId?: string;
  /** @format double */
  latitude?: number;
  /** @format double */
  longitude?: number;
}

export interface WoraCorbosServiceDriversDriverCreateDto {
  extraField?: DmDynamicDataEntitiesExtraFieldJsonObjectDto;
  /** @format uuid */
  userId?: string;
  status?: WoraCorbosServiceDriversDriverStatus;
  vehicle?: WoraCorbosServiceDriversVehicle;
  /** @format double */
  latitude?: number | null;
  /** @format double */
  longitude?: number | null;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  phone?: string | null;
  city?: string | null;
  externalId?: string | null;
}

export interface WoraCorbosServiceDriversDriverDto {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  creationTime?: string;
  /** @format uuid */
  creatorId?: string | null;
  /** @format date-time */
  lastModificationTime?: string | null;
  /** @format uuid */
  lastModifierId?: string | null;
  isDeleted?: boolean;
  /** @format uuid */
  deleterId?: string | null;
  /** @format date-time */
  deletionTime?: string | null;
  /** @format uuid */
  userId?: string;
  status?: WoraCorbosServiceDriversDriverStatus;
  vehicle?: WoraCorbosServiceDriversVehicle;
  /** @format double */
  latitude?: number | null;
  /** @format double */
  longitude?: number | null;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  phone?: string | null;
  city?: string | null;
  externalId?: string | null;
  concurrencyStamp?: string | null;
  applicationName?: string | null;
}

export interface WoraCorbosServiceDriversDriverLocationDto {
  /** @format uuid */
  driverId?: string;
  /** @format double */
  latitude?: number;
  /** @format double */
  longitude?: number;
}

export interface WoraCorbosServiceDriversDriverOnlineDto {
  /** @format uuid */
  id?: string;
  status?: WoraCorbosServiceDriversDriverStatus;
}

/** @format int32 */
export enum WoraCorbosServiceDriversDriverStatus {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
}

export interface WoraCorbosServiceDriversDriverUpdateDto {
  extraField?: DmDynamicDataEntitiesExtraFieldJsonObjectDto;
  /** @format uuid */
  userId?: string;
  status?: WoraCorbosServiceDriversDriverStatus;
  vehicle?: WoraCorbosServiceDriversVehicle;
  /** @format double */
  latitude?: number | null;
  /** @format double */
  longitude?: number | null;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  phone?: string | null;
  city?: string | null;
  externalId?: string | null;
  concurrencyStamp?: string | null;
}

export interface WoraCorbosServiceDriversDriverVehicleDto {
  /** @format uuid */
  id?: string;
  vehicle?: WoraCorbosServiceDriversVehicle;
}

export interface WoraCorbosServiceDriversGetDriversInput {
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  maxResultCount?: number;
  /**
   * @format int32
   * @min 0
   * @max 2147483647
   */
  skipCount?: number;
  sorting?: string | null;
  filters?: DmFilterApplicationContractsFiltersTelerikFilterDto;
  maps?: DmFilterApplicationContractsFiltersFilterMapDto[] | null;
  filterText?: string | null;
  /** @format uuid */
  userId?: string | null;
  status?: WoraCorbosServiceDriversDriverStatus;
  vehicle?: WoraCorbosServiceDriversVehicle;
  /** @format double */
  latitude?: number | null;
  /** @format double */
  longitude?: number | null;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  phone?: string | null;
  city?: string | null;
}

/** @format int32 */
export enum WoraCorbosServiceDriversVehicle {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
  Value5 = 5,
}

export interface WoraCorbosServiceEventMessageTemplatesEventMessageTemplateCreateDto {
  /**
   * @minLength 0
   * @maxLength 128
   */
  eventType?: string | null;
  /** @format int32 */
  eventValue?: number;
  /** @format uuid */
  messageTemplateId?: string;
}

export interface WoraCorbosServiceEventMessageTemplatesEventMessageTemplateDto {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  creationTime?: string;
  /** @format uuid */
  creatorId?: string | null;
  /** @format date-time */
  lastModificationTime?: string | null;
  /** @format uuid */
  lastModifierId?: string | null;
  isDeleted?: boolean;
  /** @format uuid */
  deleterId?: string | null;
  /** @format date-time */
  deletionTime?: string | null;
  eventType?: string | null;
  /** @format int32 */
  eventValue?: number;
  /** @format uuid */
  messageTemplateId?: string;
  concurrencyStamp?: string | null;
}

export interface WoraCorbosServiceEventMessageTemplatesEventMessageTemplateUpdateDto {
  /**
   * @minLength 0
   * @maxLength 128
   */
  eventType?: string | null;
  /** @format int32 */
  eventValue?: number;
  /** @format uuid */
  messageTemplateId?: string;
  concurrencyStamp?: string | null;
}

export interface WoraCorbosServiceFileStoragesFileStorageCreateDto {
  /** @format int32 */
  dimensionInByte: number;
  /**
   * @minLength 0
   * @maxLength 255
   */
  fileName: string;
  /**
   * @minLength 0
   * @maxLength 128
   */
  fileType: string;
  typology?: WoraCorbosServiceFileStoragesFileTypology;
  /** @format uuid */
  entityId?: string;
}

export interface WoraCorbosServiceFileStoragesFileStorageDto {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  creationTime?: string;
  /** @format uuid */
  creatorId?: string | null;
  /** @format date-time */
  lastModificationTime?: string | null;
  /** @format uuid */
  lastModifierId?: string | null;
  isDeleted?: boolean;
  /** @format uuid */
  deleterId?: string | null;
  /** @format date-time */
  deletionTime?: string | null;
  /** @format int32 */
  dimensionInByte?: number;
  fileName?: string | null;
  fileType?: string | null;
  typology?: WoraCorbosServiceFileStoragesFileTypology;
  /** @format uuid */
  entityId?: string;
}

export interface WoraCorbosServiceFileStoragesFileStorageUpdateDto {
  /** @format int32 */
  dimensionInByte: number;
  /**
   * @minLength 0
   * @maxLength 255
   */
  fileName: string;
  /**
   * @minLength 0
   * @maxLength 128
   */
  fileType: string;
  typology?: WoraCorbosServiceFileStoragesFileTypology;
  /** @format uuid */
  entityId?: string;
}

/** @format int32 */
export enum WoraCorbosServiceFileStoragesFileTypology {
  Value0 = 0,
}

/** @format int32 */
export enum WoraCorbosServiceHistoryEventsEntityType {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
}

/** @format int32 */
export enum WoraCorbosServiceHistoryEventsEventType {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
  Value5 = 5,
  Value6 = 6,
  Value7 = 7,
  Value8 = 8,
  Value9 = 9,
  Value10 = 10,
  Value11 = 11,
  Value12 = 12,
  Value13 = 13,
  Value14 = 14,
}

export interface WoraCorbosServiceHistoryEventsGetHistoryEventsInput {
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  maxResultCount?: number;
  /**
   * @format int32
   * @min 0
   * @max 2147483647
   */
  skipCount?: number;
  sorting?: string | null;
  filters?: DmFilterApplicationContractsFiltersTelerikFilterDto;
  maps?: DmFilterApplicationContractsFiltersFilterMapDto[] | null;
  filterText?: string | null;
  eventType?: WoraCorbosServiceHistoryEventsEventType;
  entityType?: WoraCorbosServiceHistoryEventsEntityType;
  externalMessage?: string | null;
  internalMessage?: string | null;
  isPublic?: boolean | null;
}

export interface WoraCorbosServiceHistoryEventsHistoryEventCreateDto {
  extraField?: DmDynamicDataEntitiesExtraFieldJsonObjectDto;
  eventType?: WoraCorbosServiceHistoryEventsEventType;
  entityType?: WoraCorbosServiceHistoryEventsEntityType;
  /**
   * @minLength 0
   * @maxLength 255
   */
  externalMessage?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  internalMessage?: string | null;
  isPublic?: boolean;
}

export interface WoraCorbosServiceHistoryEventsHistoryEventDto {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  creationTime?: string;
  /** @format uuid */
  creatorId?: string | null;
  /** @format date-time */
  lastModificationTime?: string | null;
  /** @format uuid */
  lastModifierId?: string | null;
  isDeleted?: boolean;
  /** @format uuid */
  deleterId?: string | null;
  /** @format date-time */
  deletionTime?: string | null;
  eventType?: WoraCorbosServiceHistoryEventsEventType;
  entityType?: WoraCorbosServiceHistoryEventsEntityType;
  externalMessage?: string | null;
  internalMessage?: string | null;
  isPublic?: boolean;
  concurrencyStamp?: string | null;
  applicationName?: string | null;
}

export interface WoraCorbosServiceHistoryEventsHistoryEventUpdateDto {
  extraField?: DmDynamicDataEntitiesExtraFieldJsonObjectDto;
  eventType?: WoraCorbosServiceHistoryEventsEventType;
  entityType?: WoraCorbosServiceHistoryEventsEntityType;
  /**
   * @minLength 0
   * @maxLength 255
   */
  externalMessage?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  internalMessage?: string | null;
  isPublic?: boolean;
  concurrencyStamp?: string | null;
}

/** @format int32 */
export enum WoraCorbosServiceNotificationsAutomaticMessageType {
  Value10 = 10,
  Value20 = 20,
  Value30 = 30,
  Value40 = 40,
  Value50 = 50,
  Value100 = 100,
}

export interface WoraCorbosServiceNotificationsGetNotificationsInput {
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  maxResultCount?: number;
  /**
   * @format int32
   * @min 0
   * @max 2147483647
   */
  skipCount?: number;
  sorting?: string | null;
  filters?: DmFilterApplicationContractsFiltersTelerikFilterDto;
  maps?: DmFilterApplicationContractsFiltersFilterMapDto[] | null;
  filterText?: string | null;
  messageType?: string | null;
  message?: string | null;
  internalStatus?: WoraCorbosServiceNotificationsNotificationStatus;
  status?: string | null;
  sendNotificationId?: string | null;
  sendNotifcationResult?: string | null;
  sendNotificationResponse?: string | null;
  /** @format uuid */
  deliveryId?: string | null;
  phoneNumber?: string | null;
}

export interface WoraCorbosServiceNotificationsNotificationCreateDto {
  extraField?: DmDynamicDataEntitiesExtraFieldJsonObjectDto;
  /**
   * @minLength 0
   * @maxLength 32
   */
  messageType?: string | null;
  /**
   * @minLength 0
   * @maxLength 1024
   */
  message?: string | null;
  internalStatus?: WoraCorbosServiceNotificationsNotificationStatus;
  status?: string | null;
  /**
   * @minLength 0
   * @maxLength 128
   */
  sendNotificationId?: string | null;
  /**
   * @minLength 0
   * @maxLength 2048
   */
  sendNotifcationResult?: string | null;
  /**
   * @minLength 0
   * @maxLength 4096
   */
  sendNotificationResponse?: string | null;
  /** @format uuid */
  deliveryId?: string | null;
  phoneNumber?: string | null;
}

export interface WoraCorbosServiceNotificationsNotificationDto {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  creationTime?: string;
  /** @format uuid */
  creatorId?: string | null;
  /** @format date-time */
  lastModificationTime?: string | null;
  /** @format uuid */
  lastModifierId?: string | null;
  isDeleted?: boolean;
  /** @format uuid */
  deleterId?: string | null;
  /** @format date-time */
  deletionTime?: string | null;
  messageType?: string | null;
  message?: string | null;
  internalStatus?: WoraCorbosServiceNotificationsNotificationStatus;
  status?: string | null;
  sendNotificationId?: string | null;
  sendNotifcationResult?: string | null;
  sendNotificationResponse?: string | null;
  /** @format uuid */
  deliveryId?: string | null;
  concurrencyStamp?: string | null;
  phoneNumber?: string | null;
  applicationName?: string | null;
}

/** @format int32 */
export enum WoraCorbosServiceNotificationsNotificationStatus {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
}

export interface WoraCorbosServiceNotificationsNotificationUpdateDto {
  extraField?: DmDynamicDataEntitiesExtraFieldJsonObjectDto;
  /**
   * @minLength 0
   * @maxLength 32
   */
  messageType?: string | null;
  /**
   * @minLength 0
   * @maxLength 1024
   */
  message?: string | null;
  internalStatus?: WoraCorbosServiceNotificationsNotificationStatus;
  status?: string | null;
  /**
   * @minLength 0
   * @maxLength 128
   */
  sendNotificationId?: string | null;
  /**
   * @minLength 0
   * @maxLength 2048
   */
  sendNotifcationResult?: string | null;
  /**
   * @minLength 0
   * @maxLength 4096
   */
  sendNotificationResponse?: string | null;
  /** @format uuid */
  deliveryId?: string | null;
  phoneNumber?: string | null;
  concurrencyStamp?: string | null;
}

export interface WoraCorbosServiceNotificationsSendNotificationDto {
  /** @deprecated */
  numberTo?: string | null;
  templateType?: WoraCorbosServiceNotificationsAutomaticMessageType;
  /** @deprecated */
  templateName?: string | null;
  /** @format uuid */
  deliveryId?: string | null;
  /** @deprecated */
  message?: string | null;
  /**
   * @deprecated
   * @format uuid
   */
  executionId?: string | null;
  steps?: WoraCorbosServiceDeliveryStepsDeliveryStepDto[] | null;
  sendImmediately?: boolean;
}

export interface WoraCorbosServiceReviewsReviewCheckDto {
  token?: string | null;
}

export interface WoraCorbosServiceReviewsReviewCreateDto {
  token?: string | null;
  /** @format uuid */
  deliveryId?: string;
  /** @format int32 */
  rating?: number;
  /**
   * @minLength 0
   * @maxLength 4096
   */
  note?: string | null;
}

export interface WoraCorbosServiceReviewsReviewDto {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  creationTime?: string;
  /** @format uuid */
  creatorId?: string | null;
  /** @format date-time */
  lastModificationTime?: string | null;
  /** @format uuid */
  lastModifierId?: string | null;
  isDeleted?: boolean;
  /** @format uuid */
  deleterId?: string | null;
  /** @format date-time */
  deletionTime?: string | null;
  /** @format uuid */
  deliveryId?: string;
  /** @format int32 */
  rating?: number;
  note?: string | null;
  concurrencyStamp?: string | null;
}

export interface WoraCorbosServiceReviewsReviewUpdateDto {
  /** @format uuid */
  deliveryId?: string;
  /** @format int32 */
  rating?: number;
  /**
   * @minLength 0
   * @maxLength 4096
   */
  note?: string | null;
  concurrencyStamp?: string | null;
}

export interface WoraCorbosServiceSettingsSettingDto {
  name?: string | null;
  value?: string | null;
}

export interface WoraCorbosServiceSharedDownloadTokenResultDto {
  token?: string | null;
}

export interface WoraCorbosServiceShiftsGetShiftsInput {
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  maxResultCount?: number;
  /**
   * @format int32
   * @min 0
   * @max 2147483647
   */
  skipCount?: number;
  sorting?: string | null;
  filters?: DmFilterApplicationContractsFiltersTelerikFilterDto;
  maps?: DmFilterApplicationContractsFiltersFilterMapDto[] | null;
  filterText?: string | null;
  description?: string | null;
  note?: string | null;
  /** @format uuid */
  driverId?: string | null;
  /** @format date-time */
  timeFromMin?: string | null;
  /** @format date-time */
  timeFromMax?: string | null;
  /** @format date-time */
  timeToMin?: string | null;
  /** @format date-time */
  timeToMax?: string | null;
  /** @format date-time */
  maxTime?: string | null;
}

export interface WoraCorbosServiceShiftsShiftCreateDto {
  extraField?: DmDynamicDataEntitiesExtraFieldJsonObjectDto;
  description?: string | null;
  note?: string | null;
  /** @format uuid */
  driverId?: string;
  /** @format date-time */
  timeFrom?: string | null;
  /** @format date-time */
  timeTo?: string | null;
  /** @format date-time */
  maxTime?: string | null;
}

export interface WoraCorbosServiceShiftsShiftDto {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  creationTime?: string;
  /** @format uuid */
  creatorId?: string | null;
  /** @format date-time */
  lastModificationTime?: string | null;
  /** @format uuid */
  lastModifierId?: string | null;
  isDeleted?: boolean;
  /** @format uuid */
  deleterId?: string | null;
  /** @format date-time */
  deletionTime?: string | null;
  description?: string | null;
  note?: string | null;
  /** @format uuid */
  driverId?: string;
  /** @format date-time */
  timeFrom?: string | null;
  /** @format date-time */
  timeTo?: string | null;
  /** @format date-time */
  maxTime?: string | null;
  concurrencyStamp?: string | null;
  applicationName?: string | null;
}

export interface WoraCorbosServiceShiftsShiftFullDto {
  /** @format date-time */
  dueDate?: string | null;
  /** @format date-time */
  date?: string | null;
  /** @format date-time */
  startTime?: string | null;
  /** @format date-time */
  endTime?: string | null;
  /** @format date-time */
  maxTime?: string | null;
  /** @format int32 */
  ctoId?: number | null;
  location?: string | null;
  vehicle?: WoraCorbosServiceDriversVehicle;
  fistName?: string | null;
  lastName?: string | null;
  agentId?: string | null;
  contactType?: string | null;
  subject?: string | null;
  /** @format uuid */
  driverId?: string | null;
  /** @format date-time */
  lastLocalizationDateTime?: string | null;
  /** @format double */
  latitude?: number | null;
  /** @format double */
  longitude?: number | null;
}

export interface WoraCorbosServiceShiftsShiftUpdateDto {
  extraField?: DmDynamicDataEntitiesExtraFieldJsonObjectDto;
  description?: string | null;
  note?: string | null;
  /** @format uuid */
  driverId?: string;
  /** @format date-time */
  timeFrom?: string | null;
  /** @format date-time */
  timeTo?: string | null;
  /** @format date-time */
  maxTime?: string | null;
  concurrencyStamp?: string | null;
}

export interface WoraCorbosServiceStoresGetStoresInput {
  /**
   * @format int32
   * @min 1
   * @max 2147483647
   */
  maxResultCount?: number;
  /**
   * @format int32
   * @min 0
   * @max 2147483647
   */
  skipCount?: number;
  sorting?: string | null;
  filters?: DmFilterApplicationContractsFiltersTelerikFilterDto;
  maps?: DmFilterApplicationContractsFiltersFilterMapDto[] | null;
  filterText?: string | null;
  name?: string | null;
  number?: string | null;
  address?: string | null;
  addressNumber?: string | null;
  province?: string | null;
  city?: string | null;
  zipCode?: string | null;
  vatID?: string | null;
  fiscalCode?: string | null;
  /** @format date-time */
  openingTimeMin?: string | null;
  /** @format date-time */
  openingTimeMax?: string | null;
  /** @format date-time */
  closingTimeMin?: string | null;
  /** @format date-time */
  closingTimeMax?: string | null;
  /** @format int64 */
  businessPartnerId?: number | null;
  /** @format int64 */
  contactId?: number;
  pickupInstructions?: string | null;
  deliveryInstructions?: string | null;
}

export interface WoraCorbosServiceStoresStoreCreateDto {
  extraField?: DmDynamicDataEntitiesExtraFieldJsonObjectDto;
  /**
   * @minLength 0
   * @maxLength 255
   */
  name: string;
  /**
   * @minLength 0
   * @maxLength 32
   */
  number?: string | null;
  /**
   * @minLength 0
   * @maxLength 128
   */
  address?: string | null;
  /**
   * @minLength 0
   * @maxLength 32
   */
  addressNumber?: string | null;
  /**
   * @minLength 0
   * @maxLength 32
   */
  province?: string | null;
  /**
   * @minLength 0
   * @maxLength 64
   */
  city?: string | null;
  /**
   * @minLength 0
   * @maxLength 8
   */
  zipCode?: string | null;
  /**
   * @minLength 0
   * @maxLength 11
   */
  vatID?: string | null;
  /**
   * @minLength 0
   * @maxLength 11
   */
  fiscalCode?: string | null;
  /** @format date-time */
  openingTime: string;
  /** @format date-time */
  closingTime: string;
  /** @format int64 */
  businessPartnerId: number;
  /** @format int64 */
  contactId?: number;
  /** @format double */
  lat?: number | null;
  /** @format double */
  lng?: number | null;
  pickupInstructions?: string | null;
  deliveryInstructions?: string | null;
  /** @format uuid */
  tenantId?: string | null;
  contactStore?: DmCrmContactsContactCreateDto;
}

export interface WoraCorbosServiceStoresStoreDto {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  creationTime?: string;
  /** @format uuid */
  creatorId?: string | null;
  /** @format date-time */
  lastModificationTime?: string | null;
  /** @format uuid */
  lastModifierId?: string | null;
  isDeleted?: boolean;
  /** @format uuid */
  deleterId?: string | null;
  /** @format date-time */
  deletionTime?: string | null;
  name?: string | null;
  number?: string | null;
  address?: string | null;
  addressNumber?: string | null;
  province?: string | null;
  city?: string | null;
  zipCode?: string | null;
  vatID?: string | null;
  fiscalCode?: string | null;
  /** @format date-time */
  openingTime?: string;
  /** @format date-time */
  closingTime?: string;
  /** @format int64 */
  businessPartnerId?: number;
  /** @format int64 */
  contactId?: number;
  pickupInstructions?: string | null;
  deliveryInstructions?: string | null;
  contactStore?: DmCrmContactsContactDto;
  concurrencyStamp?: string | null;
  applicationName?: string | null;
}

export interface WoraCorbosServiceStoresStoreUpdateDto {
  extraField?: DmDynamicDataEntitiesExtraFieldJsonObjectDto;
  /**
   * @minLength 0
   * @maxLength 255
   */
  name: string;
  /**
   * @minLength 0
   * @maxLength 32
   */
  number?: string | null;
  /**
   * @minLength 0
   * @maxLength 128
   */
  address?: string | null;
  /**
   * @minLength 0
   * @maxLength 32
   */
  addressNumber?: string | null;
  /**
   * @minLength 0
   * @maxLength 32
   */
  province?: string | null;
  /**
   * @minLength 0
   * @maxLength 64
   */
  city?: string | null;
  /**
   * @minLength 0
   * @maxLength 8
   */
  zipCode?: string | null;
  /**
   * @minLength 0
   * @maxLength 11
   */
  vatID?: string | null;
  /**
   * @minLength 0
   * @maxLength 11
   */
  fiscalCode?: string | null;
  /** @format date-time */
  openingTime: string;
  /** @format date-time */
  closingTime: string;
  /** @format int64 */
  businessPartnerId: number;
  concurrencyStamp?: string | null;
  /** @format int64 */
  contactId?: number;
  /** @format double */
  lat?: number | null;
  /** @format double */
  lng?: number | null;
  pickupInstructions?: string | null;
  deliveryInstructions?: string | null;
  contactStore?: DmCrmContactsContactCreateDto;
}

export interface WoraCorbosServiceUserStoresUserStoreCreateDto {
  /** @format uuid */
  userId?: string;
  /** @format uuid */
  storeId?: string;
}

export interface WoraCorbosServiceUserStoresUserStoreDto {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  creationTime?: string;
  /** @format uuid */
  creatorId?: string | null;
  /** @format date-time */
  lastModificationTime?: string | null;
  /** @format uuid */
  lastModifierId?: string | null;
  /** @format uuid */
  userId?: string;
  /** @format uuid */
  storeId?: string;
  concurrencyStamp?: string | null;
}

export interface WoraCorbosServiceUserStoresUserStoreUpdateDto {
  /** @format uuid */
  userId?: string;
  /** @format uuid */
  storeId?: string;
  concurrencyStamp?: string | null;
}

/** @format int32 */
export enum WoraRoutingServiceGeoLocationsGeolocationSystemType {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
}

import type { AxiosInstance, AxiosRequestConfig, AxiosResponse, HeadersDefaults, ResponseType } from "axios";
import axios from "axios";

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, "data" | "params" | "url" | "responseType"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, "data" | "cancelToken"> {
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || "" });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === "object" && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] = property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (type === ContentType.FormData && body && body !== null && typeof body === "object") {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== "string") {
      body = JSON.stringify(body);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title CorbosService API
 * @version v1
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  api = {
    /**
     * No description
     *
     * @tags AbpApiDefinition
     * @name AbpApiDefinitionList
     * @request GET:/api/abp/api-definition
     * @secure
     */
    abpApiDefinitionList: (
      query?: {
        IncludeTypes?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<VoloAbpHttpModelingApplicationApiDescriptionModel, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/abp/api-definition`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AbpApplicationConfiguration
     * @name AbpApplicationConfigurationList
     * @request GET:/api/abp/application-configuration
     * @secure
     */
    abpApplicationConfigurationList: (
      query?: {
        IncludeLocalizationResources?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        VoloAbpAspNetCoreMvcApplicationConfigurationsApplicationConfigurationDto,
        VoloAbpHttpRemoteServiceErrorResponse
      >({
        path: `/api/abp/application-configuration`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AbpApplicationLocalization
     * @name AbpApplicationLocalizationList
     * @request GET:/api/abp/application-localization
     * @secure
     */
    abpApplicationLocalizationList: (
      query: {
        CultureName: string;
        OnlyDynamics?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        VoloAbpAspNetCoreMvcApplicationConfigurationsApplicationLocalizationDto,
        VoloAbpHttpRemoteServiceErrorResponse
      >({
        path: `/api/abp/application-localization`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BusinessPartner
     * @name CrmBusinessPartnersCreate
     * @request POST:/api/crm/business-partners
     * @secure
     */
    crmBusinessPartnersCreate: (data: DmCrmBusinessPartnersGetBusinessPartnersInput, params: RequestParams = {}) =>
      this.request<
        VoloAbpApplicationDtosPagedResultDto1DmCrmBusinessPartnersBusinessPartnerDtoDmCrmApplicationContractsVersion1030CultureNeutralPublicKeyTokenNull,
        VoloAbpHttpRemoteServiceErrorResponse
      >({
        path: `/api/crm/business-partners`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BusinessPartner
     * @name CrmBusinessPartnersDetail
     * @request GET:/api/crm/business-partners/{id}
     * @secure
     */
    crmBusinessPartnersDetail: (id: number, params: RequestParams = {}) =>
      this.request<DmCrmBusinessPartnersBusinessPartnerDto, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/crm/business-partners/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BusinessPartner
     * @name CrmBusinessPartnersUpdate
     * @request PUT:/api/crm/business-partners/{id}
     * @secure
     */
    crmBusinessPartnersUpdate: (
      id: number,
      data: DmCrmBusinessPartnersBusinessPartnerUpdateDto,
      params: RequestParams = {},
    ) =>
      this.request<DmCrmBusinessPartnersBusinessPartnerDto, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/crm/business-partners/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BusinessPartner
     * @name CrmBusinessPartnersDelete
     * @request DELETE:/api/crm/business-partners/{id}
     * @secure
     */
    crmBusinessPartnersDelete: (id: number, params: RequestParams = {}) =>
      this.request<void, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/crm/business-partners/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags BusinessPartner
     * @name CrmBusinessPartnersComboboxList
     * @request GET:/api/crm/business-partners/combobox
     * @secure
     */
    crmBusinessPartnersComboboxList: (params: RequestParams = {}) =>
      this.request<
        VoloAbpApplicationDtosListResultDto1DmApplicationComboxesComboboxItemLongDtoDmApplicationContractsVersion1160CultureNeutralPublicKeyTokenNull,
        VoloAbpHttpRemoteServiceErrorResponse
      >({
        path: `/api/crm/business-partners/combobox`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BusinessPartner
     * @name CrmBusinessPartnersCreateCreate
     * @request POST:/api/crm/business-partners/create
     * @secure
     */
    crmBusinessPartnersCreateCreate: (
      data: DmCrmBusinessPartnersBusinessPartnerCreateDto,
      params: RequestParams = {},
    ) =>
      this.request<DmCrmBusinessPartnersBusinessPartnerDto, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/crm/business-partners/create`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BusinessPartner
     * @name CrmBusinessPartnersComboboxItemsCreate
     * @request POST:/api/crm/business-partners/combobox-items
     * @secure
     */
    crmBusinessPartnersComboboxItemsCreate: (
      data: DmCrmBusinessPartnersGetBusinessPartnersInput,
      params: RequestParams = {},
    ) =>
      this.request<
        VoloAbpApplicationDtosPagedResultDto1DmApplicationComboxesComboboxItemDtoDmApplicationContractsVersion1160CultureNeutralPublicKeyTokenNull,
        VoloAbpHttpRemoteServiceErrorResponse
      >({
        path: `/api/crm/business-partners/combobox-items`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BusinessPartner
     * @name CrmBusinessPartnersExcelCreate
     * @request POST:/api/crm/business-partners/excel
     * @secure
     */
    crmBusinessPartnersExcelCreate: (data: DmCrmBusinessPartnersGetBusinessPartnersInput, params: RequestParams = {}) =>
      this.request<File, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/crm/business-partners/excel`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BusinessPartner
     * @name CrmBusinessPartnersExcelBatchCreate
     * @request POST:/api/crm/business-partners/excel-batch
     * @secure
     */
    crmBusinessPartnersExcelBatchCreate: (
      data: DmCrmBusinessPartnersGetBusinessPartnersInput,
      params: RequestParams = {},
    ) =>
      this.request<void, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/crm/business-partners/excel-batch`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags BusinessPartnerTenantLink
     * @name CrmBusinessPartnerTenantLinksComboboxItemsCreate
     * @request POST:/api/crm/business-partner-tenant-links/combobox-items
     * @secure
     */
    crmBusinessPartnerTenantLinksComboboxItemsCreate: (
      data: DmCrmBusinessPartnerTenantLinksGetBusinessPartnerTenantLinksInput,
      params: RequestParams = {},
    ) =>
      this.request<
        VoloAbpApplicationDtosPagedResultDto1DmApplicationComboxesComboboxItemDtoDmApplicationContractsVersion1160CultureNeutralPublicKeyTokenNull,
        VoloAbpHttpRemoteServiceErrorResponse
      >({
        path: `/api/crm/business-partner-tenant-links/combobox-items`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BusinessPartnerTenantLink
     * @name CrmBusinessPartnerTenantLinksExcelCreate
     * @request POST:/api/crm/business-partner-tenant-links/excel
     * @secure
     */
    crmBusinessPartnerTenantLinksExcelCreate: (
      data: DmCrmBusinessPartnerTenantLinksGetBusinessPartnerTenantLinksInput,
      params: RequestParams = {},
    ) =>
      this.request<File, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/crm/business-partner-tenant-links/excel`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BusinessPartnerTenantLink
     * @name CrmBusinessPartnerTenantLinksExcelBatchCreate
     * @request POST:/api/crm/business-partner-tenant-links/excel-batch
     * @secure
     */
    crmBusinessPartnerTenantLinksExcelBatchCreate: (
      data: DmCrmBusinessPartnerTenantLinksGetBusinessPartnerTenantLinksInput,
      params: RequestParams = {},
    ) =>
      this.request<void, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/crm/business-partner-tenant-links/excel-batch`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags BusinessPartnerTenantLink
     * @name CrmBusinessPartnerTenantLinksCreate
     * @request POST:/api/crm/business-partner-tenant-links
     * @secure
     */
    crmBusinessPartnerTenantLinksCreate: (
      data: DmCrmBusinessPartnerTenantLinksGetBusinessPartnerTenantLinksInput,
      params: RequestParams = {},
    ) =>
      this.request<
        VoloAbpApplicationDtosPagedResultDto1DmCrmBusinessPartnerTenantLinksBusinessPartnerTenantLinkDtoDmCrmApplicationContractsVersion1030CultureNeutralPublicKeyTokenNull,
        VoloAbpHttpRemoteServiceErrorResponse
      >({
        path: `/api/crm/business-partner-tenant-links`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BusinessPartnerTenantLink
     * @name CrmBusinessPartnerTenantLinksDetail
     * @request GET:/api/crm/business-partner-tenant-links/{id}
     * @secure
     */
    crmBusinessPartnerTenantLinksDetail: (id: string, params: RequestParams = {}) =>
      this.request<DmCrmBusinessPartnerTenantLinksBusinessPartnerTenantLinkDto, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/crm/business-partner-tenant-links/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BusinessPartnerTenantLink
     * @name CrmBusinessPartnerTenantLinksUpdate
     * @request PUT:/api/crm/business-partner-tenant-links/{id}
     * @secure
     */
    crmBusinessPartnerTenantLinksUpdate: (
      id: string,
      data: DmCrmBusinessPartnerTenantLinksBusinessPartnerTenantLinkUpdateDto,
      params: RequestParams = {},
    ) =>
      this.request<DmCrmBusinessPartnerTenantLinksBusinessPartnerTenantLinkDto, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/crm/business-partner-tenant-links/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BusinessPartnerTenantLink
     * @name CrmBusinessPartnerTenantLinksDelete
     * @request DELETE:/api/crm/business-partner-tenant-links/{id}
     * @secure
     */
    crmBusinessPartnerTenantLinksDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/crm/business-partner-tenant-links/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags BusinessPartnerTenantLink
     * @name CrmBusinessPartnerTenantLinksCreateCreate
     * @request POST:/api/crm/business-partner-tenant-links/create
     * @secure
     */
    crmBusinessPartnerTenantLinksCreateCreate: (
      data: DmCrmBusinessPartnerTenantLinksBusinessPartnerTenantLinkCreateDto,
      params: RequestParams = {},
    ) =>
      this.request<DmCrmBusinessPartnerTenantLinksBusinessPartnerTenantLinkDto, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/crm/business-partner-tenant-links/create`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BusinessPartnerTenantLink
     * @name CrmBusinessPartnerTenantLinksBpNotLinkList
     * @request GET:/api/crm/business-partner-tenant-links/bp-not-link
     * @secure
     */
    crmBusinessPartnerTenantLinksBpNotLinkList: (params: RequestParams = {}) =>
      this.request<
        VoloAbpApplicationDtosListResultDto1DmApplicationComboxesComboboxItemLongDtoDmApplicationContractsVersion1160CultureNeutralPublicKeyTokenNull,
        VoloAbpHttpRemoteServiceErrorResponse
      >({
        path: `/api/crm/business-partner-tenant-links/bp-not-link`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BusinessPartnerTenantLink
     * @name CrmBusinessPartnerTenantLinksTenantNotLinkList
     * @request GET:/api/crm/business-partner-tenant-links/tenant-not-link
     * @secure
     */
    crmBusinessPartnerTenantLinksTenantNotLinkList: (params: RequestParams = {}) =>
      this.request<
        VoloAbpApplicationDtosListResultDto1DmApplicationComboxesComboboxItemDtoDmApplicationContractsVersion1160CultureNeutralPublicKeyTokenNull,
        VoloAbpHttpRemoteServiceErrorResponse
      >({
        path: `/api/crm/business-partner-tenant-links/tenant-not-link`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BusinessPartnerUserLink
     * @name CrmBusinessPartnerUserLinksList
     * @request GET:/api/crm/business-partner-user-links
     * @secure
     */
    crmBusinessPartnerUserLinksList: (
      query?: {
        FilterText?: string;
        /** @format int64 */
        BusinessPartnerIdMin?: number;
        /** @format int64 */
        BusinessPartnerIdMax?: number;
        /** @format int64 */
        BusinessPartnerId?: number;
        /** @format uuid */
        UserId?: string;
        UserIds?: string[];
        Sorting?: string;
        /**
         * @format int32
         * @min 0
         * @max 2147483647
         */
        SkipCount?: number;
        /**
         * @format int32
         * @min 1
         * @max 2147483647
         */
        MaxResultCount?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        VoloAbpApplicationDtosPagedResultDto1DmCrmBusinessPartnerUserLinksBusinessPartnerUserLinkDtoDmCrmApplicationContractsVersion1030CultureNeutralPublicKeyTokenNull,
        VoloAbpHttpRemoteServiceErrorResponse
      >({
        path: `/api/crm/business-partner-user-links`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BusinessPartnerUserLink
     * @name CrmBusinessPartnerUserLinksCreate
     * @request POST:/api/crm/business-partner-user-links
     * @secure
     */
    crmBusinessPartnerUserLinksCreate: (
      data: DmCrmBusinessPartnerUserLinksBusinessPartnerUserLinkCreateDto,
      params: RequestParams = {},
    ) =>
      this.request<DmCrmBusinessPartnerUserLinksBusinessPartnerUserLinkDto, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/crm/business-partner-user-links`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BusinessPartnerUserLink
     * @name CrmBusinessPartnerUserLinksDetail
     * @request GET:/api/crm/business-partner-user-links/{id}
     * @secure
     */
    crmBusinessPartnerUserLinksDetail: (id: number, params: RequestParams = {}) =>
      this.request<DmCrmBusinessPartnerUserLinksBusinessPartnerUserLinkDto, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/crm/business-partner-user-links/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BusinessPartnerUserLink
     * @name CrmBusinessPartnerUserLinksUpdate
     * @request PUT:/api/crm/business-partner-user-links/{id}
     * @secure
     */
    crmBusinessPartnerUserLinksUpdate: (
      id: number,
      data: DmCrmBusinessPartnerUserLinksBusinessPartnerUserLinkUpdateDto,
      params: RequestParams = {},
    ) =>
      this.request<DmCrmBusinessPartnerUserLinksBusinessPartnerUserLinkDto, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/crm/business-partner-user-links/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BusinessPartnerUserLink
     * @name CrmBusinessPartnerUserLinksDelete
     * @request DELETE:/api/crm/business-partner-user-links/{id}
     * @secure
     */
    crmBusinessPartnerUserLinksDelete: (id: number, params: RequestParams = {}) =>
      this.request<void, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/crm/business-partner-user-links/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags BusinessPartnerUserLink
     * @name CrmBusinessPartnerUserLinksUpdateLinkCreate
     * @request POST:/api/crm/business-partner-user-links/update-link
     * @secure
     */
    crmBusinessPartnerUserLinksUpdateLinkCreate: (
      data: DmCrmBusinessPartnerUserLinksGetBusinessPartnerUserLinksInput,
      params: RequestParams = {},
    ) =>
      this.request<void, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/crm/business-partner-user-links/update-link`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags BusinessPartnerUserLink
     * @name CrmBusinessPartnerUserLinksCreateLinkCreate
     * @request POST:/api/crm/business-partner-user-links/create-link
     * @secure
     */
    crmBusinessPartnerUserLinksCreateLinkCreate: (
      data: DmCrmBusinessPartnerUserLinksGetBusinessPartnerUserLinksInput,
      params: RequestParams = {},
    ) =>
      this.request<void, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/crm/business-partner-user-links/create-link`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags BusinessPartnerUserLink
     * @name CrmBusinessPartnerUserLinksGetCustomerUsersCreate
     * @request POST:/api/crm/business-partner-user-links/get-customer-users
     * @secure
     */
    crmBusinessPartnerUserLinksGetCustomerUsersCreate: (
      query?: {
        /** @format int64 */
        businessPartnerId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<string[], VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/crm/business-partner-user-links/get-customer-users`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CancellationReason
     * @name CorbosServiceCancellationReasonsList
     * @request GET:/api/corbos-service/cancellation-reasons
     * @secure
     */
    corbosServiceCancellationReasonsList: (
      query?: {
        FilterText?: string;
        IsPickUp?: boolean;
        IsBeforStart?: string;
        Message?: string;
        Sorting?: string;
        /**
         * @format int32
         * @min 0
         * @max 2147483647
         */
        SkipCount?: number;
        /**
         * @format int32
         * @min 1
         * @max 2147483647
         */
        MaxResultCount?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        VoloAbpApplicationDtosPagedResultDto1WoraCorbosServiceCancellationReasonsCancellationReasonDtoWoraCorbosServiceApplicationContractsVersion1000CultureNeutralPublicKeyTokenNull,
        VoloAbpHttpRemoteServiceErrorResponse
      >({
        path: `/api/corbos-service/cancellation-reasons`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CancellationReason
     * @name CorbosServiceCancellationReasonsCreate
     * @request POST:/api/corbos-service/cancellation-reasons
     * @secure
     */
    corbosServiceCancellationReasonsCreate: (
      data: WoraCorbosServiceCancellationReasonsCancellationReasonCreateDto,
      params: RequestParams = {},
    ) =>
      this.request<WoraCorbosServiceCancellationReasonsCancellationReasonDto, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/cancellation-reasons`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CancellationReason
     * @name CorbosServiceCancellationReasonsDetail
     * @request GET:/api/corbos-service/cancellation-reasons/{id}
     * @secure
     */
    corbosServiceCancellationReasonsDetail: (id: string, params: RequestParams = {}) =>
      this.request<WoraCorbosServiceCancellationReasonsCancellationReasonDto, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/cancellation-reasons/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CancellationReason
     * @name CorbosServiceCancellationReasonsUpdate
     * @request PUT:/api/corbos-service/cancellation-reasons/{id}
     * @secure
     */
    corbosServiceCancellationReasonsUpdate: (
      id: string,
      data: WoraCorbosServiceCancellationReasonsCancellationReasonUpdateDto,
      params: RequestParams = {},
    ) =>
      this.request<WoraCorbosServiceCancellationReasonsCancellationReasonDto, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/cancellation-reasons/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CancellationReason
     * @name CorbosServiceCancellationReasonsDelete
     * @request DELETE:/api/corbos-service/cancellation-reasons/{id}
     * @secure
     */
    corbosServiceCancellationReasonsDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/cancellation-reasons/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CancellationReason
     * @name CorbosServiceCancellationReasonsAsExcelFileList
     * @request GET:/api/corbos-service/cancellation-reasons/as-excel-file
     * @secure
     */
    corbosServiceCancellationReasonsAsExcelFileList: (
      query?: {
        DownloadToken?: string;
        FilterText?: string;
        IsPickUp?: boolean;
        IsBeforStart?: string;
        Message?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<File, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/cancellation-reasons/as-excel-file`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CancellationReason
     * @name CorbosServiceCancellationReasonsDownloadTokenList
     * @request GET:/api/corbos-service/cancellation-reasons/download-token
     * @secure
     */
    corbosServiceCancellationReasonsDownloadTokenList: (params: RequestParams = {}) =>
      this.request<WoraCorbosServiceSharedDownloadTokenResultDto, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/cancellation-reasons/download-token`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Contact
     * @name CrmContactsCreate
     * @request POST:/api/crm/contacts
     * @secure
     */
    crmContactsCreate: (data: DmCrmContactsGetContactsInput, params: RequestParams = {}) =>
      this.request<
        VoloAbpApplicationDtosPagedResultDto1DmCrmContactsContactDtoDmCrmApplicationContractsVersion1030CultureNeutralPublicKeyTokenNull,
        VoloAbpHttpRemoteServiceErrorResponse
      >({
        path: `/api/crm/contacts`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Contact
     * @name CrmContactsDetail
     * @request GET:/api/crm/contacts/{id}
     * @secure
     */
    crmContactsDetail: (id: number, params: RequestParams = {}) =>
      this.request<DmCrmContactsContactDto, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/crm/contacts/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Contact
     * @name CrmContactsUpdate
     * @request PUT:/api/crm/contacts/{id}
     * @secure
     */
    crmContactsUpdate: (id: number, data: DmCrmContactsContactUpdateDto, params: RequestParams = {}) =>
      this.request<DmCrmContactsContactDto, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/crm/contacts/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Contact
     * @name CrmContactsDelete
     * @request DELETE:/api/crm/contacts/{id}
     * @secure
     */
    crmContactsDelete: (id: number, params: RequestParams = {}) =>
      this.request<void, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/crm/contacts/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Contact
     * @name CrmContactsContactTypeComboboxList
     * @request GET:/api/crm/contacts/contact-type-combobox
     * @secure
     */
    crmContactsContactTypeComboboxList: (params: RequestParams = {}) =>
      this.request<
        VoloAbpApplicationDtosListResultDto1DmApplicationComboxesComboboxItemIntDtoDmApplicationContractsVersion1160CultureNeutralPublicKeyTokenNull,
        VoloAbpHttpRemoteServiceErrorResponse
      >({
        path: `/api/crm/contacts/contact-type-combobox`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Contact
     * @name CrmContactsCreateCreate
     * @request POST:/api/crm/contacts/create
     * @secure
     */
    crmContactsCreateCreate: (data: DmCrmContactsContactCreateDto, params: RequestParams = {}) =>
      this.request<DmCrmContactsContactDto, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/crm/contacts/create`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Contact
     * @name CrmContactsComboboxItemsCreate
     * @request POST:/api/crm/contacts/combobox-items
     * @secure
     */
    crmContactsComboboxItemsCreate: (data: DmCrmContactsGetContactsInput, params: RequestParams = {}) =>
      this.request<
        VoloAbpApplicationDtosPagedResultDto1DmApplicationComboxesComboboxItemDtoDmApplicationContractsVersion1160CultureNeutralPublicKeyTokenNull,
        VoloAbpHttpRemoteServiceErrorResponse
      >({
        path: `/api/crm/contacts/combobox-items`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Contact
     * @name CrmContactsExcelCreate
     * @request POST:/api/crm/contacts/excel
     * @secure
     */
    crmContactsExcelCreate: (data: DmCrmContactsGetContactsInput, params: RequestParams = {}) =>
      this.request<File, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/crm/contacts/excel`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Contact
     * @name CrmContactsExcelBatchCreate
     * @request POST:/api/crm/contacts/excel-batch
     * @secure
     */
    crmContactsExcelBatchCreate: (data: DmCrmContactsGetContactsInput, params: RequestParams = {}) =>
      this.request<void, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/crm/contacts/excel-batch`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags DataFile
     * @name ImportManagementDataFilesList
     * @request GET:/api/import-management/data-files
     * @secure
     */
    importManagementDataFilesList: (
      query?: {
        FilterText?: string;
        DataFileType?: string;
        FileName?: string;
        FileType?: string;
        /** @format int32 */
        DimensionInByteMin?: number;
        /** @format int32 */
        DimensionInByteMax?: number;
        Sorting?: string;
        /**
         * @format int32
         * @min 0
         * @max 2147483647
         */
        SkipCount?: number;
        /**
         * @format int32
         * @min 1
         * @max 2147483647
         */
        MaxResultCount?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        VoloAbpApplicationDtosPagedResultDto1DmExcelImportManagementDataFilesDataFileDtoDmExcelImportManagementApplicationContractsVersion00440CultureNeutralPublicKeyTokenNull,
        VoloAbpHttpRemoteServiceErrorResponse
      >({
        path: `/api/import-management/data-files`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags DataFile
     * @name ImportManagementDataFilesCreate
     * @request POST:/api/import-management/data-files
     * @secure
     */
    importManagementDataFilesCreate: (
      data: DmExcelImportManagementDataFilesDataFileCreateDto,
      params: RequestParams = {},
    ) =>
      this.request<DmExcelImportManagementDataFilesDataFileDto, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/import-management/data-files`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags DataFile
     * @name ImportManagementDataFilesDetail
     * @request GET:/api/import-management/data-files/{id}
     * @secure
     */
    importManagementDataFilesDetail: (id: string, params: RequestParams = {}) =>
      this.request<DmExcelImportManagementDataFilesDataFileDto, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/import-management/data-files/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags DataFile
     * @name ImportManagementDataFilesUpdate
     * @request PUT:/api/import-management/data-files/{id}
     * @secure
     */
    importManagementDataFilesUpdate: (
      id: string,
      data: DmExcelImportManagementDataFilesDataFileUpdateDto,
      params: RequestParams = {},
    ) =>
      this.request<DmExcelImportManagementDataFilesDataFileDto, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/import-management/data-files/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags DataFile
     * @name ImportManagementDataFilesDelete
     * @request DELETE:/api/import-management/data-files/{id}
     * @secure
     */
    importManagementDataFilesDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/import-management/data-files/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Delivery
     * @name CorbosServiceDeliveriesComboboxList
     * @request GET:/api/corbos-service/deliveries/combobox
     * @secure
     */
    corbosServiceDeliveriesComboboxList: (
      query?: {
        FilterText?: string;
        /** @format int64 */
        ContactIdMin?: number;
        /** @format int64 */
        ContactIdMax?: number;
        /** @format date-time */
        StartTimeMin?: string;
        /** @format date-time */
        StartTimeMax?: string;
        /** @format date-time */
        EndTimeMin?: string;
        /** @format date-time */
        EndTimeMax?: string;
        /** @format int32 */
        TotSmallPackagesMin?: number;
        /** @format int32 */
        TotSmallPackagesMax?: number;
        /** @format int32 */
        TotMediumPackagesMin?: number;
        /** @format int32 */
        TotMediumPackagesMax?: number;
        /** @format int32 */
        TotLargePackagesMin?: number;
        /** @format int32 */
        TotLargePackagesMax?: number;
        ExternalOrderCode?: string;
        Description?: string;
        Note?: string;
        /** @format uuid */
        StoreId?: string;
        TokanId?: string;
        TeamId?: string;
        Status?: WoraCorbosServiceDeliveriesDeliveryStatus;
        DeliveryType?: WoraCorbosServiceDeliveriesDeliveryType;
        OrderType?: WoraCorbosServiceDeliveriesOrderType;
        /** @format uuid */
        PickupId?: string;
        /** @format uuid */
        DeliveryId?: string;
        RandomCode?: string;
        /** @format date-time */
        ExpireDate?: string;
        /** @format date-time */
        OrderDate?: string;
        OrderCode?: string;
        DeliveryExternalCode?: string;
        Customer?: string;
        DeliveryTypeName?: string;
        filtersBaseObject?: string;
        filtersPropertyName?: string;
        filtersField?: string;
        filtersIgnoreCase?: boolean;
        filtersOperator?: string;
        filtersValue?: any;
        filtersLogic?: string;
        filtersFilters?: DmFilterApplicationContractsFiltersTelerikFilterDto[];
        Maps?: DmFilterApplicationContractsFiltersFilterMapDto[];
        Sorting?: string;
        /**
         * @format int32
         * @min 0
         * @max 2147483647
         */
        SkipCount?: number;
        /**
         * @format int32
         * @min 1
         * @max 2147483647
         */
        MaxResultCount?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        VoloAbpApplicationDtosPagedResultDto1DmApplicationComboxesComboboxItemDtoDmApplicationContractsVersion1160CultureNeutralPublicKeyTokenNull,
        VoloAbpHttpRemoteServiceErrorResponse
      >({
        path: `/api/corbos-service/deliveries/combobox`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Delivery
     * @name CorbosServiceDeliveriesExcelList
     * @request GET:/api/corbos-service/deliveries/excel
     * @secure
     */
    corbosServiceDeliveriesExcelList: (
      query?: {
        FilterText?: string;
        /** @format int64 */
        ContactIdMin?: number;
        /** @format int64 */
        ContactIdMax?: number;
        /** @format date-time */
        StartTimeMin?: string;
        /** @format date-time */
        StartTimeMax?: string;
        /** @format date-time */
        EndTimeMin?: string;
        /** @format date-time */
        EndTimeMax?: string;
        /** @format int32 */
        TotSmallPackagesMin?: number;
        /** @format int32 */
        TotSmallPackagesMax?: number;
        /** @format int32 */
        TotMediumPackagesMin?: number;
        /** @format int32 */
        TotMediumPackagesMax?: number;
        /** @format int32 */
        TotLargePackagesMin?: number;
        /** @format int32 */
        TotLargePackagesMax?: number;
        ExternalOrderCode?: string;
        Description?: string;
        Note?: string;
        /** @format uuid */
        StoreId?: string;
        TokanId?: string;
        TeamId?: string;
        Status?: WoraCorbosServiceDeliveriesDeliveryStatus;
        DeliveryType?: WoraCorbosServiceDeliveriesDeliveryType;
        OrderType?: WoraCorbosServiceDeliveriesOrderType;
        /** @format uuid */
        PickupId?: string;
        /** @format uuid */
        DeliveryId?: string;
        RandomCode?: string;
        /** @format date-time */
        ExpireDate?: string;
        /** @format date-time */
        OrderDate?: string;
        OrderCode?: string;
        DeliveryExternalCode?: string;
        Customer?: string;
        DeliveryTypeName?: string;
        filtersBaseObject?: string;
        filtersPropertyName?: string;
        filtersField?: string;
        filtersIgnoreCase?: boolean;
        filtersOperator?: string;
        filtersValue?: any;
        filtersLogic?: string;
        filtersFilters?: DmFilterApplicationContractsFiltersTelerikFilterDto[];
        Maps?: DmFilterApplicationContractsFiltersFilterMapDto[];
        Sorting?: string;
        /**
         * @format int32
         * @min 0
         * @max 2147483647
         */
        SkipCount?: number;
        /**
         * @format int32
         * @min 1
         * @max 2147483647
         */
        MaxResultCount?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<File, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/deliveries/excel`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Delivery
     * @name CorbosServiceDeliveriesBatchExcelList
     * @request GET:/api/corbos-service/deliveries/batch-excel
     * @secure
     */
    corbosServiceDeliveriesBatchExcelList: (
      query?: {
        FilterText?: string;
        /** @format int64 */
        ContactIdMin?: number;
        /** @format int64 */
        ContactIdMax?: number;
        /** @format date-time */
        StartTimeMin?: string;
        /** @format date-time */
        StartTimeMax?: string;
        /** @format date-time */
        EndTimeMin?: string;
        /** @format date-time */
        EndTimeMax?: string;
        /** @format int32 */
        TotSmallPackagesMin?: number;
        /** @format int32 */
        TotSmallPackagesMax?: number;
        /** @format int32 */
        TotMediumPackagesMin?: number;
        /** @format int32 */
        TotMediumPackagesMax?: number;
        /** @format int32 */
        TotLargePackagesMin?: number;
        /** @format int32 */
        TotLargePackagesMax?: number;
        ExternalOrderCode?: string;
        Description?: string;
        Note?: string;
        /** @format uuid */
        StoreId?: string;
        TokanId?: string;
        TeamId?: string;
        Status?: WoraCorbosServiceDeliveriesDeliveryStatus;
        DeliveryType?: WoraCorbosServiceDeliveriesDeliveryType;
        OrderType?: WoraCorbosServiceDeliveriesOrderType;
        /** @format uuid */
        PickupId?: string;
        /** @format uuid */
        DeliveryId?: string;
        RandomCode?: string;
        /** @format date-time */
        ExpireDate?: string;
        /** @format date-time */
        OrderDate?: string;
        OrderCode?: string;
        DeliveryExternalCode?: string;
        Customer?: string;
        DeliveryTypeName?: string;
        filtersBaseObject?: string;
        filtersPropertyName?: string;
        filtersField?: string;
        filtersIgnoreCase?: boolean;
        filtersOperator?: string;
        filtersValue?: any;
        filtersLogic?: string;
        filtersFilters?: DmFilterApplicationContractsFiltersTelerikFilterDto[];
        Maps?: DmFilterApplicationContractsFiltersFilterMapDto[];
        Sorting?: string;
        /**
         * @format int32
         * @min 0
         * @max 2147483647
         */
        SkipCount?: number;
        /**
         * @format int32
         * @min 1
         * @max 2147483647
         */
        MaxResultCount?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/deliveries/batch-excel`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Delivery
     * @name CorbosServiceDeliveriesCreate
     * @request POST:/api/corbos-service/deliveries
     * @secure
     */
    corbosServiceDeliveriesCreate: (data: WoraCorbosServiceDeliveriesGetDeliveriesInput, params: RequestParams = {}) =>
      this.request<
        VoloAbpApplicationDtosPagedResultDto1WoraCorbosServiceDeliveriesDeliveryDtoWoraCorbosServiceApplicationContractsVersion1000CultureNeutralPublicKeyTokenNull,
        VoloAbpHttpRemoteServiceErrorResponse
      >({
        path: `/api/corbos-service/deliveries`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Delivery
     * @name CorbosServiceDeliveriesDetail
     * @request GET:/api/corbos-service/deliveries/{id}
     * @secure
     */
    corbosServiceDeliveriesDetail: (id: string, params: RequestParams = {}) =>
      this.request<WoraCorbosServiceDeliveriesDeliveryDto, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/deliveries/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Delivery
     * @name CorbosServiceDeliveriesUpdate
     * @request PUT:/api/corbos-service/deliveries/{id}
     * @secure
     */
    corbosServiceDeliveriesUpdate: (
      id: string,
      data: WoraCorbosServiceDeliveriesDeliveryUpdateDto,
      params: RequestParams = {},
    ) =>
      this.request<WoraCorbosServiceDeliveriesDeliveryDto, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/deliveries/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Delivery
     * @name CorbosServiceDeliveriesDelete
     * @request DELETE:/api/corbos-service/deliveries/{id}
     * @secure
     */
    corbosServiceDeliveriesDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/deliveries/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Delivery
     * @name CorbosServiceDeliveriesCreateCreate
     * @request POST:/api/corbos-service/deliveries/create
     * @secure
     */
    corbosServiceDeliveriesCreateCreate: (
      data: WoraCorbosServiceDeliveriesDeliveryCreateDto,
      params: RequestParams = {},
    ) =>
      this.request<WoraCorbosServiceDeliveriesDeliveryDto, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/deliveries/create`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Delivery
     * @name CorbosServiceDeliveriesAsExcelFileList
     * @request GET:/api/corbos-service/deliveries/as-excel-file
     * @secure
     */
    corbosServiceDeliveriesAsExcelFileList: (
      query?: {
        DownloadToken?: string;
        FilterText?: string;
        /** @format int64 */
        ContactIdMin?: number;
        /** @format int64 */
        ContactIdMax?: number;
        /** @format date-time */
        StartTimeMin?: string;
        /** @format date-time */
        StartTimeMax?: string;
        /** @format date-time */
        EndTimeMin?: string;
        /** @format date-time */
        EndTimeMax?: string;
        /** @format int32 */
        TotSmallPackagesMin?: number;
        /** @format int32 */
        TotSmallPackagesMax?: number;
        /** @format int32 */
        TotMediumPackagesMin?: number;
        /** @format int32 */
        TotMediumPackagesMax?: number;
        /** @format int32 */
        TotLargePackagesMin?: number;
        /** @format int32 */
        TotLargePackagesMax?: number;
        ExternalOrderCode?: string;
        Description?: string;
        Note?: string;
        TokanId?: string;
        TeamId?: string;
        /** @format uuid */
        StoreId?: string;
        Status?: WoraCorbosServiceDeliveriesDeliveryStatus;
        DeliveryType?: WoraCorbosServiceDeliveriesDeliveryType;
        OrderType?: WoraCorbosServiceDeliveriesOrderType;
        /** @format date-time */
        OrderDate?: string;
        OrderCode?: string;
        RandomCode?: string;
        /** @format date-time */
        ExpireDate?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<File, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/deliveries/as-excel-file`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Delivery
     * @name CorbosServiceDeliveriesDownloadTokenList
     * @request GET:/api/corbos-service/deliveries/download-token
     * @secure
     */
    corbosServiceDeliveriesDownloadTokenList: (params: RequestParams = {}) =>
      this.request<WoraCorbosServiceSharedDownloadTokenResultDto, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/deliveries/download-token`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Delivery
     * @name CorbosServiceDeliveriesSendDeliveryCreate
     * @request POST:/api/corbos-service/deliveries/send-delivery
     * @secure
     */
    corbosServiceDeliveriesSendDeliveryCreate: (
      data: WoraCorbosServiceDeliveriesSendDeliveryInput,
      params: RequestParams = {},
    ) =>
      this.request<void, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/deliveries/send-delivery`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Delivery
     * @name CorbosServiceDeliveriesGetContactsComboboxList
     * @request GET:/api/corbos-service/deliveries/get-contacts-combobox
     * @secure
     */
    corbosServiceDeliveriesGetContactsComboboxList: (
      query?: {
        /** @format int64 */
        parentId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        VoloAbpApplicationDtosListResultDto1DmApplicationComboxesComboboxItemLongDtoDmApplicationContractsVersion1160CultureNeutralPublicKeyTokenNull,
        VoloAbpHttpRemoteServiceErrorResponse
      >({
        path: `/api/corbos-service/deliveries/get-contacts-combobox`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Delivery
     * @name CorbosServiceDeliveriesGetDeliveryTypeList
     * @request GET:/api/corbos-service/deliveries/get-delivery-type
     * @secure
     */
    corbosServiceDeliveriesGetDeliveryTypeList: (params: RequestParams = {}) =>
      this.request<
        VoloAbpApplicationDtosListResultDto1DmApplicationComboxesComboboxItemIntDtoDmApplicationContractsVersion1160CultureNeutralPublicKeyTokenNull,
        VoloAbpHttpRemoteServiceErrorResponse
      >({
        path: `/api/corbos-service/deliveries/get-delivery-type`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Delivery
     * @name CorbosServiceDeliveriesGetDeliveryStatusList
     * @request GET:/api/corbos-service/deliveries/get-delivery-status
     * @secure
     */
    corbosServiceDeliveriesGetDeliveryStatusList: (params: RequestParams = {}) =>
      this.request<
        VoloAbpApplicationDtosListResultDto1DmApplicationComboxesComboboxItemIntDtoDmApplicationContractsVersion1160CultureNeutralPublicKeyTokenNull,
        VoloAbpHttpRemoteServiceErrorResponse
      >({
        path: `/api/corbos-service/deliveries/get-delivery-status`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Delivery
     * @name CorbosServiceDeliveriesGetOrderTypeList
     * @request GET:/api/corbos-service/deliveries/get-order-type
     * @secure
     */
    corbosServiceDeliveriesGetOrderTypeList: (params: RequestParams = {}) =>
      this.request<
        VoloAbpApplicationDtosListResultDto1DmApplicationComboxesComboboxItemIntDtoDmApplicationContractsVersion1160CultureNeutralPublicKeyTokenNull,
        VoloAbpHttpRemoteServiceErrorResponse
      >({
        path: `/api/corbos-service/deliveries/get-order-type`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Delivery
     * @name CorbosServiceDeliveriesCancelOrderList
     * @request GET:/api/corbos-service/deliveries/cancel-order
     * @secure
     */
    corbosServiceDeliveriesCancelOrderList: (
      query?: {
        /** @format uuid */
        id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/deliveries/cancel-order`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Delivery
     * @name CorbosServiceDeliveriesCreateOrEditCreate
     * @request POST:/api/corbos-service/deliveries/create-or-edit
     * @secure
     */
    corbosServiceDeliveriesCreateOrEditCreate: (
      data: WoraCorbosServiceDeliveriesDeliveryCreateShopPortal,
      params: RequestParams = {},
    ) =>
      this.request<WoraCorbosServiceDeliveriesDeliveryDto, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/deliveries/create-or-edit`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Delivery
     * @name CorbosServiceDeliveriesGetExcelCreate
     * @request POST:/api/corbos-service/deliveries/get-excel
     * @secure
     */
    corbosServiceDeliveriesGetExcelCreate: (
      data: WoraCorbosServiceDeliveriesGetDeliveriesInput,
      params: RequestParams = {},
    ) =>
      this.request<File, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/deliveries/get-excel`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Delivery
     * @name CorbosServiceDeliveriesRetriggerCreate
     * @request POST:/api/corbos-service/deliveries/retrigger
     * @secure
     */
    corbosServiceDeliveriesRetriggerCreate: (params: RequestParams = {}) =>
      this.request<void, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/deliveries/retrigger`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Delivery
     * @name CorbosServiceDeliveriesGetDataList
     * @request GET:/api/corbos-service/deliveries/get-data
     * @secure
     */
    corbosServiceDeliveriesGetDataList: (
      query?: {
        /** @format uuid */
        deliveryId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<WoraCorbosServiceDeliveriesDeliveryDto, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/deliveries/get-data`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Delivery
     * @name CorbosServiceDeliveriesRegeolocalizeCreate
     * @request POST:/api/corbos-service/deliveries/regeolocalize
     * @secure
     */
    corbosServiceDeliveriesRegeolocalizeCreate: (
      data: WoraCorbosServiceDeliveriesRegeolocalizeInput,
      params: RequestParams = {},
    ) =>
      this.request<void, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/deliveries/regeolocalize`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeliveryHistory
     * @name CorbosServiceDeliveryHistoriesComboboxItemsCreate
     * @request POST:/api/corbos-service/delivery-histories/combobox-items
     * @secure
     */
    corbosServiceDeliveryHistoriesComboboxItemsCreate: (
      data: WoraCorbosServiceDeliveryHistoriesGetDeliveryHistoriesInput,
      params: RequestParams = {},
    ) =>
      this.request<
        VoloAbpApplicationDtosPagedResultDto1DmApplicationComboxesComboboxItemDtoDmApplicationContractsVersion1160CultureNeutralPublicKeyTokenNull,
        VoloAbpHttpRemoteServiceErrorResponse
      >({
        path: `/api/corbos-service/delivery-histories/combobox-items`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeliveryHistory
     * @name CorbosServiceDeliveryHistoriesExcelCreate
     * @request POST:/api/corbos-service/delivery-histories/excel
     * @secure
     */
    corbosServiceDeliveryHistoriesExcelCreate: (
      data: WoraCorbosServiceDeliveryHistoriesGetDeliveryHistoriesInput,
      params: RequestParams = {},
    ) =>
      this.request<File, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/delivery-histories/excel`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeliveryHistory
     * @name CorbosServiceDeliveryHistoriesExcelBatchCreate
     * @request POST:/api/corbos-service/delivery-histories/excel-batch
     * @secure
     */
    corbosServiceDeliveryHistoriesExcelBatchCreate: (
      data: WoraCorbosServiceDeliveryHistoriesGetDeliveryHistoriesInput,
      params: RequestParams = {},
    ) =>
      this.request<void, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/delivery-histories/excel-batch`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeliveryHistory
     * @name CorbosServiceDeliveryHistoriesCreate
     * @request POST:/api/corbos-service/delivery-histories
     * @secure
     */
    corbosServiceDeliveryHistoriesCreate: (
      data: WoraCorbosServiceDeliveryHistoriesGetDeliveryHistoriesInput,
      params: RequestParams = {},
    ) =>
      this.request<
        VoloAbpApplicationDtosPagedResultDto1WoraCorbosServiceDeliveryHistoriesDeliveryHistoryDtoWoraCorbosServiceApplicationContractsVersion1000CultureNeutralPublicKeyTokenNull,
        VoloAbpHttpRemoteServiceErrorResponse
      >({
        path: `/api/corbos-service/delivery-histories`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeliveryHistory
     * @name CorbosServiceDeliveryHistoriesDetail
     * @request GET:/api/corbos-service/delivery-histories/{id}
     * @secure
     */
    corbosServiceDeliveryHistoriesDetail: (id: string, params: RequestParams = {}) =>
      this.request<WoraCorbosServiceDeliveryHistoriesDeliveryHistoryDto, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/delivery-histories/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeliveryHistory
     * @name CorbosServiceDeliveryHistoriesUpdate
     * @request PUT:/api/corbos-service/delivery-histories/{id}
     * @secure
     */
    corbosServiceDeliveryHistoriesUpdate: (
      id: string,
      data: WoraCorbosServiceDeliveryHistoriesDeliveryHistoryUpdateDto,
      params: RequestParams = {},
    ) =>
      this.request<WoraCorbosServiceDeliveryHistoriesDeliveryHistoryDto, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/delivery-histories/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeliveryHistory
     * @name CorbosServiceDeliveryHistoriesDelete
     * @request DELETE:/api/corbos-service/delivery-histories/{id}
     * @secure
     */
    corbosServiceDeliveryHistoriesDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/delivery-histories/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeliveryHistory
     * @name CorbosServiceDeliveryHistoriesCreateCreate
     * @request POST:/api/corbos-service/delivery-histories/create
     * @secure
     */
    corbosServiceDeliveryHistoriesCreateCreate: (
      data: WoraCorbosServiceDeliveryHistoriesDeliveryHistoryCreateDto,
      params: RequestParams = {},
    ) =>
      this.request<WoraCorbosServiceDeliveryHistoriesDeliveryHistoryDto, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/delivery-histories/create`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeliveryHistory
     * @name CorbosServiceDeliveryHistoriesAsExcelFileList
     * @request GET:/api/corbos-service/delivery-histories/as-excel-file
     * @secure
     */
    corbosServiceDeliveryHistoriesAsExcelFileList: (
      query?: {
        DownloadToken?: string;
        FilterText?: string;
        /** @format uuid */
        DeliveryId?: string;
        /** @format uuid */
        StepId?: string;
        DeliveryCode?: string;
        StepCode?: string;
        StepStatus?: WoraCorbosServiceDeliveryStepsStepStatus;
        StepType?: string;
        EventType?: string;
        Note?: string;
        /** @format double */
        Lat?: number;
        /** @format double */
        Lng?: number;
        UserName?: string;
        /** @format uuid */
        HistoryEventId?: string;
        OrderStatus?: WoraCorbosServiceDeliveriesDeliveryStatus;
      },
      params: RequestParams = {},
    ) =>
      this.request<File, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/delivery-histories/as-excel-file`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeliveryHistory
     * @name CorbosServiceDeliveryHistoriesDownloadTokenList
     * @request GET:/api/corbos-service/delivery-histories/download-token
     * @secure
     */
    corbosServiceDeliveryHistoriesDownloadTokenList: (params: RequestParams = {}) =>
      this.request<WoraCorbosServiceSharedDownloadTokenResultDto, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/delivery-histories/download-token`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeliveryStep
     * @name CorbosServiceDeliveryStepsComboboxList
     * @request GET:/api/corbos-service/delivery-steps/combobox
     * @secure
     */
    corbosServiceDeliveryStepsComboboxList: (
      query?: {
        /** @format uuid */
        DeliveryId?: string;
        FilterText?: string;
        StepType?: WoraCorbosServiceDeliveryStepsStepType;
        /** @format uuid */
        DriverId?: string;
        Status?: WoraCorbosServiceDeliveryStepsStepStatus;
        Address?: string;
        /** @format double */
        LatMin?: number;
        /** @format double */
        LatMax?: number;
        /** @format double */
        LngMin?: number;
        /** @format double */
        LngMax?: number;
        /** @format date-time */
        RequestedMin?: string;
        /** @format date-time */
        RequestedMax?: string;
        /** @format date-time */
        RealMin?: string;
        /** @format date-time */
        RealMax?: string;
        /** @format date-time */
        TaskEtaMin?: string;
        /** @format date-time */
        TaskEtaMax?: string;
        /** @format date-time */
        StartEtaWindow?: string;
        /** @format date-time */
        EndEtaWindow?: string;
        /** @format date-time */
        StepDate?: string;
        Note?: string;
        /** @format uuid */
        RelatedId?: string;
        IsRoute?: boolean;
        /** @format uuid */
        ExecutionDetailId?: string;
        /** @format int64 */
        ContactId?: number;
        /** @format uuid */
        ExecutionId?: string;
        /** @format uuid */
        ScenarioId?: string;
        ClusterKey?: string;
        ForcedDriver?: boolean;
        StepCode?: string;
        ContactName?: string;
        ContactPhone?: string;
        ContactAddress?: string;
        ContactEmail?: string;
        Ids?: string[];
        filtersBaseObject?: string;
        filtersPropertyName?: string;
        filtersField?: string;
        filtersIgnoreCase?: boolean;
        filtersOperator?: string;
        filtersValue?: any;
        filtersLogic?: string;
        filtersFilters?: DmFilterApplicationContractsFiltersTelerikFilterDto[];
        Maps?: DmFilterApplicationContractsFiltersFilterMapDto[];
        Sorting?: string;
        /**
         * @format int32
         * @min 0
         * @max 2147483647
         */
        SkipCount?: number;
        /**
         * @format int32
         * @min 1
         * @max 2147483647
         */
        MaxResultCount?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        VoloAbpApplicationDtosPagedResultDto1DmApplicationComboxesComboboxItemDtoDmApplicationContractsVersion1160CultureNeutralPublicKeyTokenNull,
        VoloAbpHttpRemoteServiceErrorResponse
      >({
        path: `/api/corbos-service/delivery-steps/combobox`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeliveryStep
     * @name CorbosServiceDeliveryStepsExcelList
     * @request GET:/api/corbos-service/delivery-steps/excel
     * @secure
     */
    corbosServiceDeliveryStepsExcelList: (
      query?: {
        /** @format uuid */
        DeliveryId?: string;
        FilterText?: string;
        StepType?: WoraCorbosServiceDeliveryStepsStepType;
        /** @format uuid */
        DriverId?: string;
        Status?: WoraCorbosServiceDeliveryStepsStepStatus;
        Address?: string;
        /** @format double */
        LatMin?: number;
        /** @format double */
        LatMax?: number;
        /** @format double */
        LngMin?: number;
        /** @format double */
        LngMax?: number;
        /** @format date-time */
        RequestedMin?: string;
        /** @format date-time */
        RequestedMax?: string;
        /** @format date-time */
        RealMin?: string;
        /** @format date-time */
        RealMax?: string;
        /** @format date-time */
        TaskEtaMin?: string;
        /** @format date-time */
        TaskEtaMax?: string;
        /** @format date-time */
        StartEtaWindow?: string;
        /** @format date-time */
        EndEtaWindow?: string;
        /** @format date-time */
        StepDate?: string;
        Note?: string;
        /** @format uuid */
        RelatedId?: string;
        IsRoute?: boolean;
        /** @format uuid */
        ExecutionDetailId?: string;
        /** @format int64 */
        ContactId?: number;
        /** @format uuid */
        ExecutionId?: string;
        /** @format uuid */
        ScenarioId?: string;
        ClusterKey?: string;
        ForcedDriver?: boolean;
        StepCode?: string;
        ContactName?: string;
        ContactPhone?: string;
        ContactAddress?: string;
        ContactEmail?: string;
        Ids?: string[];
        filtersBaseObject?: string;
        filtersPropertyName?: string;
        filtersField?: string;
        filtersIgnoreCase?: boolean;
        filtersOperator?: string;
        filtersValue?: any;
        filtersLogic?: string;
        filtersFilters?: DmFilterApplicationContractsFiltersTelerikFilterDto[];
        Maps?: DmFilterApplicationContractsFiltersFilterMapDto[];
        Sorting?: string;
        /**
         * @format int32
         * @min 0
         * @max 2147483647
         */
        SkipCount?: number;
        /**
         * @format int32
         * @min 1
         * @max 2147483647
         */
        MaxResultCount?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<File, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/delivery-steps/excel`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeliveryStep
     * @name CorbosServiceDeliveryStepsBatchExcelList
     * @request GET:/api/corbos-service/delivery-steps/batch-excel
     * @secure
     */
    corbosServiceDeliveryStepsBatchExcelList: (
      query?: {
        /** @format uuid */
        DeliveryId?: string;
        FilterText?: string;
        StepType?: WoraCorbosServiceDeliveryStepsStepType;
        /** @format uuid */
        DriverId?: string;
        Status?: WoraCorbosServiceDeliveryStepsStepStatus;
        Address?: string;
        /** @format double */
        LatMin?: number;
        /** @format double */
        LatMax?: number;
        /** @format double */
        LngMin?: number;
        /** @format double */
        LngMax?: number;
        /** @format date-time */
        RequestedMin?: string;
        /** @format date-time */
        RequestedMax?: string;
        /** @format date-time */
        RealMin?: string;
        /** @format date-time */
        RealMax?: string;
        /** @format date-time */
        TaskEtaMin?: string;
        /** @format date-time */
        TaskEtaMax?: string;
        /** @format date-time */
        StartEtaWindow?: string;
        /** @format date-time */
        EndEtaWindow?: string;
        /** @format date-time */
        StepDate?: string;
        Note?: string;
        /** @format uuid */
        RelatedId?: string;
        IsRoute?: boolean;
        /** @format uuid */
        ExecutionDetailId?: string;
        /** @format int64 */
        ContactId?: number;
        /** @format uuid */
        ExecutionId?: string;
        /** @format uuid */
        ScenarioId?: string;
        ClusterKey?: string;
        ForcedDriver?: boolean;
        StepCode?: string;
        ContactName?: string;
        ContactPhone?: string;
        ContactAddress?: string;
        ContactEmail?: string;
        Ids?: string[];
        filtersBaseObject?: string;
        filtersPropertyName?: string;
        filtersField?: string;
        filtersIgnoreCase?: boolean;
        filtersOperator?: string;
        filtersValue?: any;
        filtersLogic?: string;
        filtersFilters?: DmFilterApplicationContractsFiltersTelerikFilterDto[];
        Maps?: DmFilterApplicationContractsFiltersFilterMapDto[];
        Sorting?: string;
        /**
         * @format int32
         * @min 0
         * @max 2147483647
         */
        SkipCount?: number;
        /**
         * @format int32
         * @min 1
         * @max 2147483647
         */
        MaxResultCount?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/delivery-steps/batch-excel`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeliveryStep
     * @name CorbosServiceDeliveryStepsCreate
     * @request POST:/api/corbos-service/delivery-steps
     * @secure
     */
    corbosServiceDeliveryStepsCreate: (
      data: WoraCorbosServiceDeliveryStepsGetDeliveryStepsInput,
      params: RequestParams = {},
    ) =>
      this.request<
        VoloAbpApplicationDtosPagedResultDto1WoraCorbosServiceDeliveryStepsDeliveryStepDtoWoraCorbosServiceApplicationContractsVersion1000CultureNeutralPublicKeyTokenNull,
        VoloAbpHttpRemoteServiceErrorResponse
      >({
        path: `/api/corbos-service/delivery-steps`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeliveryStep
     * @name CorbosServiceDeliveryStepsDetail
     * @request GET:/api/corbos-service/delivery-steps/{id}
     * @secure
     */
    corbosServiceDeliveryStepsDetail: (id: string, params: RequestParams = {}) =>
      this.request<WoraCorbosServiceDeliveryStepsDeliveryStepDto, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/delivery-steps/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeliveryStep
     * @name CorbosServiceDeliveryStepsUpdate
     * @request PUT:/api/corbos-service/delivery-steps/{id}
     * @secure
     */
    corbosServiceDeliveryStepsUpdate: (
      id: string,
      data: WoraCorbosServiceDeliveryStepsDeliveryStepUpdateDto,
      params: RequestParams = {},
    ) =>
      this.request<WoraCorbosServiceDeliveryStepsDeliveryStepDto, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/delivery-steps/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeliveryStep
     * @name CorbosServiceDeliveryStepsDelete
     * @request DELETE:/api/corbos-service/delivery-steps/{id}
     * @secure
     */
    corbosServiceDeliveryStepsDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/delivery-steps/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeliveryStep
     * @name CorbosServiceDeliveryStepsCreateCreate
     * @request POST:/api/corbos-service/delivery-steps/create
     * @secure
     */
    corbosServiceDeliveryStepsCreateCreate: (
      data: WoraCorbosServiceDeliveryStepsDeliveryStepCreateDto,
      params: RequestParams = {},
    ) =>
      this.request<WoraCorbosServiceDeliveryStepsDeliveryStepDto, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/delivery-steps/create`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeliveryStep
     * @name CorbosServiceDeliveryStepsAsExcelFileList
     * @request GET:/api/corbos-service/delivery-steps/as-excel-file
     * @secure
     */
    corbosServiceDeliveryStepsAsExcelFileList: (
      query?: {
        DownloadToken?: string;
        FilterText?: string;
        /** @format uuid */
        DeliveryId?: string;
        StepType?: WoraCorbosServiceDeliveryStepsStepType;
        /** @format uuid */
        DriverId?: string;
        Status?: WoraCorbosServiceDeliveryStepsStepStatus;
        Address?: string;
        /** @format double */
        LatMin?: number;
        /** @format double */
        LatMax?: number;
        /** @format double */
        LngMin?: number;
        /** @format double */
        LngMax?: number;
        /** @format date-time */
        RequestedMin?: string;
        /** @format date-time */
        RequestedMax?: string;
        /** @format date-time */
        RealMin?: string;
        /** @format date-time */
        RealMax?: string;
        /** @format date-time */
        TaskEtaMin?: string;
        /** @format date-time */
        TaskEtaMax?: string;
        Note?: string;
        /** @format int64 */
        ContactId?: number;
        /** @format uuid */
        RelatedId?: string;
        /** @format uuid */
        ExecutionId?: string;
        /** @format uuid */
        ScenarioId?: string;
        ClusterKey?: string;
        ForcedDriver?: boolean;
        StepCode?: string;
        ContactName?: string;
        ContactPhone?: string;
        ContactAddress?: string;
        ContactEmail?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<File, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/delivery-steps/as-excel-file`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeliveryStep
     * @name CorbosServiceDeliveryStepsDownloadTokenList
     * @request GET:/api/corbos-service/delivery-steps/download-token
     * @secure
     */
    corbosServiceDeliveryStepsDownloadTokenList: (params: RequestParams = {}) =>
      this.request<WoraCorbosServiceSharedDownloadTokenResultDto, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/delivery-steps/download-token`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeliveryStep
     * @name CorbosServiceDeliveryStepsStepTypeComboboxList
     * @request GET:/api/corbos-service/delivery-steps/step-type-combobox
     * @secure
     */
    corbosServiceDeliveryStepsStepTypeComboboxList: (params: RequestParams = {}) =>
      this.request<
        VoloAbpApplicationDtosListResultDto1DmApplicationComboxesComboboxItemIntDtoDmApplicationContractsVersion1160CultureNeutralPublicKeyTokenNull,
        VoloAbpHttpRemoteServiceErrorResponse
      >({
        path: `/api/corbos-service/delivery-steps/step-type-combobox`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeliveryStep
     * @name CorbosServiceDeliveryStepsStepStatusComboboxList
     * @request GET:/api/corbos-service/delivery-steps/step-status-combobox
     * @secure
     */
    corbosServiceDeliveryStepsStepStatusComboboxList: (params: RequestParams = {}) =>
      this.request<
        VoloAbpApplicationDtosListResultDto1DmApplicationComboxesComboboxItemIntDtoDmApplicationContractsVersion1160CultureNeutralPublicKeyTokenNull,
        VoloAbpHttpRemoteServiceErrorResponse
      >({
        path: `/api/corbos-service/delivery-steps/step-status-combobox`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeliveryStep
     * @name CorbosServiceDeliveryStepsGetByDeliveryIdsCreate
     * @request POST:/api/corbos-service/delivery-steps/get-by-delivery-ids
     * @secure
     */
    corbosServiceDeliveryStepsGetByDeliveryIdsCreate: (
      data: WoraCorbosServiceDeliveriesGetDeliveryStepsByDeliveryInput,
      params: RequestParams = {},
    ) =>
      this.request<WoraCorbosServiceDeliveryStepsDeliveryStepDto[], VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/delivery-steps/get-by-delivery-ids`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeliveryStep
     * @name CorbosServiceDeliveryStepsGetRouteTaskList
     * @request GET:/api/corbos-service/delivery-steps/get-route-task
     * @secure
     */
    corbosServiceDeliveryStepsGetRouteTaskList: (
      query?: {
        /** @format uuid */
        executionId?: string;
        /** @format uuid */
        driverId?: string;
        /** @default true */
        excludeCompleted?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<WoraCorbosServiceDeliveryStepsDeliveryStepDataDto[], VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/delivery-steps/get-route-task`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeliveryStep
     * @name CorbosServiceDeliveryStepsAssignDeliveryToDriverCreate
     * @request POST:/api/corbos-service/delivery-steps/assign-delivery-to-driver
     * @secure
     */
    corbosServiceDeliveryStepsAssignDeliveryToDriverCreate: (
      data: WoraCorbosServiceDeliveryStepsAssignDeliveryDto,
      params: RequestParams = {},
    ) =>
      this.request<void, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/delivery-steps/assign-delivery-to-driver`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeliveryStep
     * @name CorbosServiceDeliveryStepsDeliveriesByIdsCreate
     * @request POST:/api/corbos-service/delivery-steps/deliveries-by-ids
     * @secure
     */
    corbosServiceDeliveryStepsDeliveriesByIdsCreate: (data: string[], params: RequestParams = {}) =>
      this.request<WoraCorbosServiceDeliveryStepsDeliveryStepDto[], VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/delivery-steps/deliveries-by-ids`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeliveryStep
     * @name CorbosServiceDeliveryStepsUpdateStepStatusCreate
     * @request POST:/api/corbos-service/delivery-steps/update-step-status
     * @secure
     */
    corbosServiceDeliveryStepsUpdateStepStatusCreate: (
      data: WoraCorbosServiceDeliveryStepsDeliveryStepUpdateStatusDto,
      params: RequestParams = {},
    ) =>
      this.request<void, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/delivery-steps/update-step-status`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeliveryStep
     * @name CorbosServiceDeliveryStepsCompleteStepCreate
     * @request POST:/api/corbos-service/delivery-steps/complete-step
     * @secure
     */
    corbosServiceDeliveryStepsCompleteStepCreate: (
      data: WoraCorbosServiceDeliveryStepsCompleteStepDto,
      params: RequestParams = {},
    ) =>
      this.request<boolean, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/delivery-steps/complete-step`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeliveryStep
     * @name CorbosServiceDeliveryStepsParseRouteTaskCreate
     * @request POST:/api/corbos-service/delivery-steps/parse-route-task
     * @secure
     */
    corbosServiceDeliveryStepsParseRouteTaskCreate: (
      query?: {
        /** @format uuid */
        executionDetailId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<WoraCorbosServiceDeliveryStepsDeliveryStepDto[], VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/delivery-steps/parse-route-task`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeliveryStep
     * @name CorbosServiceDeliveryStepsUploadImageCreate
     * @request POST:/api/corbos-service/delivery-steps/upload-image
     * @secure
     */
    corbosServiceDeliveryStepsUploadImageCreate: (
      data: WoraCorbosServiceDeliveryStepsUploadImageInput,
      params: RequestParams = {},
    ) =>
      this.request<string, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/delivery-steps/upload-image`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeliveryStep
     * @name CorbosServiceDeliveryStepsDownloadImageList
     * @request GET:/api/corbos-service/delivery-steps/download-image
     * @secure
     */
    corbosServiceDeliveryStepsDownloadImageList: (
      query?: {
        /** @format uuid */
        id?: string;
        token?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<File, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/delivery-steps/download-image`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeliveryStep
     * @name CorbosServiceDeliveryStepsDownloadImageTokenList
     * @request GET:/api/corbos-service/delivery-steps/download-image-token
     * @secure
     */
    corbosServiceDeliveryStepsDownloadImageTokenList: (
      query?: {
        /** @format uuid */
        id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/delivery-steps/download-image-token`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeliveryStep
     * @name CorbosServiceDeliveryStepsGetGalleryList
     * @request GET:/api/corbos-service/delivery-steps/get-gallery
     * @secure
     */
    corbosServiceDeliveryStepsGetGalleryList: (
      query?: {
        /** @format uuid */
        stepId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string[], VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/delivery-steps/get-gallery`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeliveryStep
     * @name CorbosServiceDeliveryStepsGetDeliveryGalleryList
     * @request GET:/api/corbos-service/delivery-steps/get-delivery-gallery
     * @secure
     */
    corbosServiceDeliveryStepsGetDeliveryGalleryList: (
      query?: {
        /** @format uuid */
        deliveryId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string[], VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/delivery-steps/get-delivery-gallery`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeliveryStep
     * @name CorbosServiceDeliveryStepsDriverActualRouteCreate
     * @request POST:/api/corbos-service/delivery-steps/driver-actual-route
     * @secure
     */
    corbosServiceDeliveryStepsDriverActualRouteCreate: (
      data: WoraCorbosServiceDeliveryStepsGetDeliverDriver,
      params: RequestParams = {},
    ) =>
      this.request<
        VoloAbpApplicationDtosPagedResultDto1WoraCorbosServiceDeliveryStepsDeliveryStepDtoWoraCorbosServiceApplicationContractsVersion1000CultureNeutralPublicKeyTokenNull,
        VoloAbpHttpRemoteServiceErrorResponse
      >({
        path: `/api/corbos-service/delivery-steps/driver-actual-route`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeliveryStep
     * @name CorbosServiceDeliveryStepsUpdateAddressCreate
     * @request POST:/api/corbos-service/delivery-steps/update-address
     * @secure
     */
    corbosServiceDeliveryStepsUpdateAddressCreate: (
      data: WoraCorbosServiceDeliveryStepsUpdateAddressInput,
      params: RequestParams = {},
    ) =>
      this.request<WoraCorbosServiceDeliveryStepsDeliveryStepDto, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/delivery-steps/update-address`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeliveryStep
     * @name CorbosServiceDeliveryStepsGetByExecutionList
     * @request GET:/api/corbos-service/delivery-steps/get-by-execution
     * @secure
     */
    corbosServiceDeliveryStepsGetByExecutionList: (
      query?: {
        /** @format uuid */
        executionId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string[], VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/delivery-steps/get-by-execution`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeliveryStep
     * @name CorbosServiceDeliveryStepsGetStepsDataCreate
     * @request POST:/api/corbos-service/delivery-steps/get-steps-data
     * @secure
     */
    corbosServiceDeliveryStepsGetStepsDataCreate: (
      data: WoraCorbosServiceDeliveryStepsGetDeliveryDataInput,
      params: RequestParams = {},
    ) =>
      this.request<WoraCorbosServiceDeliveryStepsDeliveryStepDataDto[], VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/delivery-steps/get-steps-data`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeliveryStepHistory
     * @name CorbosServiceDeliveryStepHistoriesList
     * @request GET:/api/corbos-service/delivery-step-histories
     * @secure
     */
    corbosServiceDeliveryStepHistoriesList: (
      query?: {
        FilterText?: string;
        /** @format uuid */
        ExecutionId?: string;
        /** @format uuid */
        ScenarioId?: string;
        ClusterKey?: string;
        /** @format uuid */
        DriverId?: string;
        /** @format uuid */
        StepId?: string;
        /** @format date-time */
        EtaMin?: string;
        /** @format date-time */
        EtaMax?: string;
        /** @format date-time */
        StartEtaWindow?: string;
        /** @format date-time */
        EndEtaWindow?: string;
        Sorting?: string;
        /**
         * @format int32
         * @min 0
         * @max 2147483647
         */
        SkipCount?: number;
        /**
         * @format int32
         * @min 1
         * @max 2147483647
         */
        MaxResultCount?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        VoloAbpApplicationDtosPagedResultDto1WoraCorbosServiceDeliveryStepHistoriesDeliveryStepHistoryDtoWoraCorbosServiceApplicationContractsVersion1000CultureNeutralPublicKeyTokenNull,
        VoloAbpHttpRemoteServiceErrorResponse
      >({
        path: `/api/corbos-service/delivery-step-histories`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags DeliveryStepHistory
     * @name CorbosServiceDeliveryStepHistoriesCreate
     * @request POST:/api/corbos-service/delivery-step-histories
     * @secure
     */
    corbosServiceDeliveryStepHistoriesCreate: (
      data: WoraCorbosServiceDeliveryStepHistoriesDeliveryStepHistoryCreateDto,
      params: RequestParams = {},
    ) =>
      this.request<WoraCorbosServiceDeliveryStepHistoriesDeliveryStepHistoryDto, VoloAbpHttpRemoteServiceErrorResponse>(
        {
          path: `/api/corbos-service/delivery-step-histories`,
          method: "POST",
          body: data,
          secure: true,
          type: ContentType.Json,
          format: "json",
          ...params,
        },
      ),

    /**
     * No description
     *
     * @tags DeliveryStepHistory
     * @name CorbosServiceDeliveryStepHistoriesDetail
     * @request GET:/api/corbos-service/delivery-step-histories/{id}
     * @secure
     */
    corbosServiceDeliveryStepHistoriesDetail: (id: string, params: RequestParams = {}) =>
      this.request<WoraCorbosServiceDeliveryStepHistoriesDeliveryStepHistoryDto, VoloAbpHttpRemoteServiceErrorResponse>(
        {
          path: `/api/corbos-service/delivery-step-histories/${id}`,
          method: "GET",
          secure: true,
          format: "json",
          ...params,
        },
      ),

    /**
     * No description
     *
     * @tags DeliveryStepHistory
     * @name CorbosServiceDeliveryStepHistoriesUpdate
     * @request PUT:/api/corbos-service/delivery-step-histories/{id}
     * @secure
     */
    corbosServiceDeliveryStepHistoriesUpdate: (
      id: string,
      data: WoraCorbosServiceDeliveryStepHistoriesDeliveryStepHistoryUpdateDto,
      params: RequestParams = {},
    ) =>
      this.request<WoraCorbosServiceDeliveryStepHistoriesDeliveryStepHistoryDto, VoloAbpHttpRemoteServiceErrorResponse>(
        {
          path: `/api/corbos-service/delivery-step-histories/${id}`,
          method: "PUT",
          body: data,
          secure: true,
          type: ContentType.Json,
          format: "json",
          ...params,
        },
      ),

    /**
     * No description
     *
     * @tags DeliveryStepHistory
     * @name CorbosServiceDeliveryStepHistoriesDelete
     * @request DELETE:/api/corbos-service/delivery-step-histories/{id}
     * @secure
     */
    corbosServiceDeliveryStepHistoriesDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/delivery-step-histories/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Driver
     * @name CorbosServiceDriversComboboxList
     * @request GET:/api/corbos-service/drivers/combobox
     * @secure
     */
    corbosServiceDriversComboboxList: (
      query?: {
        FilterText?: string;
        /** @format uuid */
        UserId?: string;
        Status?: WoraCorbosServiceDriversDriverStatus;
        Vehicle?: WoraCorbosServiceDriversVehicle;
        /** @format double */
        Latitude?: number;
        /** @format double */
        Longitude?: number;
        FirstName?: string;
        LastName?: string;
        Email?: string;
        Phone?: string;
        City?: string;
        filtersBaseObject?: string;
        filtersPropertyName?: string;
        filtersField?: string;
        filtersIgnoreCase?: boolean;
        filtersOperator?: string;
        filtersValue?: any;
        filtersLogic?: string;
        filtersFilters?: DmFilterApplicationContractsFiltersTelerikFilterDto[];
        Maps?: DmFilterApplicationContractsFiltersFilterMapDto[];
        Sorting?: string;
        /**
         * @format int32
         * @min 0
         * @max 2147483647
         */
        SkipCount?: number;
        /**
         * @format int32
         * @min 1
         * @max 2147483647
         */
        MaxResultCount?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        VoloAbpApplicationDtosPagedResultDto1DmApplicationComboxesComboboxItemDtoDmApplicationContractsVersion1160CultureNeutralPublicKeyTokenNull,
        VoloAbpHttpRemoteServiceErrorResponse
      >({
        path: `/api/corbos-service/drivers/combobox`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Driver
     * @name CorbosServiceDriversExcelList
     * @request GET:/api/corbos-service/drivers/excel
     * @secure
     */
    corbosServiceDriversExcelList: (
      query?: {
        FilterText?: string;
        /** @format uuid */
        UserId?: string;
        Status?: WoraCorbosServiceDriversDriverStatus;
        Vehicle?: WoraCorbosServiceDriversVehicle;
        /** @format double */
        Latitude?: number;
        /** @format double */
        Longitude?: number;
        FirstName?: string;
        LastName?: string;
        Email?: string;
        Phone?: string;
        City?: string;
        filtersBaseObject?: string;
        filtersPropertyName?: string;
        filtersField?: string;
        filtersIgnoreCase?: boolean;
        filtersOperator?: string;
        filtersValue?: any;
        filtersLogic?: string;
        filtersFilters?: DmFilterApplicationContractsFiltersTelerikFilterDto[];
        Maps?: DmFilterApplicationContractsFiltersFilterMapDto[];
        Sorting?: string;
        /**
         * @format int32
         * @min 0
         * @max 2147483647
         */
        SkipCount?: number;
        /**
         * @format int32
         * @min 1
         * @max 2147483647
         */
        MaxResultCount?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<File, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/drivers/excel`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Driver
     * @name CorbosServiceDriversBatchExcelList
     * @request GET:/api/corbos-service/drivers/batch-excel
     * @secure
     */
    corbosServiceDriversBatchExcelList: (
      query?: {
        FilterText?: string;
        /** @format uuid */
        UserId?: string;
        Status?: WoraCorbosServiceDriversDriverStatus;
        Vehicle?: WoraCorbosServiceDriversVehicle;
        /** @format double */
        Latitude?: number;
        /** @format double */
        Longitude?: number;
        FirstName?: string;
        LastName?: string;
        Email?: string;
        Phone?: string;
        City?: string;
        filtersBaseObject?: string;
        filtersPropertyName?: string;
        filtersField?: string;
        filtersIgnoreCase?: boolean;
        filtersOperator?: string;
        filtersValue?: any;
        filtersLogic?: string;
        filtersFilters?: DmFilterApplicationContractsFiltersTelerikFilterDto[];
        Maps?: DmFilterApplicationContractsFiltersFilterMapDto[];
        Sorting?: string;
        /**
         * @format int32
         * @min 0
         * @max 2147483647
         */
        SkipCount?: number;
        /**
         * @format int32
         * @min 1
         * @max 2147483647
         */
        MaxResultCount?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/drivers/batch-excel`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Driver
     * @name CorbosServiceDriversCreate
     * @request POST:/api/corbos-service/drivers
     * @secure
     */
    corbosServiceDriversCreate: (data: WoraCorbosServiceDriversGetDriversInput, params: RequestParams = {}) =>
      this.request<
        VoloAbpApplicationDtosPagedResultDto1WoraCorbosServiceDriversDriverDtoWoraCorbosServiceApplicationContractsVersion1000CultureNeutralPublicKeyTokenNull,
        VoloAbpHttpRemoteServiceErrorResponse
      >({
        path: `/api/corbos-service/drivers`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Driver
     * @name CorbosServiceDriversDetail
     * @request GET:/api/corbos-service/drivers/{input}
     * @secure
     */
    corbosServiceDriversDetail: (input: string, params: RequestParams = {}) =>
      this.request<WoraCorbosServiceDriversDriverDto, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/drivers/${input}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Driver
     * @name CorbosServiceDriversCreateCreate
     * @request POST:/api/corbos-service/drivers/create
     * @secure
     */
    corbosServiceDriversCreateCreate: (data: WoraCorbosServiceDriversDriverCreateDto, params: RequestParams = {}) =>
      this.request<WoraCorbosServiceDriversDriverDto, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/drivers/create`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Driver
     * @name CorbosServiceDriversUpdate
     * @request PUT:/api/corbos-service/drivers/{id}
     * @secure
     */
    corbosServiceDriversUpdate: (
      id: string,
      data: WoraCorbosServiceDriversDriverUpdateDto,
      params: RequestParams = {},
    ) =>
      this.request<WoraCorbosServiceDriversDriverDto, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/drivers/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Driver
     * @name CorbosServiceDriversDelete
     * @request DELETE:/api/corbos-service/drivers/{id}
     * @secure
     */
    corbosServiceDriversDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/drivers/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Driver
     * @name CorbosServiceDriversAsExcelFileList
     * @request GET:/api/corbos-service/drivers/as-excel-file
     * @secure
     */
    corbosServiceDriversAsExcelFileList: (
      query?: {
        DownloadToken?: string;
        FilterText?: string;
        /** @format uuid */
        UserId?: string;
        Status?: WoraCorbosServiceDriversDriverStatus;
        Vehicle?: WoraCorbosServiceDriversVehicle;
        /** @format double */
        Latitude?: number;
        /** @format double */
        Longitude?: number;
        FirstName?: string;
        LastName?: string;
        Email?: string;
        Phone?: string;
        City?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<File, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/drivers/as-excel-file`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Driver
     * @name CorbosServiceDriversDownloadTokenList
     * @request GET:/api/corbos-service/drivers/download-token
     * @secure
     */
    corbosServiceDriversDownloadTokenList: (params: RequestParams = {}) =>
      this.request<WoraCorbosServiceSharedDownloadTokenResultDto, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/drivers/download-token`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Driver
     * @name CorbosServiceDriversVehicleComboboxList
     * @request GET:/api/corbos-service/drivers/vehicle-combobox
     * @secure
     */
    corbosServiceDriversVehicleComboboxList: (params: RequestParams = {}) =>
      this.request<
        VoloAbpApplicationDtosListResultDto1DmApplicationComboxesComboboxItemIntDtoDmApplicationContractsVersion1160CultureNeutralPublicKeyTokenNull,
        VoloAbpHttpRemoteServiceErrorResponse
      >({
        path: `/api/corbos-service/drivers/vehicle-combobox`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Driver
     * @name CorbosServiceDriversDriverStatusComboboxList
     * @request GET:/api/corbos-service/drivers/driver-status-combobox
     * @secure
     */
    corbosServiceDriversDriverStatusComboboxList: (params: RequestParams = {}) =>
      this.request<
        VoloAbpApplicationDtosListResultDto1DmApplicationComboxesComboboxItemIntDtoDmApplicationContractsVersion1160CultureNeutralPublicKeyTokenNull,
        VoloAbpHttpRemoteServiceErrorResponse
      >({
        path: `/api/corbos-service/drivers/driver-status-combobox`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Driver
     * @name CorbosServiceDriversDrivesComboboxList
     * @request GET:/api/corbos-service/drivers/drives-combobox
     * @secure
     */
    corbosServiceDriversDrivesComboboxList: (params: RequestParams = {}) =>
      this.request<
        VoloAbpApplicationDtosListResultDto1DmApplicationComboxesComboboxItemDtoDmApplicationContractsVersion1160CultureNeutralPublicKeyTokenNull,
        VoloAbpHttpRemoteServiceErrorResponse
      >({
        path: `/api/corbos-service/drivers/drives-combobox`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Driver
     * @name CorbosServiceDriversDetailsList
     * @request GET:/api/corbos-service/drivers/details
     * @secure
     */
    corbosServiceDriversDetailsList: (
      query?: {
        /** @format uuid */
        userId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<WoraCorbosServiceDriversDriverDto, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/drivers/details`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Driver
     * @name CorbosServiceDriversUpdateLocationCreate
     * @request POST:/api/corbos-service/drivers/update-location
     * @secure
     */
    corbosServiceDriversUpdateLocationCreate: (
      data: WoraCorbosServiceDriversDriverLocationDto,
      params: RequestParams = {},
    ) =>
      this.request<void, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/drivers/update-location`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Driver
     * @name CorbosServiceDriversSetOnlineStatusCreate
     * @request POST:/api/corbos-service/drivers/set-online-status
     * @secure
     */
    corbosServiceDriversSetOnlineStatusCreate: (
      data: WoraCorbosServiceDriversDriverOnlineDto,
      params: RequestParams = {},
    ) =>
      this.request<void, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/drivers/set-online-status`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Driver
     * @name CorbosServiceDriversSetVehicleCreate
     * @request POST:/api/corbos-service/drivers/set-vehicle
     * @secure
     */
    corbosServiceDriversSetVehicleCreate: (
      data: WoraCorbosServiceDriversDriverVehicleDto,
      params: RequestParams = {},
    ) =>
      this.request<void, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/drivers/set-vehicle`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Driver
     * @name CorbosServiceDriversGetByNameList
     * @request GET:/api/corbos-service/drivers/get-by-name
     * @secure
     */
    corbosServiceDriversGetByNameList: (
      query?: {
        name?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<WoraCorbosServiceDriversDriverDto, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/drivers/get-by-name`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Driver
     * @name CorbosServiceDriversGetNextTaskList
     * @request GET:/api/corbos-service/drivers/get-next-task
     * @secure
     */
    corbosServiceDriversGetNextTaskList: (params: RequestParams = {}) =>
      this.request<WoraCorbosServiceDeliveryStepsDeliveryPlanDto, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/drivers/get-next-task`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Driver
     * @name CorbosServiceDriversGetPlanForDriversCreate
     * @request POST:/api/corbos-service/drivers/get-plan-for-drivers
     * @secure
     */
    corbosServiceDriversGetPlanForDriversCreate: (data: string[], params: RequestParams = {}) =>
      this.request<WoraCorbosServiceDeliveryStepsDeliveryPlanDto[], VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/drivers/get-plan-for-drivers`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags DriverPosition
     * @name CorbosServiceDriverPositionsList
     * @request GET:/api/corbos-service/driver-positions
     * @secure
     */
    corbosServiceDriverPositionsList: (
      query?: {
        FilterText?: string;
        /** @format uuid */
        DriverId?: string;
        /** @format double */
        LatitudeMin?: number;
        /** @format double */
        LatitudeMax?: number;
        /** @format double */
        LongitudeMin?: number;
        /** @format double */
        LongitudeMax?: number;
        Sorting?: string;
        /**
         * @format int32
         * @min 0
         * @max 2147483647
         */
        SkipCount?: number;
        /**
         * @format int32
         * @min 1
         * @max 2147483647
         */
        MaxResultCount?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        VoloAbpApplicationDtosPagedResultDto1WoraCorbosServiceDriverPositionsDriverPositionDtoWoraCorbosServiceApplicationContractsVersion1000CultureNeutralPublicKeyTokenNull,
        VoloAbpHttpRemoteServiceErrorResponse
      >({
        path: `/api/corbos-service/driver-positions`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags DriverPosition
     * @name CorbosServiceDriverPositionsCreate
     * @request POST:/api/corbos-service/driver-positions
     * @secure
     */
    corbosServiceDriverPositionsCreate: (
      data: WoraCorbosServiceDriverPositionsDriverPositionCreateDto,
      params: RequestParams = {},
    ) =>
      this.request<WoraCorbosServiceDriverPositionsDriverPositionDto, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/driver-positions`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags DriverPosition
     * @name CorbosServiceDriverPositionsDetail
     * @request GET:/api/corbos-service/driver-positions/{id}
     * @secure
     */
    corbosServiceDriverPositionsDetail: (id: string, params: RequestParams = {}) =>
      this.request<WoraCorbosServiceDriverPositionsDriverPositionDto, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/driver-positions/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags DriverPosition
     * @name CorbosServiceDriverPositionsUpdate
     * @request PUT:/api/corbos-service/driver-positions/{id}
     * @secure
     */
    corbosServiceDriverPositionsUpdate: (
      id: string,
      data: WoraCorbosServiceDriverPositionsDriverPositionUpdateDto,
      params: RequestParams = {},
    ) =>
      this.request<WoraCorbosServiceDriverPositionsDriverPositionDto, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/driver-positions/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags DriverPosition
     * @name CorbosServiceDriverPositionsDelete
     * @request DELETE:/api/corbos-service/driver-positions/{id}
     * @secure
     */
    corbosServiceDriverPositionsDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/driver-positions/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags EventMessageTemplate
     * @name CorbosServiceEventMessageTemplatesList
     * @request GET:/api/corbos-service/event-message-templates
     * @secure
     */
    corbosServiceEventMessageTemplatesList: (
      query?: {
        FilterText?: string;
        EventType?: string;
        /** @format int32 */
        EventValueMin?: number;
        /** @format int32 */
        EventValueMax?: number;
        /** @format uuid */
        MessageTemplateId?: string;
        Sorting?: string;
        /**
         * @format int32
         * @min 0
         * @max 2147483647
         */
        SkipCount?: number;
        /**
         * @format int32
         * @min 1
         * @max 2147483647
         */
        MaxResultCount?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        VoloAbpApplicationDtosPagedResultDto1WoraCorbosServiceEventMessageTemplatesEventMessageTemplateDtoWoraCorbosServiceApplicationContractsVersion1000CultureNeutralPublicKeyTokenNull,
        VoloAbpHttpRemoteServiceErrorResponse
      >({
        path: `/api/corbos-service/event-message-templates`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags EventMessageTemplate
     * @name CorbosServiceEventMessageTemplatesCreate
     * @request POST:/api/corbos-service/event-message-templates
     * @secure
     */
    corbosServiceEventMessageTemplatesCreate: (
      data: WoraCorbosServiceEventMessageTemplatesEventMessageTemplateCreateDto,
      params: RequestParams = {},
    ) =>
      this.request<
        WoraCorbosServiceEventMessageTemplatesEventMessageTemplateDto,
        VoloAbpHttpRemoteServiceErrorResponse
      >({
        path: `/api/corbos-service/event-message-templates`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags EventMessageTemplate
     * @name CorbosServiceEventMessageTemplatesDetail
     * @request GET:/api/corbos-service/event-message-templates/{id}
     * @secure
     */
    corbosServiceEventMessageTemplatesDetail: (id: string, params: RequestParams = {}) =>
      this.request<
        WoraCorbosServiceEventMessageTemplatesEventMessageTemplateDto,
        VoloAbpHttpRemoteServiceErrorResponse
      >({
        path: `/api/corbos-service/event-message-templates/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags EventMessageTemplate
     * @name CorbosServiceEventMessageTemplatesUpdate
     * @request PUT:/api/corbos-service/event-message-templates/{id}
     * @secure
     */
    corbosServiceEventMessageTemplatesUpdate: (
      id: string,
      data: WoraCorbosServiceEventMessageTemplatesEventMessageTemplateUpdateDto,
      params: RequestParams = {},
    ) =>
      this.request<
        WoraCorbosServiceEventMessageTemplatesEventMessageTemplateDto,
        VoloAbpHttpRemoteServiceErrorResponse
      >({
        path: `/api/corbos-service/event-message-templates/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags EventMessageTemplate
     * @name CorbosServiceEventMessageTemplatesDelete
     * @request DELETE:/api/corbos-service/event-message-templates/{id}
     * @secure
     */
    corbosServiceEventMessageTemplatesDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/event-message-templates/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags EventMessageTemplate
     * @name CorbosServiceEventMessageTemplatesAsExcelFileList
     * @request GET:/api/corbos-service/event-message-templates/as-excel-file
     * @secure
     */
    corbosServiceEventMessageTemplatesAsExcelFileList: (
      query?: {
        DownloadToken?: string;
        FilterText?: string;
        EventType?: string;
        /** @format int32 */
        EventValueMin?: number;
        /** @format int32 */
        EventValueMax?: number;
        /** @format uuid */
        MessageTemplateId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<File, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/event-message-templates/as-excel-file`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags EventMessageTemplate
     * @name CorbosServiceEventMessageTemplatesDownloadTokenList
     * @request GET:/api/corbos-service/event-message-templates/download-token
     * @secure
     */
    corbosServiceEventMessageTemplatesDownloadTokenList: (params: RequestParams = {}) =>
      this.request<WoraCorbosServiceSharedDownloadTokenResultDto, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/event-message-templates/download-token`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags FileStorage
     * @name CorbosServiceFileStoragesList
     * @request GET:/api/corbos-service/file-storages
     * @secure
     */
    corbosServiceFileStoragesList: (
      query?: {
        FilterText?: string;
        /** @format int32 */
        DimensionInByte?: number;
        FileName?: string;
        FileType?: string;
        Typology?: WoraCorbosServiceFileStoragesFileTypology;
        /** @format uuid */
        EntityId?: string;
        Sorting?: string;
        /**
         * @format int32
         * @min 0
         * @max 2147483647
         */
        SkipCount?: number;
        /**
         * @format int32
         * @min 1
         * @max 2147483647
         */
        MaxResultCount?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        VoloAbpApplicationDtosPagedResultDto1WoraCorbosServiceFileStoragesFileStorageDtoWoraCorbosServiceApplicationContractsVersion1000CultureNeutralPublicKeyTokenNull,
        VoloAbpHttpRemoteServiceErrorResponse
      >({
        path: `/api/corbos-service/file-storages`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags FileStorage
     * @name CorbosServiceFileStoragesCreate
     * @request POST:/api/corbos-service/file-storages
     * @secure
     */
    corbosServiceFileStoragesCreate: (
      data: WoraCorbosServiceFileStoragesFileStorageCreateDto,
      params: RequestParams = {},
    ) =>
      this.request<WoraCorbosServiceFileStoragesFileStorageDto, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/file-storages`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags FileStorage
     * @name CorbosServiceFileStoragesDetail
     * @request GET:/api/corbos-service/file-storages/{id}
     * @secure
     */
    corbosServiceFileStoragesDetail: (id: string, params: RequestParams = {}) =>
      this.request<WoraCorbosServiceFileStoragesFileStorageDto, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/file-storages/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags FileStorage
     * @name CorbosServiceFileStoragesUpdate
     * @request PUT:/api/corbos-service/file-storages/{id}
     * @secure
     */
    corbosServiceFileStoragesUpdate: (
      id: string,
      data: WoraCorbosServiceFileStoragesFileStorageUpdateDto,
      params: RequestParams = {},
    ) =>
      this.request<WoraCorbosServiceFileStoragesFileStorageDto, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/file-storages/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags FileStorage
     * @name CorbosServiceFileStoragesDelete
     * @request DELETE:/api/corbos-service/file-storages/{id}
     * @secure
     */
    corbosServiceFileStoragesDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/file-storages/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags HistoryEvent
     * @name CorbosServiceHistoryEventsCreate
     * @request POST:/api/corbos-service/history-events
     * @secure
     */
    corbosServiceHistoryEventsCreate: (
      data: WoraCorbosServiceHistoryEventsGetHistoryEventsInput,
      params: RequestParams = {},
    ) =>
      this.request<
        VoloAbpApplicationDtosPagedResultDto1WoraCorbosServiceHistoryEventsHistoryEventDtoWoraCorbosServiceApplicationContractsVersion1000CultureNeutralPublicKeyTokenNull,
        VoloAbpHttpRemoteServiceErrorResponse
      >({
        path: `/api/corbos-service/history-events`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags HistoryEvent
     * @name CorbosServiceHistoryEventsDetail
     * @request GET:/api/corbos-service/history-events/{id}
     * @secure
     */
    corbosServiceHistoryEventsDetail: (id: string, params: RequestParams = {}) =>
      this.request<WoraCorbosServiceHistoryEventsHistoryEventDto, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/history-events/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags HistoryEvent
     * @name CorbosServiceHistoryEventsUpdate
     * @request PUT:/api/corbos-service/history-events/{id}
     * @secure
     */
    corbosServiceHistoryEventsUpdate: (
      id: string,
      data: WoraCorbosServiceHistoryEventsHistoryEventUpdateDto,
      params: RequestParams = {},
    ) =>
      this.request<WoraCorbosServiceHistoryEventsHistoryEventDto, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/history-events/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags HistoryEvent
     * @name CorbosServiceHistoryEventsDelete
     * @request DELETE:/api/corbos-service/history-events/{id}
     * @secure
     */
    corbosServiceHistoryEventsDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/history-events/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags HistoryEvent
     * @name CorbosServiceHistoryEventsCreateCreate
     * @request POST:/api/corbos-service/history-events/create
     * @secure
     */
    corbosServiceHistoryEventsCreateCreate: (
      data: WoraCorbosServiceHistoryEventsHistoryEventCreateDto,
      params: RequestParams = {},
    ) =>
      this.request<WoraCorbosServiceHistoryEventsHistoryEventDto, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/history-events/create`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags HistoryEvent
     * @name CorbosServiceHistoryEventsAsExcelFileList
     * @request GET:/api/corbos-service/history-events/as-excel-file
     * @secure
     */
    corbosServiceHistoryEventsAsExcelFileList: (
      query?: {
        DownloadToken?: string;
        FilterText?: string;
        EventType?: WoraCorbosServiceHistoryEventsEventType;
        EntityType?: WoraCorbosServiceHistoryEventsEntityType;
        ExternalMessage?: string;
        InternalMessage?: string;
        IsPublic?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<File, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/history-events/as-excel-file`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags HistoryEvent
     * @name CorbosServiceHistoryEventsDownloadTokenList
     * @request GET:/api/corbos-service/history-events/download-token
     * @secure
     */
    corbosServiceHistoryEventsDownloadTokenList: (params: RequestParams = {}) =>
      this.request<WoraCorbosServiceSharedDownloadTokenResultDto, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/history-events/download-token`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags HistoryEvent
     * @name CorbosServiceHistoryEventsGetEventTypeComboboxList
     * @request GET:/api/corbos-service/history-events/get-event-type-combobox
     * @secure
     */
    corbosServiceHistoryEventsGetEventTypeComboboxList: (params: RequestParams = {}) =>
      this.request<
        VoloAbpApplicationDtosListResultDto1DmApplicationComboxesComboboxItemIntDtoDmApplicationContractsVersion1160CultureNeutralPublicKeyTokenNull,
        VoloAbpHttpRemoteServiceErrorResponse
      >({
        path: `/api/corbos-service/history-events/get-event-type-combobox`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags HistoryEvent
     * @name CorbosServiceHistoryEventsGetEntityTypeComboboxList
     * @request GET:/api/corbos-service/history-events/get-entity-type-combobox
     * @secure
     */
    corbosServiceHistoryEventsGetEntityTypeComboboxList: (params: RequestParams = {}) =>
      this.request<
        VoloAbpApplicationDtosListResultDto1DmApplicationComboxesComboboxItemIntDtoDmApplicationContractsVersion1160CultureNeutralPublicKeyTokenNull,
        VoloAbpHttpRemoteServiceErrorResponse
      >({
        path: `/api/corbos-service/history-events/get-entity-type-combobox`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Import
     * @name ImportManagementImportUploadFileCreate
     * @request POST:/api/import-management/import/upload-file
     * @secure
     */
    importManagementImportUploadFileCreate: (
      data: {
        Files?: File[];
      },
      query?: {
        /** @format uuid */
        DataFileId?: string;
        DataFileType?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<DmExcelImportManagementImportFilesOutputFileDto, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/import-management/import/upload-file`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Import
     * @name ImportManagementImportDownloadFileDetail
     * @request GET:/api/import-management/import/download-file/{id}
     * @secure
     */
    importManagementImportDownloadFileDetail: (id: string, params: RequestParams = {}) =>
      this.request<File, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/import-management/import/download-file/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Import
     * @name ImportManagementImportFileInfoDetail
     * @request GET:/api/import-management/import/file-info/{id}
     * @secure
     */
    importManagementImportFileInfoDetail: (id: string, params: RequestParams = {}) =>
      this.request<DmExcelImportManagementImportFilesFileInfoDto, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/import-management/import/file-info/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ImportInstance
     * @name ImportManagementImportInstancesList
     * @request GET:/api/import-management/import-instances
     * @secure
     */
    importManagementImportInstancesList: (
      query?: {
        FilterText?: string;
        TemplateKey?: string;
        ImportProvider?: string;
        /** @format uuid */
        FileId?: string;
        ImportStatus?: DmExcelImportManagementImportStatusEnum;
        /** @format date-time */
        StartTimeMin?: string;
        /** @format date-time */
        StartTimeMax?: string;
        /** @format date-time */
        EndTimeMin?: string;
        /** @format date-time */
        EndTimeMax?: string;
        /** @format int32 */
        DurationMin?: number;
        /** @format int32 */
        DurationMax?: number;
        Message?: string;
        ExceptionMessage?: string;
        Sorting?: string;
        /**
         * @format int32
         * @min 0
         * @max 2147483647
         */
        SkipCount?: number;
        /**
         * @format int32
         * @min 1
         * @max 2147483647
         */
        MaxResultCount?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        VoloAbpApplicationDtosPagedResultDto1DmExcelImportManagementImportInstancesImportInstanceDtoDmExcelImportManagementApplicationContractsVersion00440CultureNeutralPublicKeyTokenNull,
        VoloAbpHttpRemoteServiceErrorResponse
      >({
        path: `/api/import-management/import-instances`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ImportInstance
     * @name ImportManagementImportInstancesCreate
     * @request POST:/api/import-management/import-instances
     * @secure
     */
    importManagementImportInstancesCreate: (
      data: DmExcelImportManagementImportInstancesImportInstanceCreateDto,
      params: RequestParams = {},
    ) =>
      this.request<DmExcelImportManagementImportInstancesImportInstanceDto, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/import-management/import-instances`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ImportInstance
     * @name ImportManagementImportInstancesDetail
     * @request GET:/api/import-management/import-instances/{id}
     * @secure
     */
    importManagementImportInstancesDetail: (id: string, params: RequestParams = {}) =>
      this.request<DmExcelImportManagementImportInstancesImportInstanceDto, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/import-management/import-instances/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ImportInstance
     * @name ImportManagementImportInstancesUpdate
     * @request PUT:/api/import-management/import-instances/{id}
     * @secure
     */
    importManagementImportInstancesUpdate: (
      id: string,
      data: DmExcelImportManagementImportInstancesImportInstanceUpdateDto,
      params: RequestParams = {},
    ) =>
      this.request<DmExcelImportManagementImportInstancesImportInstanceDto, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/import-management/import-instances/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ImportInstance
     * @name ImportManagementImportInstancesDelete
     * @request DELETE:/api/import-management/import-instances/{id}
     * @secure
     */
    importManagementImportInstancesDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/import-management/import-instances/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ImportInstance
     * @name ImportManagementImportInstancesAsExcelFileList
     * @request GET:/api/import-management/import-instances/as-excel-file
     * @secure
     */
    importManagementImportInstancesAsExcelFileList: (
      query?: {
        DownloadToken?: string;
        FilterText?: string;
        TemplateKey?: string;
        ImportProvider?: string;
        /** @format uuid */
        FileId?: string;
        ImportStatus?: DmExcelImportManagementImportStatusEnum;
        /** @format date-time */
        StartTimeMin?: string;
        /** @format date-time */
        StartTimeMax?: string;
        /** @format date-time */
        EndTimeMin?: string;
        /** @format date-time */
        EndTimeMax?: string;
        /** @format int32 */
        DurationMin?: number;
        /** @format int32 */
        DurationMax?: number;
        Message?: string;
        ExceptionMessage?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<File, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/import-management/import-instances/as-excel-file`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ImportInstance
     * @name ImportManagementImportInstancesDownloadTokenList
     * @request GET:/api/import-management/import-instances/download-token
     * @secure
     */
    importManagementImportInstancesDownloadTokenList: (params: RequestParams = {}) =>
      this.request<DmExcelImportManagementSharedDownloadTokenResultDto, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/import-management/import-instances/download-token`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ImportTemplate
     * @name ImportManagementImportTemplatesList
     * @request GET:/api/import-management/import-templates
     * @secure
     */
    importManagementImportTemplatesList: (
      query?: {
        FilterText?: string;
        TemplateKey?: string;
        /** @format uuid */
        FileId?: string;
        Sorting?: string;
        /**
         * @format int32
         * @min 0
         * @max 2147483647
         */
        SkipCount?: number;
        /**
         * @format int32
         * @min 1
         * @max 2147483647
         */
        MaxResultCount?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        VoloAbpApplicationDtosPagedResultDto1DmExcelImportManagementImportTemplatesImportTemplateDtoDmExcelImportManagementApplicationContractsVersion00440CultureNeutralPublicKeyTokenNull,
        VoloAbpHttpRemoteServiceErrorResponse
      >({
        path: `/api/import-management/import-templates`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ImportTemplate
     * @name ImportManagementImportTemplatesCreate
     * @request POST:/api/import-management/import-templates
     * @secure
     */
    importManagementImportTemplatesCreate: (
      data: DmExcelImportManagementImportTemplatesImportTemplateCreateDto,
      params: RequestParams = {},
    ) =>
      this.request<DmExcelImportManagementImportTemplatesImportTemplateDto, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/import-management/import-templates`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ImportTemplate
     * @name ImportManagementImportTemplatesDetail
     * @request GET:/api/import-management/import-templates/{id}
     * @secure
     */
    importManagementImportTemplatesDetail: (id: string, params: RequestParams = {}) =>
      this.request<DmExcelImportManagementImportTemplatesImportTemplateDto, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/import-management/import-templates/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ImportTemplate
     * @name ImportManagementImportTemplatesDelete
     * @request DELETE:/api/import-management/import-templates/{id}
     * @secure
     */
    importManagementImportTemplatesDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/import-management/import-templates/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ImportTemplate
     * @name ImportManagementImportTemplatesUpdate
     * @request PUT:/api/import-management/import-templates/{templateKey}
     * @secure
     */
    importManagementImportTemplatesUpdate: (
      templateKey: string,
      data: DmExcelImportManagementImportTemplatesImportTemplateUpdateDto,
      params: RequestParams = {},
    ) =>
      this.request<DmExcelImportManagementImportTemplatesImportTemplateDto, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/import-management/import-templates/${templateKey}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ImportTemplate
     * @name ImportManagementImportTemplatesAsExcelFileList
     * @request GET:/api/import-management/import-templates/as-excel-file
     * @secure
     */
    importManagementImportTemplatesAsExcelFileList: (
      query?: {
        DownloadToken?: string;
        FilterText?: string;
        TemplateKey?: string;
        /** @format uuid */
        FileId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<File, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/import-management/import-templates/as-excel-file`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ImportTemplate
     * @name ImportManagementImportTemplatesDownloadTokenList
     * @request GET:/api/import-management/import-templates/download-token
     * @secure
     */
    importManagementImportTemplatesDownloadTokenList: (params: RequestParams = {}) =>
      this.request<DmExcelImportManagementSharedDownloadTokenResultDto, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/import-management/import-templates/download-token`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ImportTemplate
     * @name ImportManagementImportTemplatesTemplateFileDefinitionList
     * @request GET:/api/import-management/import-templates/template-file-definition
     * @secure
     */
    importManagementImportTemplatesTemplateFileDefinitionList: (
      query?: {
        templateKey?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<DmExcelImportManagementImportFilesFileInfoDto, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/import-management/import-templates/template-file-definition`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Nation
     * @name CrmNationsCreate
     * @request POST:/api/crm/nations
     * @secure
     */
    crmNationsCreate: (data: DmCrmNationsGetNationsInput, params: RequestParams = {}) =>
      this.request<
        VoloAbpApplicationDtosPagedResultDto1DmCrmNationsNationDtoDmCrmApplicationContractsVersion1030CultureNeutralPublicKeyTokenNull,
        VoloAbpHttpRemoteServiceErrorResponse
      >({
        path: `/api/crm/nations`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Nation
     * @name CrmNationsDetail
     * @request GET:/api/crm/nations/{id}
     * @secure
     */
    crmNationsDetail: (id: number, params: RequestParams = {}) =>
      this.request<DmCrmNationsNationDto, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/crm/nations/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Nation
     * @name CrmNationsUpdate
     * @request PUT:/api/crm/nations/{id}
     * @secure
     */
    crmNationsUpdate: (id: number, data: DmCrmNationsNationUpdateDto, params: RequestParams = {}) =>
      this.request<DmCrmNationsNationDto, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/crm/nations/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Nation
     * @name CrmNationsDelete
     * @request DELETE:/api/crm/nations/{id}
     * @secure
     */
    crmNationsDelete: (id: number, params: RequestParams = {}) =>
      this.request<void, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/crm/nations/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Nation
     * @name CrmNationsCreateCreate
     * @request POST:/api/crm/nations/create
     * @secure
     */
    crmNationsCreateCreate: (data: DmCrmNationsNationCreateDto, params: RequestParams = {}) =>
      this.request<DmCrmNationsNationDto, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/crm/nations/create`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Nation
     * @name CrmNationsComboboxItemsCreate
     * @request POST:/api/crm/nations/combobox-items
     * @secure
     */
    crmNationsComboboxItemsCreate: (data: DmCrmNationsGetNationsInput, params: RequestParams = {}) =>
      this.request<
        VoloAbpApplicationDtosPagedResultDto1DmApplicationComboxesComboboxItemLongDtoDmApplicationContractsVersion1160CultureNeutralPublicKeyTokenNull,
        VoloAbpHttpRemoteServiceErrorResponse
      >({
        path: `/api/crm/nations/combobox-items`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Nation
     * @name CrmNationsExcelCreate
     * @request POST:/api/crm/nations/excel
     * @secure
     */
    crmNationsExcelCreate: (data: DmCrmNationsGetNationsInput, params: RequestParams = {}) =>
      this.request<File, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/crm/nations/excel`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Nation
     * @name CrmNationsExcelBatchCreate
     * @request POST:/api/crm/nations/excel-batch
     * @secure
     */
    crmNationsExcelBatchCreate: (data: DmCrmNationsGetNationsInput, params: RequestParams = {}) =>
      this.request<void, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/crm/nations/excel-batch`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Nation
     * @name CrmNationsComboboxList
     * @request GET:/api/crm/nations/combobox
     * @secure
     */
    crmNationsComboboxList: (params: RequestParams = {}) =>
      this.request<
        VoloAbpApplicationDtosListResultDto1DmApplicationComboxesComboboxItemLongDtoDmApplicationContractsVersion1160CultureNeutralPublicKeyTokenNull,
        VoloAbpHttpRemoteServiceErrorResponse
      >({
        path: `/api/crm/nations/combobox`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Notification
     * @name CorbosServiceNotificationsComboboxItemsCreate
     * @request POST:/api/corbos-service/notifications/combobox-items
     * @secure
     */
    corbosServiceNotificationsComboboxItemsCreate: (
      data: WoraCorbosServiceNotificationsGetNotificationsInput,
      params: RequestParams = {},
    ) =>
      this.request<
        VoloAbpApplicationDtosPagedResultDto1DmApplicationComboxesComboboxItemDtoDmApplicationContractsVersion1160CultureNeutralPublicKeyTokenNull,
        VoloAbpHttpRemoteServiceErrorResponse
      >({
        path: `/api/corbos-service/notifications/combobox-items`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Notification
     * @name CorbosServiceNotificationsExcelCreate
     * @request POST:/api/corbos-service/notifications/excel
     * @secure
     */
    corbosServiceNotificationsExcelCreate: (
      data: WoraCorbosServiceNotificationsGetNotificationsInput,
      params: RequestParams = {},
    ) =>
      this.request<File, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/notifications/excel`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Notification
     * @name CorbosServiceNotificationsExcelBatchCreate
     * @request POST:/api/corbos-service/notifications/excel-batch
     * @secure
     */
    corbosServiceNotificationsExcelBatchCreate: (
      data: WoraCorbosServiceNotificationsGetNotificationsInput,
      params: RequestParams = {},
    ) =>
      this.request<void, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/notifications/excel-batch`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Notification
     * @name CorbosServiceNotificationsCreate
     * @request POST:/api/corbos-service/notifications
     * @secure
     */
    corbosServiceNotificationsCreate: (
      data: WoraCorbosServiceNotificationsGetNotificationsInput,
      params: RequestParams = {},
    ) =>
      this.request<
        VoloAbpApplicationDtosPagedResultDto1WoraCorbosServiceNotificationsNotificationDtoWoraCorbosServiceApplicationContractsVersion1000CultureNeutralPublicKeyTokenNull,
        VoloAbpHttpRemoteServiceErrorResponse
      >({
        path: `/api/corbos-service/notifications`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Notification
     * @name CorbosServiceNotificationsDetail
     * @request GET:/api/corbos-service/notifications/{id}
     * @secure
     */
    corbosServiceNotificationsDetail: (id: string, params: RequestParams = {}) =>
      this.request<WoraCorbosServiceNotificationsNotificationDto, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/notifications/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Notification
     * @name CorbosServiceNotificationsUpdate
     * @request PUT:/api/corbos-service/notifications/{id}
     * @secure
     */
    corbosServiceNotificationsUpdate: (
      id: string,
      data: WoraCorbosServiceNotificationsNotificationUpdateDto,
      params: RequestParams = {},
    ) =>
      this.request<WoraCorbosServiceNotificationsNotificationDto, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/notifications/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Notification
     * @name CorbosServiceNotificationsDelete
     * @request DELETE:/api/corbos-service/notifications/{id}
     * @secure
     */
    corbosServiceNotificationsDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/notifications/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Notification
     * @name CorbosServiceNotificationsCreateCreate
     * @request POST:/api/corbos-service/notifications/create
     * @secure
     */
    corbosServiceNotificationsCreateCreate: (
      data: WoraCorbosServiceNotificationsNotificationCreateDto,
      params: RequestParams = {},
    ) =>
      this.request<WoraCorbosServiceNotificationsNotificationDto, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/notifications/create`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Notification
     * @name CorbosServiceNotificationsAsExcelFileList
     * @request GET:/api/corbos-service/notifications/as-excel-file
     * @secure
     */
    corbosServiceNotificationsAsExcelFileList: (
      query?: {
        DownloadToken?: string;
        FilterText?: string;
        MessageType?: string;
        Message?: string;
        Status?: string;
        SendNotificationId?: string;
        SendNotifcationResult?: string;
        SendNotificationResponse?: string;
        /** @format uuid */
        DeliveryId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<File, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/notifications/as-excel-file`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Notification
     * @name CorbosServiceNotificationsDownloadTokenList
     * @request GET:/api/corbos-service/notifications/download-token
     * @secure
     */
    corbosServiceNotificationsDownloadTokenList: (params: RequestParams = {}) =>
      this.request<WoraCorbosServiceSharedDownloadTokenResultDto, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/notifications/download-token`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Notification
     * @name CorbosServiceNotificationsGenerateNotificationCreate
     * @request POST:/api/corbos-service/notifications/generate-notification
     * @secure
     */
    corbosServiceNotificationsGenerateNotificationCreate: (
      data: WoraCorbosServiceNotificationsSendNotificationDto,
      params: RequestParams = {},
    ) =>
      this.request<void, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/notifications/generate-notification`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Notification
     * @name CorbosServiceNotificationsGetStatusComboboxList
     * @request GET:/api/corbos-service/notifications/get-status-combobox
     * @secure
     */
    corbosServiceNotificationsGetStatusComboboxList: (params: RequestParams = {}) =>
      this.request<
        VoloAbpApplicationDtosListResultDto1DmApplicationComboxesComboboxItemIntDtoDmApplicationContractsVersion1160CultureNeutralPublicKeyTokenNull,
        VoloAbpHttpRemoteServiceErrorResponse
      >({
        path: `/api/corbos-service/notifications/get-status-combobox`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Notification
     * @name CorbosServiceNotificationsSendMessagesList
     * @request GET:/api/corbos-service/notifications/send-messages
     * @secure
     */
    corbosServiceNotificationsSendMessagesList: (
      query?: {
        notificationIds?: string[];
      },
      params: RequestParams = {},
    ) =>
      this.request<void, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/notifications/send-messages`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Public
     * @name CorbosServicePublicCreateReviewCreate
     * @request POST:/api/corbos-service/public/create-review
     * @secure
     */
    corbosServicePublicCreateReviewCreate: (
      data: WoraCorbosServiceReviewsReviewCreateDto,
      params: RequestParams = {},
    ) =>
      this.request<WoraCorbosServiceReviewsReviewDto, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/public/create-review`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Public
     * @name CorbosServicePublicGetDriverLocationList
     * @request GET:/api/corbos-service/public/get-driver-location
     * @secure
     */
    corbosServicePublicGetDriverLocationList: (
      query?: {
        token?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<WoraCorbosServiceDriversDriverLocationDto, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/public/get-driver-location`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Public
     * @name CorbosServicePublicHasReviewCreate
     * @request POST:/api/corbos-service/public/has-review
     * @secure
     */
    corbosServicePublicHasReviewCreate: (data: WoraCorbosServiceReviewsReviewCheckDto, params: RequestParams = {}) =>
      this.request<boolean, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/public/has-review`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Public
     * @name CorbosServicePublicUpdateDeliveryTimeCreate
     * @request POST:/api/corbos-service/public/update-delivery-time
     * @secure
     */
    corbosServicePublicUpdateDeliveryTimeCreate: (
      data: WoraCorbosServiceDeliveryStepsUpdateDeliveryTimeInput,
      params: RequestParams = {},
    ) =>
      this.request<void, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/public/update-delivery-time`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Public
     * @name CorbosServicePublicUpdateDeliveryAddressCreate
     * @request POST:/api/corbos-service/public/update-delivery-address
     * @secure
     */
    corbosServicePublicUpdateDeliveryAddressCreate: (
      data: WoraCorbosServiceDeliveryStepsUpdateDeliveryAddressInput,
      params: RequestParams = {},
    ) =>
      this.request<void, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/public/update-delivery-address`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Public
     * @name CorbosServicePublicGetDeliveryList
     * @request GET:/api/corbos-service/public/get-delivery
     * @secure
     */
    corbosServicePublicGetDeliveryList: (
      query?: {
        randomCode?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<WoraCorbosServiceDeliveriesDeliveryDto, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/public/get-delivery`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Review
     * @name CorbosServiceReviewsList
     * @request GET:/api/corbos-service/reviews
     * @secure
     */
    corbosServiceReviewsList: (
      query?: {
        FilterText?: string;
        /** @format uuid */
        DeliveryId?: string;
        /** @format int32 */
        RatingMin?: number;
        /** @format int32 */
        RatingMax?: number;
        Note?: string;
        Sorting?: string;
        /**
         * @format int32
         * @min 0
         * @max 2147483647
         */
        SkipCount?: number;
        /**
         * @format int32
         * @min 1
         * @max 2147483647
         */
        MaxResultCount?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        VoloAbpApplicationDtosPagedResultDto1WoraCorbosServiceReviewsReviewDtoWoraCorbosServiceApplicationContractsVersion1000CultureNeutralPublicKeyTokenNull,
        VoloAbpHttpRemoteServiceErrorResponse
      >({
        path: `/api/corbos-service/reviews`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Review
     * @name CorbosServiceReviewsCreate
     * @request POST:/api/corbos-service/reviews
     * @secure
     */
    corbosServiceReviewsCreate: (data: WoraCorbosServiceReviewsReviewCreateDto, params: RequestParams = {}) =>
      this.request<WoraCorbosServiceReviewsReviewDto, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/reviews`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Review
     * @name CorbosServiceReviewsDetail
     * @request GET:/api/corbos-service/reviews/{id}
     * @secure
     */
    corbosServiceReviewsDetail: (id: string, params: RequestParams = {}) =>
      this.request<WoraCorbosServiceReviewsReviewDto, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/reviews/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Review
     * @name CorbosServiceReviewsUpdate
     * @request PUT:/api/corbos-service/reviews/{id}
     * @secure
     */
    corbosServiceReviewsUpdate: (
      id: string,
      data: WoraCorbosServiceReviewsReviewUpdateDto,
      params: RequestParams = {},
    ) =>
      this.request<WoraCorbosServiceReviewsReviewDto, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/reviews/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Review
     * @name CorbosServiceReviewsDelete
     * @request DELETE:/api/corbos-service/reviews/{id}
     * @secure
     */
    corbosServiceReviewsDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/reviews/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags settings
     * @name CorbosServiceSettingsUpdateUpdate
     * @request PUT:/api/corbos-service/settings/update
     * @secure
     */
    corbosServiceSettingsUpdateUpdate: (data: WoraCorbosServiceSettingsSettingDto[], params: RequestParams = {}) =>
      this.request<void, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/settings/update`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags settings
     * @name CorbosServiceSettingsList
     * @request GET:/api/corbos-service/settings
     * @secure
     */
    corbosServiceSettingsList: (params: RequestParams = {}) =>
      this.request<VoloAbpSettingsSettingValue[], VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/settings`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Shift
     * @name CorbosServiceShiftsComboboxItemsCreate
     * @request POST:/api/corbos-service/shifts/combobox-items
     * @secure
     */
    corbosServiceShiftsComboboxItemsCreate: (data: WoraCorbosServiceShiftsGetShiftsInput, params: RequestParams = {}) =>
      this.request<
        VoloAbpApplicationDtosPagedResultDto1DmApplicationComboxesComboboxItemDtoDmApplicationContractsVersion1160CultureNeutralPublicKeyTokenNull,
        VoloAbpHttpRemoteServiceErrorResponse
      >({
        path: `/api/corbos-service/shifts/combobox-items`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Shift
     * @name CorbosServiceShiftsExcelCreate
     * @request POST:/api/corbos-service/shifts/excel
     * @secure
     */
    corbosServiceShiftsExcelCreate: (data: WoraCorbosServiceShiftsGetShiftsInput, params: RequestParams = {}) =>
      this.request<File, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/shifts/excel`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Shift
     * @name CorbosServiceShiftsExcelBatchCreate
     * @request POST:/api/corbos-service/shifts/excel-batch
     * @secure
     */
    corbosServiceShiftsExcelBatchCreate: (data: WoraCorbosServiceShiftsGetShiftsInput, params: RequestParams = {}) =>
      this.request<void, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/shifts/excel-batch`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Shift
     * @name CorbosServiceShiftsCreate
     * @request POST:/api/corbos-service/shifts
     * @secure
     */
    corbosServiceShiftsCreate: (data: WoraCorbosServiceShiftsGetShiftsInput, params: RequestParams = {}) =>
      this.request<
        VoloAbpApplicationDtosPagedResultDto1WoraCorbosServiceShiftsShiftDtoWoraCorbosServiceApplicationContractsVersion1000CultureNeutralPublicKeyTokenNull,
        VoloAbpHttpRemoteServiceErrorResponse
      >({
        path: `/api/corbos-service/shifts`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Shift
     * @name CorbosServiceShiftsDetail
     * @request GET:/api/corbos-service/shifts/{id}
     * @secure
     */
    corbosServiceShiftsDetail: (id: string, params: RequestParams = {}) =>
      this.request<WoraCorbosServiceShiftsShiftDto, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/shifts/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Shift
     * @name CorbosServiceShiftsUpdate
     * @request PUT:/api/corbos-service/shifts/{id}
     * @secure
     */
    corbosServiceShiftsUpdate: (id: string, data: WoraCorbosServiceShiftsShiftUpdateDto, params: RequestParams = {}) =>
      this.request<WoraCorbosServiceShiftsShiftDto, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/shifts/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Shift
     * @name CorbosServiceShiftsDelete
     * @request DELETE:/api/corbos-service/shifts/{id}
     * @secure
     */
    corbosServiceShiftsDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/shifts/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Shift
     * @name CorbosServiceShiftsCreateCreate
     * @request POST:/api/corbos-service/shifts/create
     * @secure
     */
    corbosServiceShiftsCreateCreate: (data: WoraCorbosServiceShiftsShiftCreateDto, params: RequestParams = {}) =>
      this.request<WoraCorbosServiceShiftsShiftDto, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/shifts/create`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Shift
     * @name CorbosServiceShiftsAsExcelFileList
     * @request GET:/api/corbos-service/shifts/as-excel-file
     * @secure
     */
    corbosServiceShiftsAsExcelFileList: (
      query?: {
        DownloadToken?: string;
        FilterText?: string;
        Description?: string;
        Note?: string;
        /** @format uuid */
        DriverId?: string;
        /** @format date-time */
        TimeFromMin?: string;
        /** @format date-time */
        TimeFromMax?: string;
        /** @format date-time */
        TimeToMin?: string;
        /** @format date-time */
        TimeToMax?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<File, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/shifts/as-excel-file`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Shift
     * @name CorbosServiceShiftsDownloadTokenList
     * @request GET:/api/corbos-service/shifts/download-token
     * @secure
     */
    corbosServiceShiftsDownloadTokenList: (params: RequestParams = {}) =>
      this.request<WoraCorbosServiceSharedDownloadTokenResultDto, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/shifts/download-token`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Shift
     * @name CorbosServiceShiftsDriverByDayList
     * @request GET:/api/corbos-service/shifts/driver-by-day
     * @secure
     */
    corbosServiceShiftsDriverByDayList: (
      query?: {
        /** @format date-time */
        day?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        VoloAbpApplicationDtosListResultDto1WoraCorbosServiceShiftsShiftFullDtoWoraCorbosServiceApplicationContractsVersion1000CultureNeutralPublicKeyTokenNull,
        VoloAbpHttpRemoteServiceErrorResponse
      >({
        path: `/api/corbos-service/shifts/driver-by-day`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Store
     * @name CorbosServiceStoresComboboxItemsCreate
     * @request POST:/api/corbos-service/stores/combobox-items
     * @secure
     */
    corbosServiceStoresComboboxItemsCreate: (data: WoraCorbosServiceStoresGetStoresInput, params: RequestParams = {}) =>
      this.request<
        VoloAbpApplicationDtosPagedResultDto1DmApplicationComboxesComboboxItemDtoDmApplicationContractsVersion1160CultureNeutralPublicKeyTokenNull,
        VoloAbpHttpRemoteServiceErrorResponse
      >({
        path: `/api/corbos-service/stores/combobox-items`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Store
     * @name CorbosServiceStoresExcelCreate
     * @request POST:/api/corbos-service/stores/excel
     * @secure
     */
    corbosServiceStoresExcelCreate: (data: WoraCorbosServiceStoresGetStoresInput, params: RequestParams = {}) =>
      this.request<File, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/stores/excel`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Store
     * @name CorbosServiceStoresExcelBatchCreate
     * @request POST:/api/corbos-service/stores/excel-batch
     * @secure
     */
    corbosServiceStoresExcelBatchCreate: (data: WoraCorbosServiceStoresGetStoresInput, params: RequestParams = {}) =>
      this.request<void, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/stores/excel-batch`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Store
     * @name CorbosServiceStoresCreate
     * @request POST:/api/corbos-service/stores
     * @secure
     */
    corbosServiceStoresCreate: (data: WoraCorbosServiceStoresGetStoresInput, params: RequestParams = {}) =>
      this.request<
        VoloAbpApplicationDtosPagedResultDto1WoraCorbosServiceStoresStoreDtoWoraCorbosServiceApplicationContractsVersion1000CultureNeutralPublicKeyTokenNull,
        VoloAbpHttpRemoteServiceErrorResponse
      >({
        path: `/api/corbos-service/stores`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Store
     * @name CorbosServiceStoresDetail
     * @request GET:/api/corbos-service/stores/{id}
     * @secure
     */
    corbosServiceStoresDetail: (id: string, params: RequestParams = {}) =>
      this.request<WoraCorbosServiceStoresStoreDto, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/stores/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Store
     * @name CorbosServiceStoresUpdate
     * @request PUT:/api/corbos-service/stores/{id}
     * @secure
     */
    corbosServiceStoresUpdate: (id: string, data: WoraCorbosServiceStoresStoreUpdateDto, params: RequestParams = {}) =>
      this.request<WoraCorbosServiceStoresStoreDto, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/stores/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Store
     * @name CorbosServiceStoresDelete
     * @request DELETE:/api/corbos-service/stores/{id}
     * @secure
     */
    corbosServiceStoresDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/stores/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Store
     * @name CorbosServiceStoresCreateCreate
     * @request POST:/api/corbos-service/stores/create
     * @secure
     */
    corbosServiceStoresCreateCreate: (data: WoraCorbosServiceStoresStoreCreateDto, params: RequestParams = {}) =>
      this.request<WoraCorbosServiceStoresStoreDto, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/stores/create`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Store
     * @name CorbosServiceStoresAsExcelFileList
     * @request GET:/api/corbos-service/stores/as-excel-file
     * @secure
     */
    corbosServiceStoresAsExcelFileList: (
      query?: {
        DownloadToken?: string;
        FilterText?: string;
        Name?: string;
        Number?: string;
        Address?: string;
        AddressNumber?: string;
        Province?: string;
        City?: string;
        ZipCode?: string;
        VatID?: string;
        FiscalCode?: string;
        /** @format date-time */
        OpeningTimeMin?: string;
        /** @format date-time */
        OpeningTimeMax?: string;
        /** @format date-time */
        ClosingTimeMin?: string;
        /** @format date-time */
        ClosingTimeMax?: string;
        /** @format int64 */
        BusinessPartnerId?: number;
        /** @format int64 */
        ContactId?: number;
        PickupInstructions?: string;
        DeliveryInstructions?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<File, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/stores/as-excel-file`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Store
     * @name CorbosServiceStoresDownloadTokenList
     * @request GET:/api/corbos-service/stores/download-token
     * @secure
     */
    corbosServiceStoresDownloadTokenList: (params: RequestParams = {}) =>
      this.request<WoraCorbosServiceSharedDownloadTokenResultDto, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/stores/download-token`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Store
     * @name CorbosServiceStoresGetStoresComboboxList
     * @request GET:/api/corbos-service/stores/get-stores-combobox
     * @secure
     */
    corbosServiceStoresGetStoresComboboxList: (
      query?: {
        /** @format int64 */
        businessPartnerId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        VoloAbpApplicationDtosListResultDto1DmApplicationComboxesComboboxItemDtoDmApplicationContractsVersion1160CultureNeutralPublicKeyTokenNull,
        VoloAbpHttpRemoteServiceErrorResponse
      >({
        path: `/api/corbos-service/stores/get-stores-combobox`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserStore
     * @name CorbosServiceUserStoresList
     * @request GET:/api/corbos-service/user-stores
     * @secure
     */
    corbosServiceUserStoresList: (
      query?: {
        FilterText?: string;
        /** @format uuid */
        UserId?: string;
        /** @format uuid */
        StoreId?: string;
        Sorting?: string;
        /**
         * @format int32
         * @min 0
         * @max 2147483647
         */
        SkipCount?: number;
        /**
         * @format int32
         * @min 1
         * @max 2147483647
         */
        MaxResultCount?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        VoloAbpApplicationDtosPagedResultDto1WoraCorbosServiceUserStoresUserStoreDtoWoraCorbosServiceApplicationContractsVersion1000CultureNeutralPublicKeyTokenNull,
        VoloAbpHttpRemoteServiceErrorResponse
      >({
        path: `/api/corbos-service/user-stores`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserStore
     * @name CorbosServiceUserStoresCreate
     * @request POST:/api/corbos-service/user-stores
     * @secure
     */
    corbosServiceUserStoresCreate: (data: WoraCorbosServiceUserStoresUserStoreCreateDto, params: RequestParams = {}) =>
      this.request<WoraCorbosServiceUserStoresUserStoreDto, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/user-stores`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserStore
     * @name CorbosServiceUserStoresDetail
     * @request GET:/api/corbos-service/user-stores/{id}
     * @secure
     */
    corbosServiceUserStoresDetail: (id: string, params: RequestParams = {}) =>
      this.request<WoraCorbosServiceUserStoresUserStoreDto, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/user-stores/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserStore
     * @name CorbosServiceUserStoresUpdate
     * @request PUT:/api/corbos-service/user-stores/{id}
     * @secure
     */
    corbosServiceUserStoresUpdate: (
      id: string,
      data: WoraCorbosServiceUserStoresUserStoreUpdateDto,
      params: RequestParams = {},
    ) =>
      this.request<WoraCorbosServiceUserStoresUserStoreDto, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/user-stores/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserStore
     * @name CorbosServiceUserStoresDelete
     * @request DELETE:/api/corbos-service/user-stores/{id}
     * @secure
     */
    corbosServiceUserStoresDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/corbos-service/user-stores/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags WebhookEvent
     * @name WebHookManagementEventsList
     * @request GET:/api/web-hook-management/events
     * @secure
     */
    webHookManagementEventsList: (
      query?: {
        FilterText?: string;
        WebhookName?: string;
        Data?: string;
        Sorting?: string;
        /**
         * @format int32
         * @min 0
         * @max 2147483647
         */
        SkipCount?: number;
        /**
         * @format int32
         * @min 1
         * @max 2147483647
         */
        MaxResultCount?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        VoloAbpApplicationDtosPagedResultDto1WebHookManagementWebhookEventsWebhookEventDtoWebHookManagementApplicationContractsVersion1010CultureNeutralPublicKeyTokenNull,
        VoloAbpHttpRemoteServiceErrorResponse
      >({
        path: `/api/web-hook-management/events`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WebhookEvent
     * @name WebHookManagementEventsDetail
     * @request GET:/api/web-hook-management/events/{id}
     * @secure
     */
    webHookManagementEventsDetail: (id: string, params: RequestParams = {}) =>
      this.request<WebHookManagementWebhookEventsWebhookEventDto, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/web-hook-management/events/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WebhookSendAttempt
     * @name WebHookManagementSendAttemptsList
     * @request GET:/api/web-hook-management/send-attempts
     * @secure
     */
    webHookManagementSendAttemptsList: (
      query?: {
        FilterText?: string;
        Response?: string;
        ResponseStatusCodeMin?: SystemNetHttpStatusCode;
        /** @format uuid */
        WebhookEventId?: string;
        /** @format uuid */
        WebhookSubscriptionId?: string;
        Sorting?: string;
        /**
         * @format int32
         * @min 0
         * @max 2147483647
         */
        SkipCount?: number;
        /**
         * @format int32
         * @min 1
         * @max 2147483647
         */
        MaxResultCount?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        VoloAbpApplicationDtosPagedResultDto1WebHookManagementWebhookSendAttemptsWebhookSendAttemptWithNavigationPropertiesDtoWebHookManagementApplicationContractsVersion1010CultureNeutralPublicKeyTokenNull,
        VoloAbpHttpRemoteServiceErrorResponse
      >({
        path: `/api/web-hook-management/send-attempts`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WebhookSendAttempt
     * @name WebHookManagementSendAttemptsDetail
     * @request GET:/api/web-hook-management/send-attempts/{id}
     * @secure
     */
    webHookManagementSendAttemptsDetail: (id: string, params: RequestParams = {}) =>
      this.request<WebHookManagementWebhookSendAttemptsWebhookSendAttemptDto, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/web-hook-management/send-attempts/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WebhookSendAttempt
     * @name WebHookManagementSendAttemptsResendList
     * @request GET:/api/web-hook-management/send-attempts/resend
     * @secure
     */
    webHookManagementSendAttemptsResendList: (
      query?: {
        /** @format uuid */
        sendAttemptId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/web-hook-management/send-attempts/resend`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags WebhookSubscription
     * @name WebHookManagementSubscriptionsList
     * @request GET:/api/web-hook-management/subscriptions
     * @secure
     */
    webHookManagementSubscriptionsList: (
      query?: {
        FilterText?: string;
        WebhookUri?: string;
        Secret?: string;
        IsActive?: boolean;
        Webhooks?: string;
        Headers?: string;
        Sorting?: string;
        /**
         * @format int32
         * @min 0
         * @max 2147483647
         */
        SkipCount?: number;
        /**
         * @format int32
         * @min 1
         * @max 2147483647
         */
        MaxResultCount?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        VoloAbpApplicationDtosPagedResultDto1WebHookManagementWebhookSubscriptionsWebhookSubscriptionDtoWebHookManagementApplicationContractsVersion1010CultureNeutralPublicKeyTokenNull,
        VoloAbpHttpRemoteServiceErrorResponse
      >({
        path: `/api/web-hook-management/subscriptions`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WebhookSubscription
     * @name WebHookManagementSubscriptionsCreate
     * @request POST:/api/web-hook-management/subscriptions
     * @secure
     */
    webHookManagementSubscriptionsCreate: (
      data: WebHookManagementWebhookSubscriptionsWebhookSubscriptionCreateDto,
      params: RequestParams = {},
    ) =>
      this.request<WebHookManagementWebhookSubscriptionsWebhookSubscriptionDto, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/web-hook-management/subscriptions`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WebhookSubscription
     * @name WebHookManagementSubscriptionsDetail
     * @request GET:/api/web-hook-management/subscriptions/{id}
     * @secure
     */
    webHookManagementSubscriptionsDetail: (id: string, params: RequestParams = {}) =>
      this.request<WebHookManagementWebhookSubscriptionsWebhookSubscriptionDto, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/web-hook-management/subscriptions/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WebhookSubscription
     * @name WebHookManagementSubscriptionsUpdate
     * @request PUT:/api/web-hook-management/subscriptions/{id}
     * @secure
     */
    webHookManagementSubscriptionsUpdate: (
      id: string,
      data: WebHookManagementWebhookSubscriptionsWebhookSubscriptionUpdateDto,
      params: RequestParams = {},
    ) =>
      this.request<WebHookManagementWebhookSubscriptionsWebhookSubscriptionDto, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/web-hook-management/subscriptions/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WebhookSubscription
     * @name WebHookManagementSubscriptionsDelete
     * @request DELETE:/api/web-hook-management/subscriptions/{id}
     * @secure
     */
    webHookManagementSubscriptionsDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/web-hook-management/subscriptions/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags WebhookSubscription
     * @name WebHookManagementSubscriptionsGetAvailableWebhooksCreate
     * @request POST:/api/web-hook-management/subscriptions/get-available-webhooks
     * @secure
     */
    webHookManagementSubscriptionsGetAvailableWebhooksCreate: (params: RequestParams = {}) =>
      this.request<
        VoloAbpApplicationDtosListResultDto1WebHookManagementWebhookSubscriptionsGetAllAvailableWebhooksOutputWebHookManagementApplicationContractsVersion1010CultureNeutralPublicKeyTokenNull,
        VoloAbpHttpRemoteServiceErrorResponse
      >({
        path: `/api/web-hook-management/subscriptions/get-available-webhooks`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WebhookSubscription
     * @name WebHookManagementSubscriptionsAttemptsCreate
     * @request POST:/api/web-hook-management/subscriptions/attempts
     * @secure
     */
    webHookManagementSubscriptionsAttemptsCreate: (
      data: WebHookManagementWebhookSendAttemptsGetWebhookSendAttemptsInput,
      params: RequestParams = {},
    ) =>
      this.request<
        VoloAbpApplicationDtosPagedResultDto1WebHookManagementWebhookSendAttemptsWebhookSendAttemptWithNavigationPropertiesDtoWebHookManagementApplicationContractsVersion1010CultureNeutralPublicKeyTokenNull,
        VoloAbpHttpRemoteServiceErrorResponse
      >({
        path: `/api/web-hook-management/subscriptions/attempts`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WebhookSubscription
     * @name WebHookManagementSubscriptionsEventDetail
     * @request GET:/api/web-hook-management/subscriptions/event/{id}
     * @secure
     */
    webHookManagementSubscriptionsEventDetail: (id: string, params: RequestParams = {}) =>
      this.request<WebHookManagementWebhookEventsWebhookEventDto, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/web-hook-management/subscriptions/event/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WebhookSubscription
     * @name WebHookManagementSubscriptionsReSendDetail
     * @request GET:/api/web-hook-management/subscriptions/re-send/{id}
     * @secure
     */
    webHookManagementSubscriptionsReSendDetail: (id: string, params: RequestParams = {}) =>
      this.request<void, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/web-hook-management/subscriptions/re-send/${id}`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags WebhookSubscription
     * @name WebHookManagementSubscriptionsActivateCreate
     * @request POST:/api/web-hook-management/subscriptions/activate
     * @secure
     */
    webHookManagementSubscriptionsActivateCreate: (
      data: WebHookManagementWebhookSubscriptionsActiveSubscriptionDto,
      params: RequestParams = {},
    ) =>
      this.request<void, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/web-hook-management/subscriptions/activate`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags WebhookSubscription
     * @name WebHookManagementSubscriptionsTestDetail
     * @request GET:/api/web-hook-management/subscriptions/test/{subscriptionId}
     * @secure
     */
    webHookManagementSubscriptionsTestDetail: (subscriptionId: string, params: RequestParams = {}) =>
      this.request<void, VoloAbpHttpRemoteServiceErrorResponse>({
        path: `/api/web-hook-management/subscriptions/test/${subscriptionId}`,
        method: "GET",
        secure: true,
        ...params,
      }),
  };
}
