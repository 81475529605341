import * as types from '../types';
import Wora from '../../api/Wora';


export const hasFeedback = (token: string) => async (dispatch: any) => {
  dispatch({ type: types.LOADING });
  try {
    let result = await Wora.post('/api/corbos-service/public/has-review', { token: token });
    console.log(result);
    dispatch({ type: types.LOAD_REVIEW, payload: result.data })
  } catch (e) {
    dispatch({
      type: types.ERROR, payload: {
        type: 'callremote',
        data: e
      }
    });
  }
}

/**
 * Get data for delivery*/
export const getDeliveryInfo = (token: string) => async (dispatch: any) => {
  try {
    dispatch({ type: types.LOADING })

    const clientinfo = await Wora.get('/api/corbos-service/public/get-delivery?randomCode=' + token);
    if (clientinfo.status == 200) {
      const dat = {
        data: clientinfo.data,
        token
      }
      dispatch({ type: types.SUCCESS_USERINFO, payload: dat })
    } else {
      dispatch({
        type: types.ERROR, payload: {
          type: 'challengeerror',
          data: 'an error occurred, please try again'
        }
      });
    }
  } catch (err) {
    dispatch({
      type: types.ERROR, payload: {
        type: 'challengeerror',
        data: err
      }
    });
  }

}


/***
 * Send delivery date change
 */
export const modifyInfo = (contactId: string, token: string, address: string, addressNumber: string,
  zipCode: string, city: string, province: string, note: string, latitude: number, longitude: number,
  interPhone: string, reception: boolean) => async (dispatch: any) => {
    try {
      dispatch({ type: types.LOADING })

      const body = {
        token: token,
        contactId: contactId,
        address: address,
        addressNumber: addressNumber,
        zipCode: zipCode,
        city: city,
        province: province,
        note: note,
        latitude: latitude,
        longitude: longitude,
        interPhone: interPhone,
        reception: reception
      }
      const clientinfo = await Wora.post('/api/corbos-service/public/update-delivery-address', body);
      if (clientinfo.status == 200 || clientinfo.status == 204) {
        dispatch({ type: types.SUCCESS_MODIFY, payload: {} })
      } else {
        dispatch({
          type: types.ERROR, payload: {
            type: 'challengeerror',
            data: 'an error occurred, please try again'
          }
        });
      }
    } catch (err) {
      console.log(err)
      dispatch({
        type: types.ERROR, payload: {
          type: 'updatedata',
          data: err
        }
      });
    }

  }

export const modifyDate = (token: string, timeFrom: string, timeUntil: string, stepType = 'D') => async (dispatch: any) => {
  try {
    dispatch({ type: types.LOADING });
    /**
     * {
    "token": "string",
    "stepId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    "requestTimeStart": "2023-09-12T09:14:02.158Z",
    "requestTimeEnd": "2023-09-12T09:14:02.158Z"
  }
     */
    const body = {
      token: token,
      stepType: stepType,
      requestTimeStart: timeFrom,
      requestTimeEnd: timeUntil,
    }
    const clientinfo = await Wora.post('/api/corbos-service/public/update-delivery-time', body);

    if (clientinfo.status == 200 || clientinfo.status == 204) {
      dispatch({ type: types.SUCCESS_MODIFY, payload: {} })
    } else {
      dispatch({
        type: types.ERROR, payload: {
          type: 'challengeerror',
          data: 'an error occurred, please try again'
        }
      });
    }
  } catch (err) {
    console.log(err)
    dispatch({
      type: types.ERROR, payload: {
        type: 'updatedata',
        data: err
      }
    });
  }
}

/** Send feedback
 * 
 */
export const sendFeedback = (review: number, description: string, token: string) => async (dispatch: any) => {
  try {
    dispatch({ type: types.LOADING })
    const body = {
      token: token,
      rating: review,
      note: description
    }
    await Wora.post('/api/corbos-service/public/create-review', body);
    dispatch({ type: types.SUCCESS_REVIEW, payload: true })
  } catch (err) {
    console.log(err)
    dispatch({
      type: types.ERROR, payload: {
        type: 'feedbackerror',
        data: err
      }
    });
  }

}

/***
 * Set time in PWA
 */
export const setTime = (timeFrom: string | undefined | null, timeUntil: string | undefined | null) => (dispatch: any) => {
  dispatch({ type: types.SETTIME, payload: { timeFrom: timeFrom, timeUntil: timeUntil } })
}

export const clearerror = () => async (dispatch: any) => {
  dispatch({ type: types.CLEARERROR });
}

export const clearsuccess = () => async (dispatch: any) => {
  dispatch({ type: types.CLEARSUCCESS });
}

export const confirmInfo = (fields: string, token: string, sessionName: string,
  time_from: string, time_until: string, date: string) => async (dispatch: any) => {
    try {
      const clientinfo = await Wora.get('/api/corbos-service/public/get-delivery?randomCode=' + token);
      dispatch({ type: types.CONFIRMLOADING })
      const body = {
        operation: 'update_data',
        pwa_token: token,
        fields,
        sessionName,
      }
      await Wora.post('/webservice.php', body);
      const results = {
        time_from,
        time_until,
        date
      }
      dispatch({ type: types.SUCCESS_CONFIRM, payload: results })
    } catch (err) {
      dispatch({
        type: types.ERROR, payload: {
          type: 'confirmerror',
          data: err
        }
      });
    }
  }

/**
 * Get Coodinate driver
 * @param {} token 
 * @param {*} type 
 * @returns 
 */
export const getCoordinates = (token: string, type: string) => async (dispatch: any) => {
  try {

    const clientinfo = await Wora.get('/api/corbos-service/public/get-driver-location?token=' + token);

    if (clientinfo.status == 200) {
      const dat = {
        data: clientinfo.data,
        // sessionName: response.result.sessionName,
        token
      }
      dispatch({ type: types.DRIVER_COORDINATE, payload: dat })
    } else {
      dispatch({
        type: types.ERROR, payload: {
          type: 'challengeerror',
          data: 'an error occurred, please try again'
        }
      });
    }
  } catch (err) {
    dispatch({
      type: types.ERROR, payload: {
        type: 'challengeerror',
        data: err
      }
    });
  }
}

//NO GLOBAL STATE !
export const confirm_STATELESS = (token: string, timestart: string, timeend: string) => {
  const body = {
    token: token,
    timeStart: timestart,
    timeEnd: timeend
  }
  return Wora.post('/api/corbos-service/public/update-eta-window-time', body).then((resp: any) => {
    let ret = 0;
    if (resp.status == 200 || resp.status == 204) {
      ret = 0
    }
    else {
      ret = -1
    }
    return ret
  }).catch(exception => {
    return -1
  })


}
export const modifyInfo_STATELESS = (contactId: string, token: string, address: string, addressNumber: string,
  zipCode: string, city: string, province: string, note: string, latitude: number, longitude: number,
  interPhone: string, reception: boolean, IsAddressChange = false) => {

  const body = {
    token: token,
    contactId: contactId,
    address: address,
    addressNumber: addressNumber,
    zipCode: zipCode,
    city: city,
    province: province,
    note: note,
    latitude: latitude,
    longitude: longitude,
    interPhone: interPhone,
    reception: reception,
    IsAddressChange : IsAddressChange
  }

  return Wora.post('/api/corbos-service/public/update-delivery-address', body).then((resp: any) => {
    let ret = 0;
    if (resp.status == 200 || resp.status == 204) {
      ret = 0
    }
    else {
      ret = -1
    }
    return ret
  }).catch(exception => {
    return -1
  })
}

export const modifyDate_STATELESS = (token: string, timeFrom: string, timeUntil: string, stepType = 'D') => {
  const body = {
    token: token,
    stepType: stepType,
    requestTimeStart: timeFrom,
    requestTimeEnd: timeUntil,
  }

  return Wora.post('/api/corbos-service/public/update-delivery-time', body).then((resp: any) => {
    let ret = 0;
    if (resp.status == 200 || resp.status == 204) {
      ret = 0
    }
    else {
      ret = -1
    }
    return ret
  }).catch(exception => {
    return -1
  })
}