import React, { useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import editicon from '../icons/editdata.svg';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'row',
        width: '100%',
        backgroundColor: '#eeeeee',
        paddingRight: 10,
        borderRadius: 10,
        marginTop: 10
      },
      textField: {
        width: '100%',
        position: 'relative',
        border: '0px',
        paddingTop: 10,
        paddingBottom: 30,
        paddingLeft: 10,
        paddingRight: 10,
        backgroundColor: '#eeeeee',
        borderRadius: 50,
      },
      button: {
          backgroundColor: theme.palette.primary.light,
          width: 50,
          padding: 0,
          height: 50,
          border: 0,
          borderTopRightRadius: 10,
          borderBottomRightRadius: 10
      },
      arrow: {
          color: '#fff'
      },
      roots: {
          width: '80%'
      },
      label: {
        color: 'grey',
        fontWeight: '400',
        fontSize: 14,
        marginLeft: 10
    },
    iconsocial: {
      paddingTop: 20,
      width: 15
    }
}));

export default function DatePickers({label, id, value, onChange}) {
  const classes = useStyles();
const textInput = useRef(null);
  return (
    <div className={classes.roots}>
    <label htmlFor={id} className={classes.label}>{label}</label>
    <div className={classes.container}>
      <textarea
      id={id}
      rows={3}
        label={label}
        placeholder=""
        type="text"
        ref={textInput}
        className={classes.textField}
        value={value}
        onChange={onChange}
      />
       <img onClick={() => textInput.current.focus()} src={editicon} className={classes.iconsocial} alt="Loading"/>
    </div>
    </div>
  );
}
