import '../App.css';
import { makeStyles, Grid, Container } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import Layout from '../layout';
import Location from '@material-ui/icons/ChevronLeft';
import Button from './controls/RoundButton';
import TimePicker from './timepickertwo';
import Datepicker from './materialdatepicker';
import moment, { Moment } from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { modifyDate, clearsuccess, getDeliveryInfo, modifyDate_STATELESS, confirm_STATELESS } from '../store/actions/appactions';
import { useHistory, useLocation, useParams } from "react-router-dom";
import DatePicker from 'react-datepicker';
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import { WoraCorbosServiceDeliveriesDeliveryDto, WoraCorbosServiceDeliveryStepsStepType } from '../proxy/Api';

function roundUpToNearest15Minutes(momentObj: Moment) {

  if (momentObj == null)
    return momentObj;
  let remainder = 0
  if (momentObj.minute() % 15 != 0) {
    remainder = 15 - momentObj.minute() % 15;
  }

  return momentObj.add(remainder, 'minutes').startOf('minute');
}


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  icons: {
    display: 'flex',
    alignItems: 'center',
    height: 100,
    justifyContent: 'center'
  },
  iconstwo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    position: 'relative',
    height: 100
  },
  rounddiv: {
    height: '15px',
    width: '15px',
    borderRadius: '7.5px',
    backgroundColor: 'orange'
  },
  heading: {
    textAlign: 'center',
    color: '#909090',
    fontSize: 14
  },
  headingtwo: {
    fontWeight: 500,
    fontSize: 20,
    maxWidth: '80%',
    textAlign: 'center',
    color: '#000',
  },
  headingtwotwo: {
    fontWeight: 500,
    fontSize: 16,
    maxWidth: '80%',
    textAlign: 'center',
    color: '#000',
  },
  buttoncontainer: {
    width: '90%',
    paddingTop: 50,
    textAlign: 'center'
  },
  headingsix: {
    fontSize: 16,
    fontWeight: 800,
    lineHeight: 0,
    color: '#000'
  },
  spantext: {
    fontSize: 16,
    fontWeight: 400,
    maxWidth: '80%',
    textAlign: 'center',
    color: '#000',
    marginTop: '-10px'
  },
  spantexttwo: {
    fontSize: 16,
    marginTop: 20,
    fontWeight: 400,
    color: '#7D7D7D'
  },
  headingone: {
    marginBottom: '-20px'
  },
  icontext: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 12,
    fontWeight: 800
  },
  iconsocial: {
    fontSize: 12,
    marginRight: 5
  },
  typocontainer: {
    width: '90%'
  },
  rangediv: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 20,
  },
  errorcontainer: {
    color: 'red'
  }
}));

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

function ChangeDeliveryDateScreen(props: any) {
  const dispatch = useDispatch();
  const { token } = useParams<{ [key: string]: string }>();
  const app = useSelector((state: any) => state.app)
  const classes = useStyles();
  const location = useLocation();

  const data = useSelector((state: any) => state.app?.userinfo == null ? null : state.app?.userinfo as WoraCorbosServiceDeliveriesDeliveryDto);
  const delivery = data?.steps?.find(elemento => elemento.stepType === WoraCorbosServiceDeliveryStepsStepType.Value1);
  const isAboutToArrive = delivery?.status == 4;
  const isCompletedSuccessfully = delivery?.status == 5;
  const isConfirmed = (delivery as any)?.windowConfirmed == true || (delivery as any)?.changedDateByUser == true;


  const [timeFrom, setTimeFrom] = useState(roundUpToNearest15Minutes(moment(delivery?.startEtaWindow)))
  const [timeUntil, setTimeUntil] = useState(roundUpToNearest15Minutes(moment(delivery?.endEtaWindow)))




  // const timeFrom = roundUpToNearest15Minutes(moment("2024-02-19T18:00:00"))
  // const timeUntil = roundUpToNearest15Minutes(moment("2024-02-19T21:00:00"))

  // Time values to control the inputs 
  const [from, setFrom] = useState(moment(timeFrom).format());
  const [to, setTo] = useState(moment(timeUntil).hours(21).minutes(0).format());


  const [genericError, setGenericError] = useState<boolean | string>(false)
  const [error, setError] = useState<any>(false);

  //the date part
  const [date, setDate] = useState(moment(timeFrom).format());

  //to control the popups
  const [isOpenPopupTimeFrom, setIsOpenPopupTimeFrom] = useState(false);
  const [isOpenPopupTimeTo, setIsOpenPopupTimeTo] = useState(false);

  //The "value" of the DatePicker components
  const [startDateTo, setStartDateTo] = useState(new Date());
  const [startDateFrom, setStartDateFrom] = useState(new Date());


  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const [open, setOpen] = useState(false);

  const today = moment(date).isSame(new Date(), 'day') ? true : false;

  //TODO: i believe this can be more clean
  const [dalleminhour, setDalleminhour] = useState(today ? parseInt(moment(timeFrom).format('HH')) : 10);
  const [dalleminmin, setDalleminmin] = useState(today ? parseInt(moment(timeFrom).format('mm')) : 0);
  const [alleminhour, setAlleminhour] = useState(parseInt(moment(timeFrom).add(1, 'hours').format('HH')));
  const [alleminmin, setAlleminmin] = useState(parseInt(moment(timeFrom).add(1, 'hours').format('mm')));

  const [shouldDisableTimePickers, setShouldDisableTimePickers] = useState(false)
  const [initOk, setInitOk] = useState(false)

  function MaxMoment(m1: Moment, m2: Moment) {
    if (m1.isAfter(m2)) {
      return m1;
    }
    else {
      return m2;
    }
  }
  useEffect(() => {
    if (token !== undefined) {
      dispatch(getDeliveryInfo(token));
    }
  }, [token, dispatch]);

  useEffect(() => {
    //if the driver is driving to deliver this delivery, then redirect to tracking
    if (isAboutToArrive == true)
      history.push("/tracking/" + token);
    if (isCompletedSuccessfully == true)
      history.push('/completed/' + token);
  }, [isAboutToArrive, isCompletedSuccessfully])

  //very bad design
  useEffect(() => {
    setTimeFrom(roundUpToNearest15Minutes(moment(delivery?.startEtaWindow)));
    setTimeUntil(roundUpToNearest15Minutes(moment(delivery?.endEtaWindow)));
    setInitOk(true)
  }, [delivery])
  //very bad design. It works, but needs a better way. In the next project never use Redux for this. This is NOT a PWA
  useEffect(() => {
    if (initOk == true) {
      setInitOk(false)
      setFrom(moment(timeFrom).format());
      setTo(moment(timeUntil).hours(21).minutes(0).format());
      setDate(moment(timeFrom).format());
      setStartDateTo(new Date());
      setStartDateFrom(new Date());
      setDalleminhour(today ? parseInt(MaxMoment(moment(timeFrom), moment(new Date())).format('HH')) : 10);
      setDalleminmin(today ? parseInt(MaxMoment(moment(timeFrom), moment(new Date())).format('mm')) : 0);
      setAlleminhour(parseInt(moment(timeFrom).add(1, 'hours').format('HH')));
      setAlleminmin(parseInt(moment(timeFrom).add(1, 'hours').format('mm')));
      console.log("DIFF:", timeFrom.diff(moment(new Date()), "minutes"))

    }
  }, [initOk])

  useEffect(() => {
    console.log("Date changed")
    if ((moment(from)).diff(moment(new Date()), "minutes") < 0 && today) {
      setError({
        type: 'dalle',
        value: "Seleziona un altro orario"
      })
    }
    else {
      setError(false)
    }
  }, [from, date])

  let history = useHistory();


  useEffect(() => {
    // if the resulting range il less than 1 hour, then set TO so we have a 1 hour range
    if (moment(to).diff(moment(from), "minutes") < 60) {
      setTo(moment(from).add(1, "hours").format());
      setStartDateTo(moment(from).add(1, "hours").toDate())
    }
  }, [from])

  // useEffect(() => {
  //   if (!today) {
  //     setDalleminhour(10);
  //     setDalleminmin(0);
  //   } else if (today) {
  //     setDalleminhour(parseInt(moment(timeFrom).format('HH')));
  //     setDalleminmin(parseInt(moment(timeFrom).format('mm')));
  //   }
  // }, [date, timeFrom, today])

  useEffect(() => {
    setGenericError(false)
    if (moment(to).diff(moment(from), "minutes") < 60) {
      setGenericError("La fascia oraria deve essere di almeno 1 ora !")
    }
  }, [from, to])


  useEffect(() => {
    setAlleminhour(parseInt(moment(from).add(1, "h").format('HH')));
    setAlleminmin(parseInt(moment(from).add(1, "h").format('mm')));
  }, [from])


  const handleChangeFromTime = (e: any) => {
    console.log(e)
    setIsOpenPopupTimeTo(!isOpenPopupTimeTo);
    setStartDateFrom(e);
    setFrom(moment(e).format());
    //setTo(moment(e).add(1, 'hours'))
  };
  const handleChangeToTime = (e: any) => {
    setIsOpenPopupTimeFrom(!isOpenPopupTimeFrom);
    setStartDateTo(e);
    setTo(moment(e).format());
  };


  const handleClickFromTimePicker = (e: any) => {
    e.preventDefault();
    setIsOpenPopupTimeFrom(false);
    setIsOpenPopupTimeTo(!isOpenPopupTimeTo);
  };
  const handleClickToTimePicker = (e: any) => {
    e.preventDefault();
    setIsOpenPopupTimeTo(false)
    setIsOpenPopupTimeFrom(!isOpenPopupTimeFrom);
  };



  // console.log(moment(app?.userinfo?.time_from).format('HH:mm'));
  // console.log(to);

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);



  // useEffect(() => {
  //   if (moment(date).isSame(new Date(), 'day')) {
  //     setFrom(moment(timeFrom).format());
  //   }
  // }, [date, timeFrom])

  const onSubmit = (e: any) => {
    e.preventDefault();
    setError(false)


    const requireddate = moment(date).format("DD-MM-YYYY");
    const requiredfrom = moment(from).format('HH:mm');
    const requireduntil = moment(to).format('HH:mm');

    if (moment(`${requireddate} ${requireduntil}`).isBefore(`${requireddate} ${requiredfrom}`)) {
      setError({
        type: 'alle',
        value: "Non puoi inserire un'ora prima di **Dalle**"
      })
    }
    else if (!to || !from) {
      setError({
        type: 'all',
        value: ""
      })
    } else {

      const dateobj = moment(date)
      let time_from = moment(from).set({ year: dateobj.year(), month: dateobj.month(), day: dateobj.day() }).utc().format();
      let time_until = moment(to).set({ year: dateobj.year(), month: dateobj.month(), day: dateobj.day() }).utc().format();
      console.log("Compare:")
      console.log(delivery?.startEtaWindow)
      console.log(delivery?.endEtaWindow)

      console.log(time_from)
      console.log(time_until)
  
      if (dateobj.isAfter(moment(new Date()),'D'))
      {
        time_from = moment(dateobj).set({ "h": 9, "m": 0, "s": 0, "ms": 0 }).utc().format();
        time_until = moment(dateobj).set({ "h": 21, "m": 0, "s": 0, "ms": 0 }).utc().format();
      }
      console.log(time_from)
      console.log(time_until)

      modifyDate_STATELESS(token, time_from, time_until).then(x => {
        if (x == 0) {
          const isTimeSpanChanged = (delivery?.startEtaWindow !== time_from ) || (delivery?.endEtaWindow !== time_until)
          history.push("/address/" + token, { datehaschanged: true });
          //success
          // confirm_STATELESS(token, time_from!, time_until!).then(c => {
          //   if(c==0)
          //   {
          //     history.push("/thankyouscreen/" + token, {changetype:"timeOnly"});
          //   }
          //   else
          //   {
          //     window.alert("ERR")
          //   }
          // })
        }
        else {
          history.push("/err")
        }
      })

    }
  }
  useEffect(() => { console.log(error) }, [error])

  useEffect(() => {
    //TODO: confirm is missing (?)
    if (app.modifysuccess) {
      dispatch(clearsuccess());
      history.push("/thankyouscreen/" + token);
    }
  }, [app.modifysuccess, token, dispatch, history])
  const isTooLate = new Date().getHours() >= 21;
  return (
    <Layout>
      <div className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={2} className={classes.icons}>
            <Location onClick={() => props.history.goBack()} />
          </Grid>
          <Grid item xs={8} className={classes.iconstwo}>
            <h2 className={windowDimensions.height <= 568 ? classes.headingtwotwo : classes.headingtwo}>MODIFICA ORARIO </h2>
          </Grid>
          <Grid item xs={2} className={classes.icons} />
        </Grid>
        <span className={classes.spantext}>E' possibile posticipare l'ordine al giorno successivo oppure indicare una fascia di consegna diversa per oggi</span>
        <Container>
          <Grid container>
            <Grid item xs={12}>
              <Datepicker
                onOpen={() => { }}
                onClick={() => {
                  setOpen(true);
                  setIsOpenPopupTimeTo(false);
                  setIsOpenPopupTimeFrom(false);
                }}
                value={date} open={open} onClose={() => setOpen(false)} onChange={(value: any) => setDate((new Date(value)).toString())}
                onNextDaySelected={(isSelected: boolean) => {
                  setError(false)
                  if (isSelected) {
                    setShouldDisableTimePickers(true)
                  }
                  else {
                    setShouldDisableTimePickers(false)
                  }
                }} label='Data' />
            </Grid>
            <Grid item xs={12}>
              {!shouldDisableTimePickers && <TimePicker onBlur={() => {
                //setIsOpenPopupTimeFrom(false)
              }} onClick={(e: any) => handleClickFromTimePicker(e)}
                error={['dalle', 'all'].includes(error?.type)}
                helperText={(['dalle', 'all'].includes(error?.type)) ? error?.value : null}
                value={from}
                onChange={(value: any) => value ? setFrom((new Date(value)).toString()) : ''} label='Dalle' />}
              {isOpenPopupTimeTo && (
                <div style={{ position: 'absolute', zIndex: 999 }}>
                  <DatePicker
                    selected={startDateFrom}
                    onChange={(e) => handleChangeFromTime(e)}
                    showTimeSelect
                    onClickOutside={() => {
                      setIsOpenPopupTimeTo(false)
                    }}
                    isClearable
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Dalle"
                    timeFormat="HH:mm"
                    minTime={setHours(setMinutes(new Date(), dalleminmin), dalleminhour)}
                    maxTime={setHours(setMinutes(new Date(), 0), 20)}//TODO: Bug if dallemimmin is > than 20
                    inline />
                </div>

              )}
            </Grid>
            <Grid item xs={12}>
              {!shouldDisableTimePickers && <TimePicker onBlur={() => {
                //setIsOpenPopupTimeTo(false)
              }} onClick={(e: any) => handleClickToTimePicker(e)}
                error={['alle', 'all'].includes(error?.type)}
                helperText={(['alle', 'all'].includes(error?.type)) ? error?.value : null}
                value={to} label='Alle'
                onChange={(value: any) => value ? setTo((new Date(value)).toString()) : ''} />}
              {isOpenPopupTimeFrom && (
                <div style={{ position: 'absolute', zIndex: 999 }}>
                  <DatePicker
                    selected={startDateTo}
                    onChange={(e) => handleChangeToTime(e)}
                    closeOnScroll={true}
                    isClearable
                    showTimeSelect
                    onClickOutside={() => {
                      setIsOpenPopupTimeFrom(false)
                    }}
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Alle"
                    timeFormat="HH:mm"
                    minTime={setHours(setMinutes(new Date(), alleminmin), alleminhour)}
                    maxTime={setHours(setMinutes(new Date(), 0), 21)}
                    inline />
                </div>

              )}
            </Grid>
          </Grid>
        </Container>
        <span className={classes.errorcontainer}>{genericError}</span>

        <div className={classes.buttoncontainer}>
          <span style={{ color: 'red', textAlign: 'center' }}>{app?.error?.type === 'updatedata' && app?.error?.data === 'Non puoi modificare questo ordine!' ? app?.error?.data : null}</span>
          {isTooLate && <span style={{ color: 'red', textAlign: 'center' }}>Si prega di contattare il supporto tecnico</span>}
          {isConfirmed && <span style={{ color: 'red', textAlign: 'center' }}>I dati sono già stati confermati</span>}
          <Button disabled={error || isTooLate || isConfirmed}
            onClick={(e: any) => onSubmit(e)} loading={app.confirm} text="Conferma orario" variant="contained" link='/confirm' />
        </div>
      </div>
    </Layout>


  )
}

export default ChangeDeliveryDateScreen;
