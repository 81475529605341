import React, { Fragment, useEffect, useRef } from "react";

import DateFnsUtils from '@date-io/date-fns';
import itLocale from "date-fns/locale/it";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';
import { makeStyles, TextField } from '@material-ui/core';
import moment from 'moment';
import Calender from '@material-ui/icons/CalendarToday';


const useStyles = makeStyles((theme) => ({
  datePicker: {
    '& .MuiPickersModal-dialog': {
      textTransform: 'uppercase'
    }
  }
}))
function disableWeekends(date) {
  if (date.getDay() == 0 || date.getDay() == 6) return true;
}
function getNextDayIgnoringWeekends(date) {
  const dateobj = moment(date)

  console.log("NEXTDAY! for: ",dateobj.day())
  if (dateobj.day() >= 1 && dateobj.day() <= 4) {
    dateobj.add(1, "d")
  }
  else if (dateobj.day() == 5) {
    dateobj.add(3, "d")
  }
  return dateobj
}

function BasicTimePicker({ value, onChange, onClick, label, open, onOpen, onClose, onNextDaySelected }) {
  const classes = useStyles();

  const baseDateRef = useRef(moment(value));

  //TODO: please move the maxdate calculation logic in the component that uses this component. onNextDaySelected shouldn't exist ! instead do the comparison in the parent !
  const maxdate = getNextDayIgnoringWeekends(Date.parse(baseDateRef.current))
  const mindate = moment(Date.parse(baseDateRef.current))

  useEffect(()=>{
    if (moment(value).date() === maxdate.date())
    {
      onNextDaySelected(true)
    }
    else
    {
      onNextDaySelected(false)
    }
  },[value])
  return (
    <Fragment>
      {/* {"MINDATE"}{JSON.stringify(mindate)}<br/>
      {"VAL"}{JSON.stringify(value)} */}

      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={itLocale}>
        <KeyboardDatePicker
          autoOk
          
          minDate={mindate}
          shouldDisableDate={disableWeekends}

          maxDate={maxdate}
          fullWidth
          variant="inline"
          inputVariant="outlined"
          format="dd/MM/yyyy"
          margin="normal"
          id="date-picker"
          label={label}
          value={value}
          onChange={onChange}
          KeyboardButtonProps={{
            'aria-label': 'change date'
          }}
          open={open}
          onOpen={onOpen}
          onClose={onClose}
          DialogProps={{ className: classes.datePicker }}
          TextFieldComponent={(props) => (
            <TextField {...props} onFocus={onClick} />
          )}
          keyboardIcon={<Calender onClick={onClick} />}
        />
      </MuiPickersUtilsProvider>

    </Fragment>
  );
}

export default BasicTimePicker;