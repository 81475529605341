export interface IConfig {
    CAN_POSTPONE_NEXT_DAY_FROM_HOMEPAGE:boolean,
    CAN_CHANGE_LOCATION: boolean,
    CAN_POSTPONE_TIME: boolean,
    CAN_CHANGE_ADDRESS: boolean,
    CITY_LIMIT: string,
    CAPS_LIMIT: Array<number>
}
export const limitations: { [id: string] : IConfig; } = {};
//FOR PRODUCTION !
//zara
limitations["3a0da0d6-8dba-338e-f828-79e46ef33349"] = { //CURRENT !
    CAN_POSTPONE_NEXT_DAY_FROM_HOMEPAGE:true,
    CAN_CHANGE_LOCATION: true,//Deprecated
    CAN_POSTPONE_TIME: false,
    CAN_CHANGE_ADDRESS: false,
    CITY_LIMIT: "Milano",
    CAPS_LIMIT: [20121,20122,20123,20124,20125,20126,20127,20128,20129,20131,20132,20133,20134,20135,20136,20137,20138,20139,20141,20142,20143,20144,20145,20146,20147,20148,20149,20151,20152,20153,20154,20155,20156,20157,20158,20159,20161,20162]

 };
 //decatlhon
limitations["3a110bdd-5b34-9f3c-ee59-80194350543b"] = { 
    CAN_POSTPONE_NEXT_DAY_FROM_HOMEPAGE:true,
    CAN_CHANGE_LOCATION: true,
    CAN_POSTPONE_TIME: true,
    CAN_CHANGE_ADDRESS: true,
    CITY_LIMIT: "Milano",
    CAPS_LIMIT: [20121,20122,20123,20124,20125,20126,20127,20128,20129,20131,20132,20133,20134,20135,20136,20137,20138,20139,20141,20142,20143,20144,20145,20146,20147,20148,20149,20151,20152,20153,20154,20155,20156,20157,20158,20159,20161,20162]

 };
//decatlhon
limitations["3a10de8b-6e05-fdec-54c8-714e3dd5bc46"] = { 
    CAN_POSTPONE_NEXT_DAY_FROM_HOMEPAGE:true,
    CAN_CHANGE_LOCATION: true,
    CAN_POSTPONE_TIME: true,
    CAN_CHANGE_ADDRESS: true,
    CITY_LIMIT: "Milano",
    CAPS_LIMIT: [20121,20122,20123,20124,20125,20126,20127,20128,20129,20131,20132,20133,20134,20135,20136,20137,20138,20139,20141,20142,20143,20144,20145,20146,20147,20148,20149,20151,20152,20153,20154,20155,20156,20157,20158,20159,20161,20162]

 };
 //Nespresso BTQ
 limitations["3a0c8964-1847-f7df-6105-c52b8fce1070"] = { 
   CAN_POSTPONE_NEXT_DAY_FROM_HOMEPAGE:true,
   CAN_CHANGE_LOCATION: true,
   CAN_POSTPONE_TIME: true,
   CAN_CHANGE_ADDRESS: true,
   CITY_LIMIT: "Milano",
   CAPS_LIMIT: [20121,20122,20123,20124,20125,20126,20127,20128,20129,20131,20132,20133,20134,20135,20136,20137,20138,20139,20141,20142,20143,20144,20145,20146,20147,20148,20149,20151,20152,20153,20154,20155,20156,20157,20158,20159,20161,20162]
};
//Nespresso Liberty
limitations["3a0c85ee-2416-00ec-9fd9-12a030442c71"] = { 
   CAN_POSTPONE_NEXT_DAY_FROM_HOMEPAGE:true,
   CAN_CHANGE_LOCATION: true,
   CAN_POSTPONE_TIME: true,
   CAN_CHANGE_ADDRESS: true,
   CITY_LIMIT: "Milano",
   CAPS_LIMIT: [20121,20122,20123,20124,20125,20126,20127,20128,20129,20131,20132,20133,20134,20135,20136,20137,20138,20139,20141,20142,20143,20144,20145,20146,20147,20148,20149,20151,20152,20153,20154,20155,20156,20157,20158,20159,20161,20162]
};
//Nespresso Milano Bouqtique Babila
limitations["3a0c7f90-8335-7c8d-e21e-920c0cae8d14"] = { 
   CAN_POSTPONE_NEXT_DAY_FROM_HOMEPAGE:true,
   CAN_CHANGE_LOCATION: true,
   CAN_POSTPONE_TIME: true,
   CAN_CHANGE_ADDRESS: true,
   CITY_LIMIT: "Milano",
   CAPS_LIMIT: [20121,20122,20123,20124,20125,20126,20127,20128,20129,20131,20132,20133,20134,20135,20136,20137,20138,20139,20141,20142,20143,20144,20145,20146,20147,20148,20149,20151,20152,20153,20154,20155,20156,20157,20158,20159,20161,20162]

};

//FOR TEST PURPOSES THESE ARE PREFERRED !
 //TESTING PURPOSES
 limitations["GENERICMILAN"] = { 
   CAN_POSTPONE_NEXT_DAY_FROM_HOMEPAGE:true,
   CAN_CHANGE_LOCATION: true,//Deprecated
   CAN_POSTPONE_TIME: false,
   CAN_CHANGE_ADDRESS: false,
   CITY_LIMIT: "Milano",
   CAPS_LIMIT: [20121,20122,20123,20124,20125,20126,20127,20128,20129,20131,20132,20133,20134,20135,20136,20137,20138,20139,20141,20142,20143,20144,20145,20146,20147,20148,20149,20151,20152,20153,20154,20155,20156,20157,20158,20159,20161,20162]
};
limitations["GENERICROME"] = { 
   CAN_POSTPONE_NEXT_DAY_FROM_HOMEPAGE:true,
   CAN_CHANGE_LOCATION: true,//Deprecated
   CAN_POSTPONE_TIME: false,
   CAN_CHANGE_ADDRESS: false,
   CITY_LIMIT: "Roma",
   CAPS_LIMIT: [136, 141, 142, 144, 145, 146, 147, 149, 151, 152, 153, 154, 157, 158, 159, 
      161, 162, 164, 165, 167, 176, 179, 181, 182, 183, 184, 185, 186, 187, 191, 
      192, 193, 195, 196, 197, 198, 199]
};



limitations["3a0c7f90-8335-7c8d-e21e-920c0cae8d14"] = { 
   CAN_POSTPONE_NEXT_DAY_FROM_HOMEPAGE:true,
   CAN_CHANGE_LOCATION: true,
   CAN_POSTPONE_TIME: true,
   CAN_CHANGE_ADDRESS: true,
   CITY_LIMIT: "Milano",
   CAPS_LIMIT: [20121,20122,20123,20124,20125,20126,20127,20128,20129,20131,20132,20133,20134,20135,20136,20137,20138,20139,20141,20142,20143,20144,20145,20146,20147,20148,20149,20151,20152,20153,20154,20155,20156,20157,20158,20159,20161,20162]
};
limitations["3a10d861-567c-ff27-b219-a938da6e0454"] = { 
   CAN_POSTPONE_NEXT_DAY_FROM_HOMEPAGE:true,
   CAN_CHANGE_LOCATION: true,
   CAN_POSTPONE_TIME: true,
   CAN_CHANGE_ADDRESS: false,
   CITY_LIMIT: "Milano",
   CAPS_LIMIT: [20121,20122,20123,20124,20125,20126,20127,20128,20129,20131,20132,20133,20134,20135,20136,20137,20138,20139,20141,20142,20143,20144,20145,20146,20147,20148,20149,20151,20152,20153,20154,20155,20156,20157,20158,20159,20161,20162]
};
limitations["3a0da0e3-e758-a829-6625-7f3d55f67796"] = { 
   CAN_POSTPONE_NEXT_DAY_FROM_HOMEPAGE:true,
   CAN_CHANGE_LOCATION: true,
   CAN_POSTPONE_TIME: true,
   CAN_CHANGE_ADDRESS: false,
   CITY_LIMIT: "Milano",
   CAPS_LIMIT: [20121,20122,20123,20124,20125,20126,20127,20128,20129,20131,20132,20133,20134,20135,20136,20137,20138,20139,20141,20142,20143,20144,20145,20146,20147,20148,20149,20151,20152,20153,20154,20155,20156,20157,20158,20159,20161,20162]
};
