import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        width: '100%',
        backgroundColor: '#fff',
      },
      textField: {
        width: '100%',
        position: 'relative',
        border: '1px solid #DADADA',
        padding: 20,
        backgroundColor: '#fff',
        borderRadius: 10,
        fontSize: 14,
        fontWeight: '400'
      },
      button: {
          backgroundColor: theme.palette.primary.light,
          width: 50,
          padding: 0,
          height: 50,
          border: 0,
          borderTopRightRadius: 10,
          borderBottomRightRadius: 10
      },
      arrow: {
          color: '#fff'
      },
      roots: {
          width: '80%'
      },
      label: {
          color: 'grey',
          fontWeight: '500',
          fontSize: 16,
          marginLeft: 10
      }
}));

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

export default function DatePickers({label, id, onChange, value}) {
  const classes = useStyles();
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());


useEffect(() => {
  function handleResize() {
    setWindowDimensions(getWindowDimensions());
  }

  window.addEventListener('resize', handleResize);
  return () => window.removeEventListener('resize', handleResize);
}, []);

  return (
    <div className={classes.roots}>
    <label htmlFor={id} className={classes.label}>{label}</label>
    <div className={classes.container}>
      <textarea
      id={id}
      rows={windowDimensions.height <= 667 ? 4 : 5}
        label={label}
        placeholder=""
        value={value}
        onChange={onChange}
        type="text"
        className={classes.textField}
      />
    </div>
    </div>
  );
}
