import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import editicon from '../icons/calender.svg';
import editicontwo from '../icons/thankyoucalender.svg';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    width: '90%',
    marginBottom: 20,
    backgroundColor: '#eeeeee',
    height: 50,
    borderRadius: 25,
    border: '0px',
  },
  containertwo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    width: '90%',
    marginBottom: 20,
    backgroundColor: '#eeeeee',
    height: 45,
    borderRadius: 25,
    border: '0px',
  },
  textField: {
    width: '100%',
    position: 'relative',
    
   
    backgroundColor: '#eeeeee',
    padding: 10
  },
  word: {
    color: '#000 !important',
    fontSize: 16,
    marginLeft: 10
  },
  iconsocial: {
    width: 20
  }
}));

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

export default function TimePickers({color, words, calender}) {
  const classes = useStyles();
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());


  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
  
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return (
    <div className={windowDimensions.height <= 568 ? classes.containertwo : classes.container}>
   {color === 'black' ?  
<img src={editicontwo} className={classes.iconsocial} alt="editicon"/> :  
<img src={editicon} className={classes.iconsocial} alt="socialicon"/>}
<span className={classes.word}>{words ? words : '-'}</span>
   </div>
  );
}
