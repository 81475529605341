import '../App.css';
import { makeStyles } from '@material-ui/core';
import React from 'react'
import Layout from '../layout';
import Person from '../assets/complete.png';



const useStyles = makeStyles((theme) => ({
   root: {
       display: 'flex',
       flexDirection: 'column',
       alignItems: 'center',
       justifyContent: 'center',
       flex: 1
   },
   icons: {
       display: 'flex',
       flexDirection: 'row',
       alignItems: 'flex-start',
       justifyContent: 'space-between',
       width: '90%',
       marginTop: 20,
       marginBottom: 20
   },
   iconss: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    width: '90%',
    height: 80,
    marginTop: 50
},
   rounddiv: {
       height: '15px',
       width: '15px',
       borderRadius: '7.5px',
       backgroundColor: 'orange'
   },
   heading: {
       textAlign: 'center',
       color: '#909090',
       fontSize: 15,
       maxWidth: 250,
       fontWeight: 500
   },
   headingtwo: {
    fontWeight: 700,
    fontSize: 24,
    maxWidth: '80%',
    textAlign: 'center',
    color: '#393939',
    marginTop: '-10px'
   },
   headingtwotwo: {
    fontWeight: 700,
    fontSize: 20,
    maxWidth: '80%',
    textAlign: 'center',
    color: '#393939',
    marginTop: '-10px'
   },
   headingalt: {
    fontWeight: 700,
    fontSize: 24,
    maxWidth: '80%',
    textAlign: 'center',
    color: '#393939',
    paddingTop: 20,
    margin: 0
   },
   buttoncontainer: {
       width: '90%'
   },
   headingsix: {
       fontSize: 18,
       fontWeight: 500,
       lineHeight: 5,
       color: '#393939',
       textAlign: 'center'
   },
   headingsixsix: {
    fontSize: 18,
    fontWeight: 500,
    lineHeight: 3,
    color: '#393939',
    textAlign: 'center',
    marginTop: 30
},
  //  spantext: {
  //      fontSize: 18,
  //      fontWeight: '400',
  //      maxWidth: '80%',
  //      textAlign: 'center',
  //      color: '#393939',
  //      marginTop: '-10px'
  //  },
   spantext: {
    fontSize: 16,
    fontWeight: '400',
    maxWidth: '80%',
    textAlign: 'center',
    color: '#393939',
    // marginTop: '-10px'
},
   spantexttwo: {
    fontSize: 16,
    marginTop: 10,
    fontWeight: '400',
    color: '#7D7D7D',
    textDecoration: 'none'
},
spantextthree: {
    fontSize: '14px !important',
    marginTop: 20,
    fontWeight: '400',
    color: '#7D7D7D',
    textDecoration: 'none'
},
   headingone: {
       fontSize: 37,
       fontWeight: 800
   },
   icontext: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 12,
    fontWeight: '800',
    textDecoration: 'none',
    color: 'black'
   },
   icontexttwo: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 9,
    fontWeight: '800',
    textDecoration: 'none',
    color: 'black'
   },
   iconsocial: {
       marginRight: 5,
       width: 15
   },
   typocontainer: {
       width: '90%'
   },
   pageImage: {
    width: 250,
    paddingTop: 20,
},
photo: {
    width: 300,
    paddingTop: 100
},
headertext: {
    fontSize: 24,
    fontWeight: 500,
    color: '#000',
    textAlign: 'center',
    maxWidth: '70%',
},
line: {
    height: 2,
    backgroundColor: '#eeeeee',
    width: '80%',
    marginBottom: 50
},
leavebutton: {
    backgroundColor: '#930483',
    width: 280,
    height: 50,
    borderRadius: 25,
    color: '#fff',
    fontSize: 16,
    fontWeight: 500
}
  }));



function NotFoundScreen() {
  const classes = useStyles();


  return (
      <Layout>
        <div className={classes.root}>
       <img src={Person} alt='complete' className={classes.photo}/>
       <h5 className={classes.headertext}>Si e’ verificato un errore, per maggiori informazioni contatti il servizio clienti Wora su Whatsapp.</h5>
       <div className={classes.line}></div>
   </div>  
      </Layout>
   
   
  )
}

export default NotFoundScreen;
